












































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import App from "@/App.vue";


// The MDC Component
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCIconButton from "@/components/mdcComponents/buttons/TheMDCIconButton.vue";
import TheMDCDataTable from "@/components/mdcComponents/tables/TheMDCDataTable.vue";
import { ShipmentHelpers } from "@/utils/shipmentHelpers.ts";
import Prompt from "@/components/prompts/Prompt.vue";
import * as ImportDataHelpers from "@/utils/importDataHelpers";
import * as theme from '@/assets/scss/_theme.scss';





// import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';




@Component({
  components: {
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCIconButton,
    TheMDCDataTable,
    Prompt,
    'vue-good-table': require('vue-good-table').VueGoodTable
  }

})
export default class PickUpStoreList extends Vue implements VuePromptCaller {  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }

  @Ref("mobile-warning-dialog") readonly mobileWarngingDialog!: Prompt;
  
  private ROOT = this.$root.$children[0] as App;

  private invalidData: any[] = [];

  async beforeCreate() {
    this.$store.commit("hideSettingBar");
    this.$store.commit("hideNavBar");
    this.$store.commit("showTopBar");
    await this.$store.dispatch("bulkImport/fetchBulkImportRecords");
  }

  mounted() {
    if(ImportDataHelpers.checkIsMobile()){
      this.mobileWarngingDialog.show({
        closeBlt: this.$t("dialogs.bulkImportWithMobileDialog.okay") as string, 
        title: this.$t("dialogs.bulkImportWithMobileDialog.title") as string, 
        msg: this.$t("dialogs.bulkImportWithMobileDialog.recommendUsingDesktop") as string
      });     
    }
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }

  get theme(){
    return theme;
  }

  get bulkImportRecordTableHeader(){
    return [
      {
        label: this.$t("pickUpStore.code"),
        field: 'code'
      },
      {
        label: this.$t("pickUpStore.alias"),
        field: 'alias'
      },
      {
        label: this.$t("pickUpStore.district"),
        field: 'district'
      },
      {
        label: this.$t("pickUpStore.area"),
        field: 'area'
      },
      {
        label: this.$t("pickUpStore.address"),
        field: 'address'
      },
      {
        label: this.$t("pickUpStore.openHours"),
        field: 'openHours',
        tdClass: 'pre-line-text'
      },
      {
        label: this.$t("pickUpStore.freeStorageDays"),
        field: 'freeStorage',
      },
      // {
      //   label: this.$t("pickUpStore.excelKey"),
      //   field: 'excelKey',
      //   html: true,
      //   tdClass: (data: any) => data.labelKey?"download-icon-button":"processing-button",
      // }
    ];
  }

  pickUpStoreTableRows(records: any[]){
    const formattedRecords = [];
    for (let i = 0; i < records.length;i++){
      formattedRecords.push(
        {
          code: records[i]["code"],
          alias: records[i]["alias"],
          district: this.$i18n.locale === "cant"?records[i]["districtChi"]:records[i]["districtEn"],
          area: this.$i18n.locale === "cant"?this.$store.getters["address/getAreaByAreaRank"](records[i]["areaRank"]).areaChi:this.$store.getters["address/getAreaByAreaRank"](records[i]["areaRank"]).areaEn,
          address: records[i]["address"],
          openHours: ShipmentHelpers.displayPickUpStoreOpenHours(records[i]),
          freeStorage: records[i]["freeStorage"]
          // excelKey: (records[i]["excelKey"]?'<i @click="downlodLabelExcel("'+records[i]["labelKey"]+'", "excel")" class="material-icons">file_download</i>':this.$t("general.processing")),
        }
      )
    }
    return formattedRecords;
  }
  
  

  get pickUpStores(){
    return this.$store.getters["pickUpStore/getAllPickUpStores"] || [];
  }

  get formatBulkImportRecords(){
    return ImportDataHelpers.formatBulkImportRecords(this.pickUpStores);
  }
  private async backTo(){
    await this.$router.go(-1);
  }
}

