var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      staticClass: "notranslate",
      class: { "freeze-click": _vm.freezeClick },
      attrs: { id: "app" }
    },
    [
      _c(
        "div",
        {
          staticClass: "main-content__container",
          class: {
            "main-content--extend-top": _vm.extendBaseTop,
            "main-content--extend-bot": _vm.extendBaseBot,
            "main-content--double-extend": _vm.doubleExtendBase
          },
          attrs: { id: "base-content" }
        },
        [_c("router-view", { staticStyle: { height: "100%" } })],
        1
      ),
      _c("SettingBar", { attrs: { id: "setting-bar" } }),
      _c("TopBar", { attrs: { id: "top-nav-bar" } }),
      _c("NavBar", { attrs: { id: "bottom-nav-bar" } }),
      _c(
        "div",
        { staticClass: "prompt__containr" },
        [
          _c("Loading", { staticStyle: { "z-index": "19" } }),
          _c("ErrorPrompt", {
            ref: "generic-error-prompt",
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              "z-index": "20"
            },
            attrs: {
              reference: "generic-error-prompt",
              message: _vm.text.connectionIssue
            }
          }),
          _c("Prompt", { ref: "generic-prompt" }),
          _c("AddShipmentDataPrompt", { ref: "add-shipment-data-prompt" }),
          _vm.hasCard
            ? _c("AddCreditsPrompt", { ref: "add-credits-prompt" })
            : _vm._e(),
          _c("IframePrompt", { ref: "iframe-prompt" }),
          _c("InAppNoticesPrompt", { ref: "inAppNotices-prompt" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }