import i18n from "@/i18n";
import store from "@/store";
import * as helpers from "@/utils/fetchDataHelpers";
import { ShipmentHelpers } from "@/utils/shipmentHelpers";
import BalanceLog from "@/views/credits/BalanceLog.vue";
import credit from "@/views/credits/Credits.vue";
import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import bulkImport from "../views/bulkImport/BulkImport.vue";
import pickUpStoreList from "../views/bulkImport/PickUpStoreList.vue";
import loginPage from "../views/Login.vue";
import requestLabelForm from "../views/RequestLabelForm.vue";

Vue.use(VueRouter);

async function checkLogin(): Promise<boolean> {
  if (store.getters.isLogin) {
    return true;
  }
  if (await helpers.fetchUserLoginInfo()) {
    return true;
  }
  return false;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL || "/",
  routes: [
    {
      path: "/processing_credit",
      component: () =>
        import(
          /* webpackChunkName: "CreditsReturn" */ "@/iframe/CreditsReturn.vue"
        ),
      meta: {
        needLogin: true,
      },
      beforeEnter: (to, from, next) => {
        // helpers.fetchIntentId()
        next();
      },
    },
    {
      path: "",
      redirect: { name: "Login", params: { locale: i18n.locale } },
    },
    {
      path: "/:locale",
      component: {
        template: "<router-view></router-view>",
      },
      beforeEnter: async (to: Route, from: Route, next: any) => {
        const locale = to.params.locale;
        const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE!.split(
          ","
        );

        if (!supportedLocales.includes(locale)) {
          return next(i18n.locale);
        }

        // always follow i18n instead of path
        if (i18n.locale != locale) {
          to.params.locale = i18n.locale;
          next(to);
        }
        return next();
      },
      children: [
        {
          path: "",
          redirect: { name: "Login" },
          meta: {
            needLogin: false,
          },
        },
        {
          path: "login",
          name: "Login",
          component: loginPage,
          meta: {
            needLogin: false,
          },
          beforeEnter: async (to, from, next) => {
            if (await checkLogin()) {
              await next({ name: "Shipment", params: { locale: i18n.locale } });
            } else {
              next();
            }
          },
        },
        {
          path: "dashboard",
          name: "Dashboard",
          component: () =>
            import(
              /* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue"
            ),
          meta: { needLogin: true },
        },
        {
          path: "credits",
          name: "Credits",
          component: credit,
          meta: { needLogin: true },
          beforeEnter: async (to, from, next) => {
            store.commit("showLoading");
            await helpers.fetchCardInfo();
            next();
          },
        },
        {
          path: "balance",
          name: "BalanceLog",
          // component: () => import(/* webpackChunkName: "BalanceLog" */ "../views/credits/BalanceLog.vue"),
          component: BalanceLog,
          meta: { needLogin: true },
        },
        {
          path: "shipment",
          // component: shipment,
          component: () =>
            import(
              /* webpackChunkName: "Shipment" */ "../views/shipment/Shipment.vue"
            ),
          meta: { needLogin: true },
          beforeEnter: async (to, from, next) => {
            // console.log("to Shipment", to);
            store.commit("showLoading");
            await helpers.fetchCompanySavedInfo();
            await helpers.fetchShippingInfo();
            await helpers.fetchExpressOrderAvailableDate();
            await helpers.fetchPickUpStores();
            next();
          },
          children: [
            {
              path: "",
              name: "Shipment",
              component: () =>
                import(
                  /* webpackChunkName: "Shipment" */ "../views/shipment/ShipmentForm/ShipmentForm.vue"
                ),
              meta: { needLogin: true },
              // beforeEnter: async (to, from, next) =>  {
              //   next();
              // }
            },

            //{
            //  path: "qrCode",
            //  name: "qrCodeScanner",
            //  // component: qrCodeScanner,
            //  component: () =>
            //    import(
            //      /* webpackChunkName: "Shipment" */ "../views/shipment/ShipmentForm/QrCodeScanner.vue"
            //    ),
            //  meta: { needLogin: true },
            //  // beforeEnter: async (to, from, next) => {
            //  //   next();
            //  // }
            //},
            {
              path: "other-courier-address",
              name: "Address",
              component: () =>
                import(
                  /* webpackChunkName: "AddressForm" */ "@/views/shipment/ShipmentForm/Address/AddressForm.vue"
                ),
              props: true,
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                if (!to.params.action || !to.params.identity) {
                  //TODO: add url error
                  await next({
                    name: "Shipment",
                    params: { locale: i18n.locale },
                  });
                }
                next();
              },
            },
            {
              path: "item/:action/:itemId/:templateItemId",
              name: "Item",
              component: () =>
                import(
                  /* webpackChunkName: "Item" */ "@/views/shipment/ShipmentForm/Item/ItemForm.vue"
                ),
              props: true,
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                next();
              },
            },
            {
              path: "expressOrderItem/",
              component: () =>
                import(
                  /* webpackChunkName: "Item" */ "@/views/shipment/ShipmentForm/Item/ExpressOrderItemBase.vue"
                ),
              props: true,
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                next();
              },
              children: [
                {
                path: ":templateItemId",
                name: "ExpressOrderItem",
                component: () =>
                  import(
                    /* webpackChunkName: "Item" */ "@/views/shipment/ShipmentForm/Item/ExpressOrderItemForm.vue"
                  ),
                props: true,
                meta: { needLogin: true },
                beforeEnter: async (to, from, next) => {
                  next();
                }},{
                path: "qrCode",
                name: "qrCodeScanner",
                // component: qrCodeScanner,
                component: () =>
                  import(
                    /* webpackChunkName: "Shipment" */ "../views/shipment/ShipmentForm/QrCodeScanner.vue"
                  ),
                meta: { needLogin: true },
                beforeEnter: async (to, from, next) => {
                  next();
                }
              },]
            },
            {
              path: "pickUpStore",
              name: "PickUpStore",
              component: () =>
                import(
                  /* webpackChunkName: "Item" */ "@/views/shipment/ShipmentForm/PickUpStoreForm.vue"
                ),
              props: true,
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                next();
              },
            },
            {
              path: "confirm-and-proceed",
              name: "ConfirmAndProceed",
              component: () =>
                import(
                  /* webpackChunkName: "ConfirmAndProceed"*/ "@/views/shipment/ConfirmAndProceedForm/ConfirmAndProceedForm.vue"
                ),
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                store.commit("showLoading");
                await helpers.fetchShippingRates();
                await helpers.fetchShippingPickup();
                next();
              },
            },
            {
              path: "confirm-and-pay",
              name: "ConfirmAndPay",
              component: () =>
                import(
                  /* webpackChunkName: "ConfirmAndPay" */ "@/views/shipment/ConfirmAndPayForm/ConfirmAndPayForm.vue"
                ),
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                store.commit("showLoading");
                await helpers.fetchShippingRates();
                await helpers.fetchShippingPickup();
                next();
              },
            },
            {
              path: "*/",
              name: "TrackingNumber",
              component: () =>
                import(
                  /* webpackChunkName: "Shipment" */ "../views/shipment/Shipment.vue"
                ),
              props: true,
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                store.commit("showLoading");
                const checkingTrackingNumber = to.params.pathMatch;
                if (
                  (await ShipmentHelpers.validateExpressOrderTrackingNumberFormat(
                    checkingTrackingNumber
                  )) === true
                ) {
                  // Match Tracking number, set url Tracking number
                  store.commit("shipment/setTrackingNumber", null);
                  if (
                    (await ShipmentHelpers.validateTrackingNumeberWithoutType(
                      checkingTrackingNumber
                    )) === true
                  ) {
                    store.commit("shipment/setIsSubprime", true);
                  }
                }
                await router.push({ name: "Shipment" });
              },
            },
            {
              path: "address",
              name: "address",
              component: () =>
                import(
                  /* webpackChunkName: "Address" */ "../views/shipment/ShipmentForm/Address/Address.vue"
                ),
              meta: { needLogin: true },
              props: true,
              beforeEnter: async (to, from, next) => {
                if (
                  !to.params.addressMutation ||
                  !to.params.completingRedirectName
                ) {
                  //TODO: add url error
                  store.commit("dialog/showErrorDialog", {
                    title: i18n.t("dialogs.routerErrorPrompt.title"),
                    msg: i18n.t("dialogs.routerErrorPrompt.message"),
                    closeBlt: i18n.t("dialogs.close"),
                  });
                  await next({
                    name: "Shipment",
                    params: { locale: i18n.locale },
                  });
                }
                next();
              },
              children: [
                {
                  path: "",
                  name: "ExpressOrderAddress",
                  component: () =>
                    import(
                      /* webpackChunkName: "AddressForm" */ "@/views/shipment/ShipmentForm/Address/ExpressOrderAddressForm.vue"
                    ),
                  props: true,
                  meta: { needLogin: true },
                },
                {
                  path: "buildingSearch",
                  name: "BuildingSearch",
                  component: () =>
                    import(
                      /* webpackChunkName: "BuildingSearch" */ "@/views/shipment/ShipmentForm/Address/BuildingSearch.vue"
                    ),
                  props: (route) => ({ ...route.params }),
                  meta: { needLogin: true },
                },
              ],
            },
            // {
            //   path: ":trackingNumber",
            //   name: "Testing",
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "Shipment" */ "../views/shipment/ShipmentForm/ShipmentForm.vue"
            //     ),
            //   meta: { needLogin: true },
            //   beforeEnter: async (to, from, next) =>  {
            //     console.log("to TrackingNumber", to);
            //     next();
            //   }
            // },
          ],
        },
        {
          path: "tracking",
          component: () =>
            import(
              /* webpackChunkName: "Shipment" */ "../views/tracking/Tracking.vue"
            ),
          children: [
            {
              path: "",
              name: "tracking",
              component: () =>
                import(
                  /* webpackChunkName: "Shipment" */ "../views/tracking/Tracking.vue"
                ),
              meta: { needLogin: true },
            },
            {
              path: "trackingList",
              name: "TrackingList",
              component: () =>
                import(
                  /* webpackChunkName: "Shipment" */ "../views/tracking/TrackingList.vue"
                ),
              meta: { needLogin: true },
              // meta: {needLogin: true },
              beforeEnter: async (to, from, next) => {
                store.commit("showLoading");
                await helpers.fetchTracking();
                // TrackingHelpers.resetTrackinglistFilter().then(function () {
                // helpers.fetchShipmentList().then(function () {
                await helpers.fetchShipmentList();
                next();
                //   });
                // });
              },
            },
            {
              path: "trackingDetail/:shipmentId",
              name: "TrackingDetail",
              component: () =>
                import(
                  /* webpackChunkName: "Shipment" */ "../views/tracking/TrackingDetail.vue"
                ),
              meta: { needLogin: true },
              beforeEnter: async (to, from, next) => {
                store.commit("showLoading");
                await helpers.fetchTracking();
                await helpers.fetchPickUpStores();
                await helpers
                  .fetchShipmentRecordbyId(to.params.shipmentId as string)
                  .then(function() {
                    next();
                  });
                //TODOs: error handling
              },
            },
          ],
        },
        {
          path: "requestLabelForm",
          name: "RequestLabelForm",
          component: requestLabelForm,
          // component: () =>
          //   import(/* webpackChunkName: "RequestLabelForm" */ "../views/RequestLabelForm.vue"),
          meta: { needLogin: true },
        },
        {
          path: "bulkImport",
          name: "BulkImport",
          component: bulkImport,

          // component: () =>
          //   import(/* webpackChunkName: "BulkImport" */ "../views/bulkImport/BulkImport.vue"),
          meta: { needLogin: true },
          beforeEnter: async (to, from, next) => {
            await store.dispatch("bulkImport/fetchBulkImportRecords");
            next();
          },
        },
        {
          path: "pickUpStoreList",
          name: "PickUpStoreList",
          component: pickUpStoreList,
          // component: () =>
          //   import(/* webpackChunkName: "BulkImport" */ "../views/BulkImport.vue"),
          meta: { needLogin: true },
          beforeEnter: async (to, from, next) => {
            await helpers.fetchPickUpStores();
            next();
          },
        },
        {
          path: "*",
          redirect: { name: "Shipment", params: { locale: i18n.locale } },
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  store.commit("showLoading");
  if (to?.meta?.needLogin) {
    // check if locked in
    if (await checkLogin()) {
      await helpers.fetchDistrictAreas();
      await helpers.fetchExpressOrderOptions();
      await helpers.fetchOtherCourierOptions();
      next();
    } else {
      next({ name: "Login", params: { locale: i18n.locale } });
    }
  } else {
    next();
  }
});

router.onError((error) => {
  store.commit("hideLoading");
  store.commit("dialog/showErrorDialog", {
    title: i18n.t("dialogs.routerErrorPrompt.title"),
    msg: i18n.t("dialogs.routerErrorPrompt.message"),
    closeBlt: i18n.t("dialogs.close"),
  });
});

export default router;
