import { AxiosInstance } from "axios";
import { Module } from "vuex";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { fetchLabelUrl } from "@/utils/fetchDataHelpers";
import  * as backendDataConversionHelpers from "@/utils/backendDataConversionHelpers";

export function trackingModule(axiosInstance: AxiosInstance) {
  const tracking: Module<any, any> = {
    namespaced: true,
    state: {
      // shipmentRecords: {} as any,

      shipmentRecord: {} as ShipmentRecord,
      shipmentList: [] as ShipmentListItem[],

      expressRecord: {} as ExpressRecord,
      expressIntros: [] as ExpressIntro[],
      trackingRecordFilter: {
        orderType: "express" as TrackingOrderType,
        type: "inProgress",
        criteria: "shipment",
        keyword: "",
        limit: 100, //TODOs: add limit and render when scrolling
        offset: 0
      } as TrackingRecordFilter
    },
    getters: {
      getTrackingOrderType: (state: any) => {
        return state.trackingRecordFilter.orderType
      },
      getTrackingCriteria: (state: any) => {
        return state.trackingRecordFilter.criteria
      },
      getTrackingKeyword: (state: any) => {
        return state.trackingRecordFilter.keyword
      },
      getShipmentList: (state: any) => {
        return state.shipmentList;
      },

      getShipmentRecord: (state: any) => {
        return state.shipmentRecord;
      },

      getTrackingRecordFilter: (state: any) => {
        return state.trackingRecordFilter;
      },

      //express tracking
      getExpressIntros: (state: any) => {
        return state.expressIntros;
      },
      getExpressRecord: (state: any) => {
        return state.expressRecord;
      },
      getExpressOrderTrackings: (state: any) => {
        return state.expressOrderTrackings;
      }
    },

    mutations: {
      //Tracking
      setTrackingOrderType: (
        state: any,
        trackingOrderType: TrackingOrderType
      ) => {
        state.trackingOrderType = trackingOrderType;
      },

      //shipment
      fetchShipmentList(state: any, shipmentList: ShipmentListItem[]) {
        state.shipmentList = shipmentList;
      },
      fetchShipmentRecord(state: any, shipmentRecord: ShipmentRecord) {
        state.shipmentRecord = shipmentRecord;
      },

      //Express
      fetchExpressIntros: (state: any, expressIntros: ExpressIntro[]) => {
        state.expressIntros = expressIntros;
      },

      fetchExpressRecord: (state: any, expressRecord: ExpressRecord) => {
        state.expressRecord = expressRecord;
      },
      fetchExpressOrderTrackings: (state: any, expressOrderTrackings: ExpressOrderTracking[]) =>{
        state.expressOrderTrackings = expressOrderTrackings;
      },

      //Filter
      setTrackingRecordFilter(state: any, filter: TrackingRecordFilter) {
        state.trackingRecordFilter = filter;
      },
      setTrackingRecordOrderType(state: any, orderType: TrackingOrderType) {
        Storage.saveToSD("state.trackingRecordFilter.orderType", orderType);
        state.trackingRecordFilter.orderType = orderType;
      },
      setTrackingRecordFilterType(
        state: any,
        type: "inProgress" | "delivered"
      ) {
        state.trackingRecordFilter.type = type;
      },
      setTrackingRecordFilterCriteria(state: any, criteria: string) {
        Storage.saveToSD("state.trackingRecordFilter.criteria", criteria);
        state.trackingRecordFilter.criteria = criteria;

      },
      setTrackingRecordFilterKeyword(state: any, keyword: string) {
        Storage.saveToSD("state.trackingRecordFilter.keyword", keyword);
        state.trackingRecordFilter.keyword = keyword;

      }
    },

    actions: {
      //Tracking
      async retrieveTrackingList(store: any) {
          const filter: TrackingRecordFilter  = store.getters.getTrackingRecordFilter;
          if(filter.orderType == 'shipment'){
              return await store.dispatch('retrieveShipmentList', filter);
          }else{
              return await store.dispatch('retrieveExpressIntros', filter);
          }
      },

      async retrieveShipmentList(store: any) {
        const filter: TrackingRecordFilter =
          store.getters.getTrackingRecordFilter;
        const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url:
              "easyship-order/list/?type=" +
              filter.type +
              "&criteria=" +
              filter.criteria +
              "&keyword=" +
              (filter.keyword ?? "") +
              "&limit=" +
              filter.limit +
              "&offset=" +
              filter.offset
          })
          .then(response => {
            if (response.data.success) {
              //TODOs : update store shipmentRecords
              store.commit("fetchShipmentList", response.data.data);

              return true;
            }
            return false;
          })
          .catch(error => {
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      },

      async retrieveShipmentRecordById(store: any, shipmentId: string) {
        return await axiosInstance
          .request({
            method: "GET",
            url: "easyship-order/details/" + shipmentId
          })
          .then((response: any) => {
            if (response.data.success) {
              store.commit("fetchShipmentRecord", response.data.data);
              return true;
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
      },

      async fetchOtherCourierLabelUrl(store: any, shipmentId: string) {
        store.commit("showLoading", null, {root: true});

        return await axiosInstance
          .request({
            method: "GET",
            url: "label/easyship-order/" + shipmentId
          })
          .then((response: any) => {
            store.commit("hideLoading", null, {root: true});
            if (response.data.success) {
              // store.commit("fetchOtherCourierLabelUrl", response.data.data);
              return response.data.data.labelUrl;
              //testing only
              // return "https://staging.aigniter.com/integrated_sales/create_order_receipt/013e81c5-4c6b-44b7-a85e-746f9fed6625";
            }
            return false;
          })
          .catch(error => {
            store.commit("hideLoading", null, {root: true});
            return serverResponseHelpers.processServerErrors(error, console.trace());
          })
      },

      async fetchExpressOrderLabelUrl(store: any, expressId: string) {
        store.commit("showLoading", null, {root: true});
        return await axiosInstance
          .request({
            method: "GET",
            url: "label/express-order/" + expressId
          })
          .then((response: any) => {
            if (response.data.success) {
              store.commit("hideLoading", null, {root: true});
              // store.commit("fetchOtherCourierLabelUrl", response.data.data);
              return response.data.data.labelUrl;
              //testing only
              // return "https://staging.aigniter.com/integrated_sales/create_order_receipt/013e81c5-4c6b-44b7-a85e-746f9fed6625";
            }
            return false;
          })
          .catch(error => {
            store.commit("hideLoading", null, {root: true});
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
      },

      async fetchExpressOrderItemLabelUrl(store: any, itemId: string) {
        store.commit("showLoading", null, {root: true});
        return await axiosInstance
          .request({
            method: "GET",
            url: "label/express-order-item/" + itemId
          })
          .then((response: any) => {
            if (response.data.success) {
              store.commit("hideLoading", null, {root: true});
              // store.commit("fetchOtherCourierLabelUrl", response.data.data);
              return response.data.data.labelUrl;
              //testing only
              // return "https://staging.aigniter.com/integrated_sales/create_order_receipt/013e81c5-4c6b-44b7-a85e-746f9fed6625";
            }
            return false;
          })
          .catch(error => {
            store.commit("hideLoading", null, {root: true});
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
      },

      async retrieveExpressIntros(store: any) {
        const filter: TrackingRecordFilter =
          store.getters.getTrackingRecordFilter;
        const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url:
              "express-order/list/?type=" +
              filter.type +
              "&criteria=" +
              filter.criteria +
              "&keyword=" +
              (filter.keyword ?? "") +
              "&limit=" +
              filter.limit +
              "&offset=" +
              filter.offset
          })
          .then(response => {
            if (response.data.success) {
              //TODOs : update store shipmentRecords
              store.commit("fetchExpressIntros", response.data.data);
              return true;
            }
            return false;
          })
          .catch(error => {
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
      },

      async retrieveExpressRecordById(store: any, expressId: string) {
        return await axiosInstance
          .request({
            method: "GET",
            url: "express-order/details/" + expressId
          })
          .then((response: any) => {
            if (response.data.success) {
              store.commit("fetchExpressRecord", backendDataConversionHelpers.convertExpressRecord(response.data.data.shipment));
              return true;
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
      }
    }
  };
  return tracking;
}
