import { AxiosInstance } from "axios";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";
import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { Alpha2ToName, areas, itemCategories } from "@/assets/constant";


export function pickUpStoreModule(axiosInstance: AxiosInstance) {
    const getDefaultState = () => {
        return Object.assign({
            districts: [] as PickUpStoreDistrict[]
        });
      };
      return {
        namespaced: true,
        state: getDefaultState(),
        getters: {
            getDistricts: (state: any) => state.districts,
            getAllPickUpStores: (state: any) => {
                let pickUpStores:  PickUpStore[] = [];
                for(let i=0;i<state.districts.length;i++){
                    const district = state.districts[i];
                    for(let j=0;j<district.areas.length;j++){
                        const area = district.areas[j];
                        if(area.pickUpStores){
                          pickUpStores =pickUpStores.concat(area.pickUpStores);
                        }
                    }
                }
                return pickUpStores ;
            },
            getPickUpStoreByCode(state: any, getters: any): (code: string) => PickUpStore{
              return (code: string) => {
                return getters["getAllPickUpStores"].find((pickUpStore: PickUpStore) => pickUpStore.code === code) || {};
              }
            },            
            getPickUpStoreById(state: any, getters: any): (id: string) => PickUpStore{
              return (id: string) => {
                return getters["getAllPickUpStores"].find((pickUpStore: PickUpStore) => pickUpStore.id === id) || {} as PickUpStore;
              }
            },
            getDistrictByDistrictFull(state: any): (districtFull: string) => PickUpStoreDistrict {
              return (districtFull: string) => {
                return state.districts.find((district: PickUpStoreDistrict)=>district.districtFull === districtFull);
              }
            },
        },
        mutations: {
            resetPickUpStore: (state: any) => {
                Object.assign(state, getDefaultState());
            },
            setDistricts: (state: any, districts: District[]) => {
            //   Storage.saveToSD("state.address.districts", districts);
              state.districts = districts
            },
        },
        actions: {
          async fetchPickUpStores(store: any){
            const serverReturn = await axiosInstance
              .request({
                method: "GET",
                url: "pick-up-store/list",
              })
              .then(response => {
                if(response.data.success && response.data.data) {
                  store.commit('setDistricts', response.data.data.districts);
                  return true;
                }else{
                  return false;
                }
              })
              .catch(error => {
                console.log(error);
                return serverResponseHelpers.processServerErrors(error, console.trace());
              })
            return serverReturn;
          }
        }
    }  
}
