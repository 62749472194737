


























































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { MDCDialog } from "@material/dialog";

// using mdc dialog to do implementation
@Component
export default class Prompt extends Vue {
  @Prop() event?: string;

  @Ref("generic-prompt") readonly thisPromptElement!: HTMLElement;
  private thisDialog!: MDCDialog;
  mounted() {
    this.thisDialog = new MDCDialog(this.thisPromptElement);

    this.thisDialog.listen("MDCDialog:closing", (e: CustomEvent) => {
      this.$store.commit('dialog/resetErrorDialog');
      if (this.listener) {
        this.listener.on(e.detail.action, this.emitData, this.execution);
      }
      if(this.event){
        this.$emit(this.event);
      }
      this.$emit(e.detail.action);
      this.reset();
    });
  }

  public show(data: PromptData) {
    const {
      acceptBlt, // default to have, need explicit like turn it off
      closeBlt,
      title,
      HTMLMsg,
      msg,
      listener,
      emitData,
      emit,
      execution
    } = data;

    this.acceptBlt = acceptBlt || null;
    this.closeBlt = closeBlt || null;
    this.title = title || null;
    this.HTMLMsg = HTMLMsg || null;
    this.msg = msg || null;
    this.listener = listener || null;
    this.execution = execution || null;
    this.thisDialog.open();
  }

  // call when prompt need to be hided
  public hide(){
    if(typeof this.thisDialog !== 'undefined' && this.thisDialog.isOpen){
      this.thisDialog.close();
      this.reset();
    }
  }

  private reset() {
    this.acceptBlt = null;
    this.closeBlt = null;
    this.title = null;
    this.HTMLMsg = null;
    this.msg = null;
    this.listener = null;
    this.emitData = null;
    this.execution = null;
  }

  private acceptBlt: string | null = null;
  private title: string | null = null;
  private closeBlt: string | null = null;
  private HTMLMsg: string | null = null;
  private msg: string | null = null;
  private listener: VuePromptCaller | null = null;
  private emitData: any = null;
  private execution: CallBack<void> | null = null;
}
