var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mdc-text-field-container",
      style: { width: _vm.width || "inherit", height: _vm.height || "inherit" }
    },
    [
      _c(
        "div",
        {
          ref: _vm.reference,
          staticClass: "mdc-text-field mdc-text-field--outlined",
          style: {
            width: _vm.width || "inherit",
            height: _vm.height || "inherit"
          }
        },
        [
          (_vm.type
            ? _vm.type === "decimal" || _vm.type === "integer"
              ? "number"
              : _vm.type
            : "text") === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  id: _vm.reference,
                  required: _vm.isRequired,
                  disabled: _vm.isDisabled,
                  step:
                    _vm.type === "decimal" || _vm.type === "number"
                      ? ".01"
                      : null,
                  maxlength: _vm.maxlength || "255",
                  minlength: _vm.minlength || "0",
                  placeholder: _vm.placeholder,
                  type: "checkbox"
                },
                domProps: {
                  checked: Array.isArray(_vm.inputValue)
                    ? _vm._i(_vm.inputValue, null) > -1
                    : _vm.inputValue
                },
                on: {
                  focus: _vm.emitFocus,
                  change: function($event) {
                    var $$a = _vm.inputValue,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.inputValue = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.inputValue = $$c
                    }
                  }
                }
              })
            : (_vm.type
                ? _vm.type === "decimal" || _vm.type === "integer"
                  ? "number"
                  : _vm.type
                : "text") === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  id: _vm.reference,
                  required: _vm.isRequired,
                  disabled: _vm.isDisabled,
                  step:
                    _vm.type === "decimal" || _vm.type === "number"
                      ? ".01"
                      : null,
                  maxlength: _vm.maxlength || "255",
                  minlength: _vm.minlength || "0",
                  placeholder: _vm.placeholder,
                  type: "radio"
                },
                domProps: { checked: _vm._q(_vm.inputValue, null) },
                on: {
                  focus: _vm.emitFocus,
                  change: function($event) {
                    _vm.inputValue = null
                  }
                }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue"
                  }
                ],
                staticClass: "mdc-text-field__input",
                attrs: {
                  id: _vm.reference,
                  required: _vm.isRequired,
                  disabled: _vm.isDisabled,
                  step:
                    _vm.type === "decimal" || _vm.type === "number"
                      ? ".01"
                      : null,
                  maxlength: _vm.maxlength || "255",
                  minlength: _vm.minlength || "0",
                  placeholder: _vm.placeholder,
                  type: _vm.type
                    ? _vm.type === "decimal" || _vm.type === "integer"
                      ? "number"
                      : _vm.type
                    : "text"
                },
                domProps: { value: _vm.inputValue },
                on: {
                  focus: _vm.emitFocus,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputValue = $event.target.value
                  }
                }
              }),
          _c("div", { staticClass: "mdc-notched-outline" }, [
            _c("div", { staticClass: "mdc-notched-outline__leading" }),
            _c("div", { staticClass: "mdc-notched-outline__notch" }, [
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  class: {
                    "mdc-floating-label--float-above": _vm.forceFloating
                  },
                  attrs: { for: _vm.reference }
                },
                [_vm._v(_vm._s(_vm.label || ""))]
              )
            ]),
            _c("div", { staticClass: "mdc-notched-outline__trailing" })
          ])
        ]
      ),
      _vm.showCounter || _vm.helperText
        ? _c(
            "div",
            { staticClass: "mdc-text-field-helper-line helper-line-container" },
            [
              _c(
                "div",
                {
                  staticClass: "helper-text",
                  attrs: { id: "mdc-helper-text", "aria-hidden": "true" }
                },
                [_vm._v("\n      " + _vm._s(_vm.helperText) + "\n    ")]
              ),
              _vm.showCounter
                ? _c("div", { staticClass: "mdc-text-field-character-counter" })
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }