import { AxiosInstance } from "axios";

export function dialogModule(axiosInstance: AxiosInstance) {
    const getDefaultState = () => {
        return  {
            showErrorDialog: false as boolean,
            errorDialogData: {
                title: "",
                msg: "",
                acceptBlt: null
            } as PromptData
        }
      };
      return {
        namespaced: true,
        state: getDefaultState(),
        getters: {
            showErrorDialog:(state: any) =>  state.showErrorDialog,
            getErrorDialogData:(state: any) =>  state.errorDialogData
        },
        mutations: {
            showErrorDialog:(state: any, errorDialogData?: PromptData) => {
                state.errorDialogData = errorDialogData || getDefaultState().errorDialogData;
                state.showErrorDialog = true;
            },
            hideErrorDialog:(state: any ) => state.showErrorDialog = false,
            setErrorDialogData: (state: any, errorDialogData: PromptData) => state.errorDialogData = errorDialogData,
            resetErrorDialog: (state: any) => state.showErrorDialog = false,
            resetErrorDialogData: (state: any) => state.errorDialogData = getDefaultState().errorDialogData
        }
    }  
}
