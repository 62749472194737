import { AxiosInstance } from "axios";
import { Module } from "vuex";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { fetchLabelUrl } from "@/utils/fetchDataHelpers";
import  * as backendDataConversionHelpers from "@/utils/backendDataConversionHelpers";

export function requestLabelModule(axiosInstance: AxiosInstance) {
  const tracking: Module<any, any> = {
    namespaced: true,
    state: {
      deliveryAddress: {} as ExpressOrderAddress,
      sameDayDeliveryQuantity: 0 as number,
      standardDeliveryQuantity: 0 as number,
      labelDeliveryRemarks: "" as string
    },
    getters: {
        getDeliveryAddress: (state: any) =>  {
          return state.deliveryAddress;
        },
        getSameDayDeliveryQuantity: (state: any) =>  {
          return state.sameDayDeliveryQuantity;
        },
        getStandardDeliveryQuantity: (state: any) =>  state.standardDeliveryQuantity,
        getLabelDeliveryRemarks: (state: any) =>  state.labelDeliveryRemarks
      },
    mutations: {

      resetRequestLabel(state: any){
        state.deliveryAddress = Object.assign({});
        state.sameDayDeliveryQuantity = 0;
        state.standardDeliveryQuantity = 0;
        state.labelDeliveryRemarks = "";
      },

      setDeliveryAddress(state: any, deliveryAddress: ExpressOrderAddress){
        state.deliveryAddress = deliveryAddress;
      },
      setSameDayDeliveryQuantity(state: any, sameDayDeliveryQuantity: string){
        state.sameDayDeliveryQuantity = !isNaN(Number(sameDayDeliveryQuantity))?Number(sameDayDeliveryQuantity):0;
      },
      setStandardDeliveryQuantity(state: any, standardDeliveryQuantity: string){
        state.standardDeliveryQuantity  = !isNaN(Number(standardDeliveryQuantity))?Number(standardDeliveryQuantity):0;;
      },
      setLabelDeliveryRemarks(state: any, labelDeliveryRemarks: string){
        state.labelDeliveryRemarks = labelDeliveryRemarks;
      }

    },

    actions: {
      async requestLabel(store: any) {
        const serverReturn = await axiosInstance
          .request({
            method: "POST",
            url:
              "label/request",
            data: {
              deliveryAddress: store.state.deliveryAddress,
              sameDayDeliveryQuantity: store.state.sameDayDeliveryQuantity,
              standardDeliveryQuantity: store.state.standardDeliveryQuantity,
              remarks: store.state.labelDeliveryRemarks
            }

          })
          .then(response => {
            if (response.data.success) {
              //TODOs : update store shipmentRecords
              store.commit("resetRequestLabel");
              return true;
            }
            return false;
          })
          .catch(error => {
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
          return serverReturn;
      }
    }
  };
  return tracking;
}
