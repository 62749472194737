




























































































import { Component, Prop, Vue, Ref } from "vue-property-decorator";

// The MDC Components
import TheMDCTextField from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";

// In Page Prompts
import ErrorPrompt from "@/components/dialogs/ErrorPrompt.vue";
import MessagePrompt from "@/components/dialogs/MessagePrompt.vue";
import Privacy from "@/components/dialogs/Privacy.vue";
import Terms from "@/components/dialogs/Terms.vue";

// If Login, fetch required data
import * as fetchDataHelpers from "@/utils/fetchDataHelpers";
import { openBrowser } from "../utils/saveFileToDevice";

@Component({
  components: {
    Privacy,
    Terms,
    TheMDCTextField,
    TheMDCButtonShaped,
    TheMDCButtonShapedRaised,
    ErrorPrompt,
    MessagePrompt,
  },
})
export default class Login extends Vue {
  @Ref("login") loginComponent!: TheMDCTextField;
  @Ref("password") passwordComponent!: TheMDCTextField;
  @Ref("loginError") loginErrorPromptComponent!: ErrorPrompt;
  // @Ref("registration-prompt") registrationPromptComponent!: MessagePrompt;
  @Ref("privacy") privacyComponent!: Privacy;
  @Ref("terms") termsComponent!: Terms;

  mounted() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }
  get text() {
    return {
      loginId: this.$t("login.loginId"),
      password: this.$t("login.password"),
      login: this.$t("login.login"),
      register: this.$t("login.register"),
      terms0: this.$t("login.terms0"),
      terms1: this.$t("login.terms1"),

      loginError: {
        message: this.$t("login.loginError.message"),
      },
      privacyPolicy: this.$t("login.privacyPolicy"),
      termsOfUse: this.$t("login.termsOfUse"),
      registrationPrompt: {
        title: this.$t("login.registrationPrompt.title"),
        message: this.$t("login.registrationPrompt.message"),
      },
    };
  }
  private userAuth() {
    return {
      username: this.getUsername(),
      password: this.getPassword(),
    };
  }
  private getUsername() {
    return this.loginComponent.getValue();
  }
  private getPassword() {
    return this.passwordComponent.getValue();
  }

  beforeCreate() {
    this.$store.commit("hideTopBar");
    this.$store.commit("hideNavBar");
  }

  async login() {
    // Use loading page to hide login time lap
    this.$store.commit("showLoading");

    // await for login function
    const login = await this.$store.dispatch("login", this.userAuth());
    if (login) {
      fetchDataHelpers.fetchUserLoginInfo();
      await this.$router.push({ name: "Shipment" });
    } else if (login === false) {
      this.loginErrorPromptComponent.show();
      this.$store.commit("hideLoading");
    }
  }
  // get registerFormUrl(): string{
  //   return process.env.VUE_APP_JUMPPOINT_REGISTER_URL as string;
  // }
  register() {
    // open capacitor browser
    // openBrowser("https://www.jumppoint.io/sign-up");
    openBrowser(process.env.VUE_APP_JUMPPOINT_REGISTER_URL as string);
  }

  showPrivacy() {
    this.privacyComponent.show();
  }

  showTerms() {
    this.termsComponent.show();
  }
}
