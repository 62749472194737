














import { Component, Vue, Prop } from "vue-property-decorator";
import NavBarItem from "@/components/sidebars/NavBar/NavBarItem.vue";

@Component({
  components: {
    NavBarItem
  }
})
export default class NavBar extends Vue {
  get isShow() {
    return this.$store.getters.showNavBar;
  }
  get text() {
    return {
      dashBoard: this.$t("sidebars.navBar.navBar.dashBoard"),
      credits: this.$t("sidebars.navBar.navBar.credits"),
      shipment: this.$t("sidebars.navBar.navBar.shipment"),
      tracking: this.$t("sidebars.navBar.navBar.tracking"),
      history: this.$t("sidebars.navBar.navBar.history")
    };
  }
  get items() {
    return [
      // {
      //   to: { name: "Dashboard" },
      //   icon: "dashboard",
      //   label: this.text.dashBoard
      // },
      {
        to: { name: "Shipment" },
        icon: "flight_takeoff",
        label: this.text.shipment
      },
      {
        to: { name: "Credits" },
        icon: "credit_card",
        label: this.text.credits
      },
      {
        to: { name: "TrackingList" },
        icon: "receipt",
        label: this.text.tracking
      }
      // {
      //   to: { name: "History" },
      //   icon: "history",
      //   label: this.text.history
      // }
    ];
  }

  show() {
    this.$store.commit("showNavBar");
  }
  hide() {
    this.$store.commit("hideNavBar");
  }

  async navRoute(routeName: string) {
    await this.$router.push({ name: routeName });
  }
}
