var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-layout-grid request-label__container" },
    [
      _c(
        "div",
        { staticClass: "add-address-title__container mdc-layout-grid__inner" },
        [
          _c(
            "h3",
            {
              staticClass:
                "address-title mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("sidebars.settingBar.requestLabel")) +
                  "\n    "
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "address-form__container mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
        },
        [
          _c(
            "form",
            {
              staticClass:
                "mdc-dialog__content grid-list-container mdc-layout-grid__inner",
              attrs: { id: "request-label-prompt-content" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitRequestForm.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "form-label sub-title mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("requestLabelForm.refillingDeliveryQuantity")
                      ) +
                      "\n        "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "row label-quantity__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "sameDayDeliveryQuantity",
                    attrs: {
                      reference: "sameDayDeliveryQuantity",
                      label: _vm.$t("requestLabelForm.sameDayDeliveryQuantity"),
                      isRequired: false,
                      maxNumber: "10000",
                      minNumber: "0",
                      type: "integer"
                    },
                    model: {
                      value: _vm.sameDayDeliveryQuantity,
                      callback: function($$v) {
                        _vm.sameDayDeliveryQuantity = $$v
                      },
                      expression: "sameDayDeliveryQuantity"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "row label-quantity__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "standardDeliveryQuantity",
                    attrs: {
                      reference: "standardDeliveryQuantity",
                      label: _vm.$t(
                        "requestLabelForm.standardDeliveryQuantity"
                      ),
                      type: "integer",
                      maxNumber: "10000",
                      minNumber: "0",
                      isRequired: false,
                      event: "standardDeliveryQuantityChange"
                    },
                    model: {
                      value: _vm.standardDeliveryQuantity,
                      callback: function($$v) {
                        _vm.standardDeliveryQuantity = $$v
                      },
                      expression: "standardDeliveryQuantity"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "form-label sub-title mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("requestLabelForm.deliveryAddress")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                },
                [
                  _c(
                    "div",
                    { staticClass: "request-label-form__field" },
                    [
                      _c("TheMDCTextFieldOutlined", {
                        ref: "deliveryAddress",
                        attrs: {
                          reference: "deliveryAddress",
                          label: _vm.$t("requestLabelForm.deliveryAddress")
                        },
                        on: { focus: _vm.buildingSearch }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                },
                [
                  _c(
                    "div",
                    { staticClass: "request-label-form__field" },
                    [
                      _c("TheMDCTextFieldOutlined", {
                        ref: "labelDeliveryRemarks",
                        attrs: {
                          reference: "labelDeliveryRemarks",
                          label: _vm.$t("general.remarks"),
                          type: "string",
                          maxlength: 250,
                          showCounter: true,
                          isRequired: false
                        },
                        model: {
                          value: _vm.labelDeliveryRemarks,
                          callback: function($$v) {
                            _vm.labelDeliveryRemarks = $$v
                          },
                          expression: "labelDeliveryRemarks"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "address-process-button-row address-form__row address-process-button-row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "address-form__field address-process-button-container"
                    },
                    [
                      _c("TheMDCButtonShaped", {
                        ref: "return",
                        attrs: {
                          reference: "return",
                          type: "button",
                          width: "100%",
                          label: _vm.$t("requestLabelForm.backToHomePage"),
                          event: "back-to"
                        },
                        on: { "back-to": _vm.backTo }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "address-form__field address-process-button-container"
                    },
                    [
                      _c("TheMDCButtonShapedRaised", {
                        ref: "proceed",
                        attrs: {
                          reference: "proceed",
                          type: "submit",
                          width: "100%",
                          label: _vm.$t("general.okay"),
                          isDisabled: !_vm.canProceed,
                          event: "submit-request-form"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      ),
      _c("Prompt", {
        ref: "success-redirect-prompt",
        attrs: { event: "redirect" },
        on: { redirect: _vm.successSubmitRedirect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }