var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "generic-prompt", staticClass: "mdc-dialog" }, [
    _c("div", { staticClass: "mdc-dialog__container" }, [
      _c(
        "div",
        {
          staticClass: "mdc-dialog__surface",
          attrs: {
            role: "alertdialog",
            "aria-modal": "true",
            "aria-labelledby": "generic-prompt-title",
            "aria-describedby": "generic-prompt-content"
          }
        },
        [
          _vm.title
            ? _c(
                "h2",
                {
                  staticClass: "mdc-dialog__title",
                  attrs: { id: "generic-prompt-title" }
                },
                [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "mdc-dialog__content overflow--scroll",
              attrs: { id: "generic-prompt-content" }
            },
            [
              _vm.HTMLMsg
                ? _c("div", {
                    staticClass: "html-message__container",
                    domProps: { innerHTML: _vm._s(_vm.HTMLMsg) }
                  })
                : _vm.msg
                ? _c("div", { staticClass: "message__container" }, [
                    _vm._v(_vm._s(_vm.msg))
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "mdc-dialog__actions",
              class: {
                "blt-place-right": _vm.closeBlt && _vm.acceptBlt,
                "blt-place-center":
                  (_vm.closeBlt || _vm.acceptBlt) &&
                  !(_vm.closeBlt && _vm.acceptBlt)
              }
            },
            [
              _vm.closeBlt
                ? _c(
                    "button",
                    {
                      staticClass: "mdc-button mdc-dialog__button close-button",
                      attrs: {
                        type: "button",
                        "data-mdc-dialog-action": "close"
                      }
                    },
                    [
                      _c("div", { staticClass: "mdc-button__ripple" }),
                      _c("span", { staticClass: "mdc-button__label" }, [
                        _vm._v(_vm._s(_vm.closeBlt || _vm.$t("dialogs.close")))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.acceptBlt
                ? _c(
                    "button",
                    {
                      staticClass:
                        "mdc-button mdc-dialog__button success-button",
                      attrs: {
                        type: "button",
                        "data-mdc-dialog-action": "accept"
                      }
                    },
                    [
                      _c("div", { staticClass: "mdc-button__ripple" }),
                      _c("span", { staticClass: "mdc-button__label" }, [
                        _vm._v(_vm._s(_vm.acceptBlt))
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "mdc-dialog__scrim" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }