












































































































































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import App from "@/App.vue";


// The MDC Component
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCIconButton from "@/components/mdcComponents/buttons/TheMDCIconButton.vue";
import TheMDCDataTable from "@/components/mdcComponents/tables/TheMDCDataTable.vue";
import { ShipmentHelpers } from "@/utils/shipmentHelpers.ts";
import Prompt from "@/components/prompts/Prompt.vue";
import * as constant from "@/assets/constant";
import * as ImportDataHelpers from "@/utils/importDataHelpers";
import  * as theme from '@/assets/scss/_theme.scss';
import {camelCase} from "change-case";
import { openBrowser } from '@/utils/saveFileToDevice';
import ERROR_TYPE from '@/constants/backendError/ERROR_TYPE';
import USER_MESSAGE from '@/constants/backendError/USER_MESSAGE';





// import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import DateFormatHelpers from "@/utils/dateFormatHelpers";




@Component({
  components: {
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCIconButton,
    TheMDCDataTable,
    Prompt,
    'vue-good-table': require('vue-good-table').VueGoodTable
  }

})
export default class BulkImport extends Vue implements VuePromptCaller {  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }

  @Ref("bulk-import-dialog") readonly bulkImportDialog!: Prompt;
  @Ref("importButton") importButton!: HTMLFormElement;

  private ROOT = this.$root.$children[0] as App;

  private invalidData: any[] = [];

  private invalidCustomMessages: string[] | undefined = [];

  private dragging: number = 0;

  private bulkImportSummary: any = {};

  private totalShipments: string[] = Object.keys(this.bulkImportSummary);

  beforeCreate(){
    this.$store.commit("hideSettingBar");
    this.$store.commit("hideNavBar");
    this.$store.commit("showTopBar");
  }

  mounted() {
    
    if(ImportDataHelpers.checkIsMobile()){
      this.bulkImportDialog.show({
        closeBlt: this.$t("dialogs.bulkImportWithMobileDialog.okay") as string, 
        title: this.$t("dialogs.bulkImportWithMobileDialog.title") as string, 
        msg: this.$t("dialogs.bulkImportWithMobileDialog.recommendUsingDesktop") as string
      });     
    }
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
      this.checkShowImportFormUpdatedDialog();
    });

    //change default drag and drop behavior for uploading
    //start

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.importButton.addEventListener(eventName, preventDefaults, false)
    });

    function preventDefaults(e: any) {
      e.preventDefault();
      e.stopPropagation();
    }
    //end

  }

  get theme(){
    return theme;
  }

  get downloadExcelFormText(){
    return this.$t("bulkImport.downloadGuideTemplate",{version: ["(" + this.$t("general.updatedAt") as string + " " + DateFormatHelpers.formattedJSDateStringFromDate(new Date(this.lastUpdateDate as string)) + ")"].join("")});
  }

  get lastUpdateDate(){
    return process.env.VUE_APP_BULK_IMPORT_FORM_LAST_UPDATE;
  }

  get bulkImportRecordTableHeader(){
    return [
      {
        label: this.$t("bulkImport.headers.createdAt"),
        field: 'createdAt'
      },
      {
        label: this.$t("bulkImport.headers.status"),
        field: 'status'
      },
      {
        label: this.$t("bulkImport.headers.numberOfOrders"),
        field: 'numberOfOrders'
      },
      {
        label: this.$t("bulkImport.headers.labelKey"),
        field: 'labelKey',
        html: true,
        tdClass: (data: any) => data.labelKey?"download-icon-button":"processing-button",
      },
      {
        label: this.$t("bulkImport.headers.excelKey"),
        field: 'excelKey',
        html: true,
        tdClass: (data: any) => data.labelKey?"download-icon-button":"processing-button",
      }
    ];
  }

  bulkImportRecordTableRows(records: any[]){
    const formattedRecords = [];
    for (let i = 0; i < records.length;i++){
      formattedRecords.push(
        {
          id: records[i]["id"],
          createdAt: DateFormatHelpers.JSDateToDateTimeString(new Date(records[i]["createdAt"])),
          status: this.$te("bulkImport.status." + records[i]!.status)?this.$t("bulkImport.status." + records[i]!.status):this.$t("bulkImport.status.ERROR"),
          numberOfOrders: records[i]["numberOfOrders"],
          labelKey: (records[i]["labelKey"]?'<i @click="downlodLabelExcel("'+records[i]["labelKey"]+'", "label")" class="material-icons">file_download</i>':this.$t("general.processing")),
          excelKey: (records[i]["excelKey"]?'<i @click="downlodLabelExcel("'+records[i]["labelKey"]+'", "excel")" class="material-icons">file_download</i>':this.$t("general.processing")),
          labelKeyId: records[i]["labelKey"],
          excelKeyId: records[i]["excelKey"]
        }
      )
    }
    return formattedRecords;
  }
  
  

  get bulkImportRecords(){
    return this.$store.getters["bulkImport/getBulkImportRecords"];
  }

  get formatBulkImportRecords(){
    return ImportDataHelpers.formatBulkImportRecords(this.bulkImportRecords);
  }

  private displayImportDataErrorMessage(invalidData: any[], invalidCustomMessages: string[]){
    let invalidMessage = [];
    if((invalidData && invalidData.length > 0) || (invalidCustomMessages && invalidCustomMessages.length > 0)){
      for(let i=0;i<invalidData.length;i++){
        if(invalidData[i] && invalidData[i].length > 0){
          let columnMessage = "";
          columnMessage = invalidData[i]
            .map((invalid: boolean, index: number)=>{
              return ["(", ImportDataHelpers.indexToExcelColumn(index),")", ImportDataHelpers.bulkImportExpressOrderColumnName[index]].join("")
            }).filter((message: string)=>message && message.length > 0)
            .join(this.$t("general.coma"));
          invalidMessage.push( [this.$t("bulkImport.rowErrorMessage", {row:String(i + 1), columnString: columnMessage})].join(""));
        }
      }
      invalidMessage = invalidMessage.concat(invalidCustomMessages || []);
      // console.log('invalidCustomMessages', invalidCustomMessages);
      // console.log('invalidMessage', invalidMessage);
      
      if(invalidMessage.length > 0){
        return [this.$t("bulkImport.dataErrorMessage"),invalidMessage.join("\n")].join("\n");
      }
    }
  }

  private async toPickUpStoreList(){
    openBrowser(process.env.VUE_APP_PICK_UP_STORE_LIST_URL as string);

    // await this.$router.push({
    //   name: "PickUpStoreList"
    // });
  }

  private async backTo(){
    await this.$router.push({
      name: "Shipment"
    })
  }

  private groupBy = function(xs: any, key: any) {
    return xs.reduce(function(rv: any , x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  private async importExcel(event: any) {
    this.importButton.classList.remove("hover");
    this.dragging = 0;
    let customErrorTitle = "";
    let customErrorMessages = "";
    this.invalidData = [];
    this.invalidCustomMessages = [];
    let files = [];
    //Drag and Drop event handling
    if(event.type === 'drop'){
      files = event.dataTransfer.files;
    }
    //Click event handling
    else{
      files = event.target.files;
    }
    if(files.length > 0){
      this.$store.commit("showLoading");
      const importResult = await ImportDataHelpers.bulkImportExpressOrder(files[0], {sheet: 1, dateFormat: 'YYYY-MM-DD'});
      // console.log('importResult', importResult);
      if(!importResult || !importResult.validate){
        // if there is invalid data, show invalid data row and column
        if(importResult.errorMessage === ImportDataHelpers.INVALID_DATA){
          this.invalidData = importResult.invalidData;
          this.invalidCustomMessages = importResult.invalidCustomMessages;
        }else if(importResult.errorMessage === ImportDataHelpers.EMPTY_EXCEL_DATA ){
          customErrorMessages = this.$t("dialogs.bulkImportExcelPrompt.emptyExcel") as string;
        }else if(importResult.errorMessage === ImportDataHelpers.EXCEL_VERSION_INCOMPATIBLE){
          customErrorMessages = this.$t("dialogs.bulkImportExcelPrompt.versionIncompatible",{version: process.env.VUE_APP_BULK_IMPORT_FORM_VERSION || "X.X."}) as string;
        }else if(importResult.errorMessage === ImportDataHelpers.NUMBER_OF_ROWS_EXCEED_LIMIT ){
          customErrorMessages = this.$t("dialogs.bulkImportExcelPrompt.numberOfRowExceedLimit",{rowLimit: process.env.VUE_APP_BULK_IMPORT_ROW_NUMBER_LIMIT || "-"}) as string;
        }else if(importResult.errorMessage === ImportDataHelpers.UNMATCH_COLUMN_NUMBER){
          customErrorMessages = this.$t("dialog.bulkImportExcelPrompt.invalidFormat") as string;
        }
      
      }else{
        const gatherByTag = this.groupBy(importResult.formattedData,'manyItemsTag');
        const shipmentTags = [] as any[];
        for (const group in gatherByTag){
          shipmentTags.push(group);
        }

        // console.log(shipmentTags);
        //console.log(gatherByTag);
        
        const validateGroupTag = [] as any[];
        const groupedKey = [] as any[];
        for (const tag of shipmentTags){
          if (tag == 'undefined'){
            continue;
          }
          validateGroupTag[tag] = this.groupBy(gatherByTag[tag],'dummy');
          groupedKey[tag] = Object.keys(validateGroupTag[tag]);
        }
        const groupFailedRow = [] as any[];
        for (const tag of shipmentTags){
          if (tag == 'undefined'){
            continue;
          }
          if (groupedKey[tag].length == 1){
            continue;
          }
          const groupedRowCollector = [];
          for (const key in groupedKey[tag]){
            //console.log(validateGroupTag[tag][groupedKey[tag][key]])
            const rowCollector = [];
            for (const index in validateGroupTag[tag][groupedKey[tag][key]]){
              rowCollector.push(validateGroupTag[tag][groupedKey[tag][key]][index].rowNumber)
            }
            groupedRowCollector.push(rowCollector)
          }
          groupFailedRow[tag] = groupedRowCollector;
        }
        //console.log(Object.keys(groupFailedRow));
        //console.log((validateGroupTag));
        //console.log((importResult.formattedData));
        if (Object.keys(groupFailedRow).length > 0){
          let displayErrorGrouping = '以下一單多件編號未能整合\n';
          for (const tag in groupFailedRow){  
            //console.log(group);
            displayErrorGrouping = displayErrorGrouping + tag + ' :\n';
            for (const group of groupFailedRow[tag]){
              if (group.length > 1){
                displayErrorGrouping = displayErrorGrouping + this.$t("bulkImport.combinableItemRows",{rowNumbers:JSON.stringify(group)}) as string + '\n';
              }else{
                displayErrorGrouping = displayErrorGrouping + this.$t("bulkImport.separateShipmentRows",{rowNumbers:JSON.stringify(group)}) as string + '\n';
              }
            }
          }
          // console.log(displayErrorGrouping);
          customErrorMessages = displayErrorGrouping;
        } else{ //Send bulk import request
          const bulkImportResult = await this.$store.dispatch("bulkImport/bulkImportOrders", importResult.formattedData);
          const fetchImportRecordTableResult = await this.$store.dispatch("bulkImport/fetchBulkImportRecords");
          // console.log(bulkImportResult.success)
          if(bulkImportResult.success === true){
            //success
            this.$store.commit("hideLoading");
            this.bulkImportDialog.show({
              closeBlt: this.$t("dialogs.bulkImportExcelPrompt.okay") as string, 
              title: this.$t("dialogs.bulkImportExcelPrompt.successfullySubmitted") as string, 
              msg: this.$t("dialogs.bulkImportExcelPrompt.bulkImportSuccess") as string
            });
            if(!fetchImportRecordTableResult){
              //TODO: error handling for getting history
              this.$store.commit("showConnectionError");
              this.$store.commit("hideLoading");
            }
            // this.resetFileInput();
            return;
          //backend bulk import validation fail
          }else if (!bulkImportResult.success && 
            bulkImportResult.error && 
            (bulkImportResult.error.error_type === ERROR_TYPE.MISSING_PARAM_ERROR || 
              bulkImportResult.error.error_type === ERROR_TYPE.INVALID_PARAM_ERROR
            ))
          {
            //  translate backend validation to display error message
            try{
              if(bulkImportResult.error?.user_message === "EXPRESS_ORDERS"){
                customErrorMessages = this.$t("dialogs.bulkImportExcelPrompt.numberOfRowExceedLimit",{rowLimit: process.env.VUE_APP_BULK_IMPORT_ROW_NUMBER_LIMIT || "-"}) as string;
              }else{
                const columnName = camelCase((bulkImportResult.error.user_message as string).split(": ")[0] as string || "");
                const rowNumber = Number(bulkImportResult.error.user_message.split(": ")[1].trim());
                if(ImportDataHelpers.bulkImportExpressOrderColumnName.includes(columnName) && !isNaN(rowNumber)){
                  const columnNumber = Number(ImportDataHelpers.bulkImportExpressOrderColumnName.findIndex((element)=>element === columnName));
                  const tempDataArray = importResult.invalidData;
                  tempDataArray[rowNumber][columnNumber] = true;
                  this.invalidData = tempDataArray;
                  this.invalidCustomMessages = importResult.invalidCustomMessages;
                }
              }
            } catch (e) {
              console.log(e);
            }
          }else if(bulkImportResult.error.error_type === ERROR_TYPE.EXPRESS_ORDER_ERROR){
            customErrorTitle = this.$t("shipment.createShipmentFail") as string;
            customErrorMessages = this.$t("credits.insufficientBalance") as string;
          }
        } 
        ///       
      }
    }
    // show error dialog when bulk import failed showing fail msg from above
    this.$store.commit("hideLoading");
    this.bulkImportDialog.show({
        closeBlt: this.$t("dialogs.bulkImportExcelPrompt.okay") as string, 
        title: customErrorTitle || this.$t("dialogs.bulkImportExcelPrompt.invalidUploadFile") as string, 
        msg: customErrorMessages || this.$t("dialogs.bulkImportExcelPrompt.uploadAgain") as string
    });
    

  }

  private async refreshRecord(){
    this.$store.commit("showLoading");
    const recordResult = await this.$store.dispatch("bulkImport/fetchBulkImportRecords");
    if(!recordResult){
      //TODO: error handling for getting history
      this.$store.commit("showConnectionError");
    }
    this.$store.commit("hideLoading");
  }
  downloadGuideTemplate(){
    // window.open(process.env.VUE_APP_BULK_IMPORT_FORM_URL,'_blank');
    // openBrowser(this.bulkImportFormUrl);
    openBrowser(process.env.VUE_APP_BULK_IMPORT_FORM_URL as string);

  }

  //id: string, type: "label" | "excel
  async downlodLabelExcel(params: any){
    // params.row - row object 
    // params.column - column object
    // params.rowIndex - index of this row on the current page.
    // params.event - click event
    if(["labelKey", "excelKey"].includes(params.column.field)){
      const downloadResult = await this.$store.dispatch("bulkImport/downloadBulkImportRecordFile",{type: params.column.field?.replace("Key",""), bulkImportLogId: params.row.id})
      if(downloadResult === true){
        this.bulkImportDialog.show({
            closeBlt: this.$t("dialogs.bulkImportDownloadDialog.okay") as string, 
            title: this.$t("dialogs.bulkImportDownloadDialog.title") as string, 
            msg: this.$t("dialogs.bulkImportDownloadDialog.downloadSuccess") as string
          });
      }
    }
  }
  
  resetFileInput(){
    (this.$refs.uploadExcelFileInput as HTMLInputElement).value = "";
  }

  checkShowImportFormUpdatedDialog(){
    const latestUpdateDate = new Date(this.lastUpdateDate as string);
    const latestUploadRecord = this.bulkImportRecords.length > 0?this.bulkImportRecords.reduce((latestRecord: BulkImportRecords, record: BulkImportRecords) => (new Date(record.createdAt) > new Date(latestRecord.createdAt)? record:latestRecord)): null;
    if(latestUpdateDate && (latestUpdateDate > new Date(Date.parse(latestUploadRecord?.createdAt)))){
      this.bulkImportDialog.show({
        closeBlt: this.$t("dialogs.bulkImportFormUpdateReminderDialog.okay") as string, 
        title: this.$t("dialogs.bulkImportFormUpdateReminderDialog.title") as string, 
        msg: this.$t("dialogs.bulkImportFormUpdateReminderDialog.content",{updatedAt: DateFormatHelpers.formattedJSDateStringFromDate(latestUpdateDate)}) as string
      });
    }

    
  }

  //For Import Button CSS
  //start
  private isHover(e: any){
    if (e.type ==="dragenter"){
      this.dragging++;
      if (this.dragging === 1){
        this.importButton.classList.add("hover");
      }
    }
    else if(e.type ==="dragleave"){
      this.dragging--;
      if (this.dragging === 0){
        this.importButton.classList.remove("hover");
      }
    }
  }
  //end
}

