import store from "@/store";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

/**
 * get all the saved address and items saved from backend to vuex
 */
export function convertExpressRecord(expressOrderRecord: any): ExpressRecord | null {
  try{
    const convertingExpressOrder = Object.assign({}, expressOrderRecord);
    if(convertingExpressOrder.items){
      convertingExpressOrder.items.forEach((item: any, index: number) => {
        if(item.dimensionOptionId){
          convertingExpressOrder.items[index].isLatestFormat = true;
        }
        if(convertingExpressOrder.category){
          delete convertingExpressOrder.categoryOptionId;
        }
      });  
      return convertingExpressOrder;  
    }
  }catch(e){
    store.commit("showConnectionError");
  }
  return null;
}
