import { AxiosInstance } from "axios";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";
import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { Alpha2ToName, areas, itemCategories } from "@/assets/constant";


export function addressModule(axiosInstance: AxiosInstance) {
    const getDefaultState = () => {
        return  {
            //save address info when browsing through different page of address(addrss and building search)
            // action: "CreateItem" as "CreateItem" | "ModifyItem",
            // identity: "Sender" as "Sender" | "Receiver",
            // templateId: "" as string,
            // buildingAddress: null as BuildingAddress | null,
            // address: null as Address | ExpressOrderAddress | null,


            districts: [] as District[],
            areas:[] as Area[],
            // Saving building address search result
            buildingAddressSearchResults: [] as BuildingAddress[],

        }
      };
      return {
        namespaced: true,
        state: getDefaultState(),
        getters: {
            // getAction: (state: any) => state.action,
            // getIdentity: (state: any) => state.identity,
            // getTemplateId: (state: any) => state.templateId,
            // getBuildingAddress: (state: any) => state.buildingAddress,
            // getAddress: (state: any) => state.address,
            getDistricts: (state: any) => state.districts,
            getAreas: (state: any) => state.areas,
            getAreaByAreaRank(state: any): (areaRank: string) => Area{
              return (areaRank: string) =>{
                return state.areas.find((area: Area)=>area.areaRank === areaRank)
              }
            },
            getAreasByDistrict: (state: any) => (districtFull: string) => {
              const areasInDistrict = state.areas.filter((area: Area) => area.districtFull == districtFull);
              return areasInDistrict;
            },
            getBuildingSearchResults: (state: any) => state.buildingAddressSearchResults,
            getBuildingSearchResultById: (state: any) => (id: string) => state.buildingAddressSearchResults.find((result: BuildingAddress) => result.id == id)
        },
        mutations: {
            // setAction: (state: any, action: "CreateItem" | "ModifyItem") => state.action = state,
            // setIdentity: (state: any, identity: "Sender" | "Receiver") => state.identity = identity,
            // setTemplateId: (state: any, templateId: string) => state.templateId = templateId,
            // setBuildingAddress: (state: any, buildingAddress: BuildingAddress | null) => state.buildingAddress = buildingAddress,
            // setAddress: (state: any, address: Address | ExpressOrderAddress | null) => state.address = address,

            setDistricts: (state: any, districts: District[]) => {
              Storage.saveToSD("state.address.districts", districts);
              state.districts = districts
            },
            setAreas: (state: any, areas: Area[]) => {
              Storage.saveToSD("state.address.areas", areas);
              state.areas = areas
            },

            setBuildingAddressSearchResults(state: any, buildingAddressSearchResults: BuildingAddress[]){
              state.buildingAddressSearchResults = buildingAddressSearchResults;
            },
            resetBuilingAddressSearchResults:(state: any) => state.buildingAddressSearchResults = getDefaultState().buildingAddressSearchResults,

            resetAddressState: (state: any) => {
              state = getDefaultState();
            },

            
        },
        actions: {
          async fetchDistrictAreas(store: any){
            const serverReturn = await axiosInstance
              .request({
                method: "GET",
                url: "address/districts",
              })
              .then(response => {
                if(response.data.success && response.data.data) {
                  store.commit('setDistricts', response.data.data.districts);
                  store.commit('setAreas', response.data.data.areas);
                }else{
                  return false;
                }
              })
              .catch(error => {
                console.log(error);
                return serverResponseHelpers.processServerErrors(error, console.trace());
              })
            return serverReturn;
          },
          //Doing search at backend
          async fetchBuildingSearchResults(store: any, keyword: string) {
            const serverReturn = await axiosInstance
              .request({
                method: "POST",
                url: "address/search",
                data: {
                  q: keyword,
                  limit: 30,
                  offset: 0
                }
              })
              .then(response => {
                //console.log(response);
                if (response.data.success && response.data.data) {
                  const addresses = response.data.data.addresses;
                  addresses.filter((address: BuildingAddress)=> address.canBeDelivered);
                  store.commit('setBuildingAddressSearchResults', addresses);
                  return true;
                }else{
                  return false;
                }
              })
              .catch(error => {
                console.log(error);
                return serverResponseHelpers.processServerErrors(error, console.trace());
              });
            return serverReturn;
          }
        }
    }  
}
