var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "reset-password-dialog", staticClass: "mdc-dialog" },
    [
      _c("div", { staticClass: "mdc-dialog__container" }, [
        _c(
          "div",
          {
            staticClass: "mdc-dialog__surface",
            attrs: {
              role: "alertdialog",
              "aria-modal": "true",
              "aria-labelledby": "reset-password-dialog-title",
              "aria-describedby": "reset-password-dialog-content"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "mdc-dialog__title",
                attrs: { id: "reset-password-dialog-title" }
              },
              [_vm._v(_vm._s(_vm.text.title))]
            ),
            _c(
              "form",
              {
                staticClass: "mdc-dialog__content grid-list-container",
                attrs: { id: "reset-password-dialog-content" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "password__row" },
                  [
                    _c("TheMDCTextFieldOutlined", {
                      ref: "origin-pw",
                      attrs: {
                        reference: "origin-pw",
                        label: _vm.text.originPw,
                        type: "password",
                        minlength: "8",
                        isRequired: true
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "password__row" },
                  [
                    _c("TheMDCTextFieldOutlined", {
                      ref: "new-pw",
                      attrs: {
                        reference: "new-pw",
                        label: _vm.text.newPw,
                        type: "password",
                        minlength: "8",
                        isRequired: true
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "password__row" },
                  [
                    _c("TheMDCTextFieldOutlined", {
                      ref: "re-enter-pw",
                      attrs: {
                        reference: "re-enter-pw",
                        label: _vm.text.reEnterPw,
                        type: "password",
                        isRequired: true,
                        minlength: "8"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "password__row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "mdc-button mdc-dialog__button",
                      attrs: {
                        type: "button",
                        "data-mdc-dialog-action": "close"
                      }
                    },
                    [
                      _c("div", { staticClass: "mdc-button__ripple" }),
                      _c("span", { staticClass: "mdc-button__label" }, [
                        _vm._v(_vm._s(_vm.text.notOkay))
                      ])
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "mdc-button mdc-dialog__button",
                      attrs: { type: "submit" },
                      on: { click: _vm.proceed }
                    },
                    [
                      _c("div", { staticClass: "mdc-button__ripple" }),
                      _c("span", { staticClass: "mdc-button__label" }, [
                        _vm._v(_vm._s(_vm.text.okay))
                      ])
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "mdc-dialog__scrim" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }