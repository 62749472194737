import { AxiosInstance } from "axios";
import { Module } from "vuex";
import { expressOrderHistorialInputModule } from "./expressOrderHistorialInput";
import { otherCourierHistorialInputModule } from "./otherCourierHistorialInput";



import * as serverResponseHelpers from "@/utils/serverResponseHelpers";

export function historialInputModule(axiosInstance: AxiosInstance) {
  const historialInput: Module<any, any> = {
    namespaced: true,
    modules: {
      expressOrder: expressOrderHistorialInputModule(axiosInstance),
      otherCourier: otherCourierHistorialInputModule(axiosInstance)
    },
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
  }
  return historialInput;
}
