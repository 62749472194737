import { AxiosInstance } from "axios";
import { Module } from "vuex";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";



import * as serverResponseHelpers from "@/utils/serverResponseHelpers";

export function expressOrderItemOptionModule(axiosInstance: AxiosInstance) {
  const getDefaultState = () => {
    return {
      sameDayCategories: [] as  Option[],
      nonSameDayCategories: [] as  Option[],
      sameDayDimensions: [] as  Option[],
      nonSameDayDimensions: [] as Option[],
      weights: [] as  Option[],
      temperatures: [] as  Option[],

    }
  };
    return  {
    namespaced: true,
     state: getDefaultState(),

    getters: {
      getSameDayCategories: (state: any) => state.sameDayCategories,
      getNonSameDayCategories: (state: any) => {
        return state.nonSameDayCategories;
      },
      getSameDayDimensions: (state: any) => state.sameDayDimensions,
      getNonSameDayDimensions: (state: any) => state.nonSameDayDimensions,
      getWeights: (state: any) => state.weights,
      getTemperatures: (state: any) => state.temperatures,
      getSameDayCategoriesById: (state: any) => (id: string) => {
        return state.sameDayCategories.find((category: Option) => category.id == id);
      },
      getNonSameDayCategoriesById: (state: any) => (id: string) => {
        return state.nonSameDayCategories.find((category: Option) => category.id == id);
      },
      getSameDayDimensionById: (state: any) => (id: string) => {
        return state.sameDayDimensions.find((sameDayDimensions: Option) => sameDayDimensions.id == id);
      },
      getNonSameDayDimensionById: (state: any) => (id: string) => {
        return state.nonSameDayDimensions.find((nonSameDayDimension: Option) => nonSameDayDimension.id == id);
      },
      getWeightById: (state: any) => (id: string) => {
        return state.weights.find((weight: Option) => weight.id == id);
      },
      getTemperatureById: (state: any) => (id: string) => {
        return state.temperatures.find((temperature: Option) => temperature.id == id);
      },
    },
     
    mutations: {
      setSameDayCategories: (state: any, sameDayCategories: Option[]) => {
        Storage.saveToSD("state.expressOrderItemOption.sameDayCategories", sameDayCategories);
        state.sameDayCategories = sameDayCategories;
      },
       setNonSameDayCategories: (state: any, nonSameDayCategories: Option[]) => {
        Storage.saveToSD("state.expressOrderItemOption.nonSameDayCategories", nonSameDayCategories);
        state.nonSameDayCategories = nonSameDayCategories;
       },
       setSameDayDimensions: (state: any, sameDayDimensions: Option[]) => {
        Storage.saveToSD("state.expressOrderItemOption.sameDayDimensions", sameDayDimensions);
         state.sameDayDimensions = sameDayDimensions;
       },
       setNonSameDayDimensions: (state: any, nonSameDayDimensions: Option[]) => {
        Storage.saveToSD("state.expressOrderItemOption.nonSameDayDimensions", nonSameDayDimensions);
         state.nonSameDayDimensions = nonSameDayDimensions;
       },
       setWeights: (state: any, weights: Option[]) => {
        Storage.saveToSD("state.expressOrderItemOption.weights", weights);

         state.weights = weights;
       },
       setTemperatures: (state: any, temperatures: Option[]) => {
        Storage.saveToSD("state.expressOrderItemOption.temperatures", temperatures);

        state.temperatures = temperatures;
       }
     },

     actions: {
      async fetchExpressOrderOptions(store: any){
        const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url: "item/options",
          })
          .then(response => {
            if(response.data.success && response.data.data) {
              store.commit('setSameDayCategories', response.data.data.options.categorySameDay || []);
              store.commit('setNonSameDayCategories', response.data.data.options.categoryNonSameDayNormal || []);
              store.commit('setSameDayDimensions', response.data.data.options.dimensionSameDay || []);
              store.commit('setNonSameDayDimensions', response.data.data.options.dimensionNonSameDay || []);
              store.commit('setWeights', response.data.data.options.weight || []);
              store.commit('setTemperatures', response.data.data.options.temperature || []);
            }else{
              return false;
            }
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          })
        return serverReturn;
      },
    }
  };
}