var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer__container" }, [
    _c(
      "aside",
      {
        staticClass: "mdc-drawer mdc-drawer--modal",
        attrs: { id: "setting-bar-inner" },
        on: { "MDCDrawer:opened": _vm.opening, "MDCDrawer:closed": _vm.closing }
      },
      [
        _c(
          "div",
          {
            staticClass: "mdc-drawer__header",
            staticStyle: { "text-align": "left" }
          },
          [
            _c("h3", { staticClass: "mdc-drawer__title" }, [
              _vm._v(_vm._s(_vm.name))
            ]),
            _c("h6", { staticClass: "mdc-drawer__subtitle" }, [
              _vm._v(_vm._s(_vm.email))
            ]),
            _c("h1", [_vm._v("$ " + _vm._s(_vm.accountBalance))])
          ]
        ),
        _c("div", { staticClass: "mdc-drawer__content" }, [
          _c("nav", { staticClass: "mdc-list", attrs: { tabindex: "0" } }, [
            _c(
              "a",
              {
                staticClass: "mdc-list-item mdc-list-item--selected",
                attrs: { href: "#", "aria-current": "page", tabindex: "0" },
                on: {
                  click: function($event) {
                    return _vm.openDialog(
                      _vm.normalSettingItems.export.prompt.ref
                    )
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons mdc-list-item__graphic",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v(_vm._s(_vm.normalSettingItems.export.icon))]
                ),
                _c("span", { staticClass: "mdc-list-item__text" }, [
                  _vm._v(_vm._s(_vm.normalSettingItems.export.name))
                ])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "mdc-list-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toBulkImportPage.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons mdc-list-item__graphic",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v(_vm._s(_vm.normalSettingItems.bulkImport.icon))]
                ),
                _c("span", { staticClass: "mdc-list-item__text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.normalSettingItems.bulkImport.name) +
                      "\n          "
                  )
                ])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "mdc-list-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.openDialog(
                      _vm.normalSettingItems.language.prompt.ref
                    )
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons mdc-list-item__graphic",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v(_vm._s(_vm.normalSettingItems.language.icon))]
                ),
                _c("span", { staticClass: "mdc-list-item__text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.normalSettingItems.language.name) +
                      "\n          "
                  )
                ])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "mdc-list-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.openDialog(
                      _vm.normalSettingItems.resetPassword.prompt.ref
                    )
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons mdc-list-item__graphic",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v(_vm._s(_vm.normalSettingItems.resetPassword.icon))]
                ),
                _c("span", { staticClass: "mdc-list-item__text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.normalSettingItems.resetPassword.name) +
                      "\n          "
                  )
                ])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "mdc-list-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toRequestLabelForm.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons mdc-list-item__graphic",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v(_vm._s(_vm.normalSettingItems.requestLabel.icon))]
                ),
                _c("span", { staticClass: "mdc-list-item__text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.normalSettingItems.requestLabel.name) +
                      "\n          "
                  )
                ])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "mdc-list-item",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.openDialog(
                      _vm.normalSettingItems.logout.prompt.ref
                    )
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons mdc-list-item__graphic",
                    attrs: { "aria-hidden": "true" }
                  },
                  [_vm._v(_vm._s(_vm.normalSettingItems.logout.icon))]
                ),
                _c("span", { staticClass: "mdc-list-item__text" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.normalSettingItems.logout.name) +
                      "\n          "
                  )
                ])
              ]
            )
          ])
        ])
      ]
    ),
    _c("div", {
      staticClass: "mdc-drawer-scrim",
      attrs: { id: "mdc-drawer-scrim" }
    }),
    _c("div", { staticClass: "drawer-prompt__container" }, [
      _c(
        "div",
        { staticClass: "prompt-item" },
        [
          _c("ConfirmPrompt", {
            ref: _vm.normalSettingItems.logout.prompt.ref,
            attrs: {
              message: _vm.normalSettingItems.logout.prompt.message,
              reference: _vm.normalSettingItems.logout.name,
              event: "clicked"
            },
            on: { clicked: _vm.normalSettingItems.logout.function }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "prompt-item" },
        [
          _c("ResetPasswordDialog", {
            ref: _vm.normalSettingItems.resetPassword.prompt.ref
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "prompt-item" },
        [
          _c("ChangeLanguageDialog", {
            ref: _vm.normalSettingItems.language.prompt.ref
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "prompt-item" },
        [
          _c("ExportReportDialog", {
            ref: _vm.normalSettingItems.export.prompt.ref
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }