
































import Component from "vue-class-component";
import Vue from "vue";
import { MDCDialog } from "@material/dialog";
import TERMS_HTML from "@/assets/TERMS_HTML";

@Component
export default class Terms extends Vue {
  private dialog!: MDCDialog;

  get TERMS_HTML() {
    return TERMS_HTML;
  }
  get text() {
    return {
      termsTitle: this.$t("dialogs.terms.termsTitle"),
      termsButton: this.$t("dialogs.terms.termsButton")
    };
  }

  public mounted() {
    this.dialog = new MDCDialog(document.getElementById("terms-dialog") as any);
  }
  public show() {
    this.dialog.open();
  }
}
