var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: _vm.reference, staticClass: "mdc-data-table" }, [
    _c("table", { staticClass: "mdc-data-table__table" }, [
      _c("thead", [
        _c(
          "tr",
          { staticClass: "mdc-data-table__header-row" },
          _vm._l(_vm.headers, function(header, columnId) {
            return _c(
              "th",
              {
                key: columnId,
                staticClass: "mdc-data-table__header-cell",
                attrs: {
                  role: "columnheader",
                  scope: "col",
                  "aria-sort": "none",
                  "data-column-id": columnId
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "mdc-data-table__header-cell-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "mdc-data-table__header-cell-label" },
                      [
                        _vm._v(
                          "\n              " + _vm._s(header) + "\n            "
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "tbody",
        { staticClass: "mdc-data-table__content" },
        _vm._l(_vm.rows, function(row, columnId) {
          return _c(
            "tr",
            { key: columnId, staticClass: "mdc-data-table__row" },
            _vm._l(row, function(data, index) {
              return _c(
                "td",
                { key: index, staticClass: "mdc-data-table__cell" },
                [_vm._v("\n          " + _vm._s(data) + "\n        ")]
              )
            }),
            0
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }