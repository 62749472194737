var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "iframe__container" }, [
    _c("div", { staticClass: "iframe-inner__container" }, [
      _vm.showIframe
        ? _c("div", {
            staticClass: "iframe__scrim",
            on: {
              click: function($event) {
                return _vm.quitIFrame()
              }
            }
          })
        : _vm._e(),
      _c("iframe", {
        ref: "iframe",
        class: { "iframe--show": _vm.showIframe },
        attrs: { src: _vm.url }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }