var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-dialog", attrs: { id: "change-language-prompt" } },
    [
      _c("div", { staticClass: "mdc-dialog__container" }, [
        _c(
          "div",
          {
            staticClass: "mdc-dialog__surface",
            attrs: {
              role: "alertdialog",
              "aria-modal": "true",
              "aria-labelledby": "change-language-prompt-title",
              "aria-describedby": "change-language-prompt-content"
            }
          },
          [
            _c(
              "h2",
              {
                staticClass: "mdc-dialog__title",
                attrs: { id: "change-language-prompt-title" }
              },
              [_vm._v("\n        " + _vm._s(_vm.text.title) + "\n      ")]
            ),
            _c(
              "div",
              {
                staticClass: "mdc-dialog__content",
                attrs: { id: "change-language-prompt-content" }
              },
              [
                _c(
                  "ul",
                  { staticStyle: { "list-style-type": "none", padding: "0" } },
                  _vm._l(_vm.languageMenu, function(item) {
                    return _c("li", { key: item.name }, [
                      _c(
                        "button",
                        {
                          staticClass: "mdc-button mdc-dialog__button",
                          staticStyle: {
                            width: "100%",
                            "justify-content": "left",
                            height: "10vh"
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return item.function()
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "mdc-button__ripple" }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "mdc-list-item__text mdc-button__label",
                              staticStyle: { color: "grey" }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "mdc-dialog__scrim" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }