var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      staticStyle: { height: "100%", width: "100%" }
    },
    [
      _c(
        "div",
        {
          ref: _vm.reference,
          staticClass: "mdc-text-field mdc-text-field--textarea",
          style: {
            width: _vm.width || "inherit",
            height: _vm.height || "inherit"
          }
        },
        [
          _c("textarea", {
            staticClass: "mdc-text-field__input",
            attrs: {
              rows: "5",
              cols: "40",
              required: _vm.isRequired,
              maxlength: _vm.maxlength || "255",
              minlength: _vm.minlength || "0",
              autofocus: _vm.autofocus,
              "aria-labelledby": _vm.reference
            }
          }),
          _c("div", { staticClass: "mdc-notched-outline" }, [
            _c("div", { staticClass: "mdc-notched-outline__leading" }),
            _c("div", { staticClass: "mdc-notched-outline__notch" }, [
              _c(
                "label",
                {
                  staticClass: "mdc-floating-label",
                  attrs: { id: _vm.reference }
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            ]),
            _c("div", { staticClass: "mdc-notched-outline__trailing" })
          ])
        ]
      ),
      _vm.showCounter
        ? _c("div", { staticClass: "mdc-text-field-helper-line" }, [
            _c("div", { staticClass: "mdc-text-field-character-counter" }, [
              _vm._v("0 / 10")
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }