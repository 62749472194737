var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login__container" }, [
    _vm._m(0),
    _c(
      "form",
      {
        staticClass: "login-form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.login.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "login-form-row login-form__input" }, [
          _c(
            "div",
            { staticClass: "login-form__field" },
            [
              _c("TheMDCTextField", {
                ref: "login",
                attrs: {
                  reference: "login",
                  type: "email",
                  isRequired: true,
                  label: _vm.text.loginId,
                  forceFloating: true
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "login-form-row login-form__input" }, [
          _c(
            "div",
            { staticClass: "login-form__field" },
            [
              _c("TheMDCTextField", {
                ref: "password",
                attrs: {
                  reference: "password",
                  type: "password",
                  isRequired: true,
                  label: _vm.text.password,
                  minlength: "8",
                  maxlength: "30",
                  forceFloating: true
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "login-form-row" }, [
          _c(
            "div",
            { staticClass: "login-button__container" },
            [
              _c("TheMDCButtonShapedRaised", {
                attrs: {
                  reference: "loginblt",
                  type: "submit",
                  label: _vm.text.login
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "login-form-row",
            staticStyle: { "margin-top": "2vh" }
          },
          [
            _c(
              "div",
              { staticClass: "login-button__container" },
              [
                _c("TheMDCButtonShaped", {
                  attrs: {
                    reference: "referenceblt",
                    type: "button",
                    event: "register",
                    label: _vm.text.register
                  },
                  on: { register: _vm.register }
                })
              ],
              1
            )
          ]
        )
      ]
    ),
    _c("div", { staticClass: "login-terms__container position-to-page" }, [
      _c("div", { staticClass: "login-terms" }, [
        _vm._v("\n      " + _vm._s(_vm.text.terms0) + "\n      "),
        _c(
          "button",
          { staticClass: "text-button", on: { click: _vm.showPrivacy } },
          [_c("u", [_vm._v(_vm._s(_vm.text.privacyPolicy))])]
        ),
        _vm._v("\n      " + _vm._s(_vm.text.terms1) + "\n      "),
        _c(
          "button",
          { staticClass: "text-button", on: { click: _vm.showTerms } },
          [_c("u", [_vm._v(_vm._s(_vm.text.termsOfUse))])]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "prompt__container" },
      [
        _c("ErrorPrompt", {
          ref: "loginError",
          attrs: {
            reference: "loginError",
            message: _vm.text.loginError.message
          }
        }),
        _c("Privacy", { ref: "privacy" }),
        _c("Terms", { ref: "terms" })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-logo__container" }, [
      _c("img", {
        staticClass: "login-logo",
        attrs: {
          src: require("../assets/icon_round.svg"),
          alt: "No image is found"
        }
      }),
      _c("div", { staticClass: "login-logo-title" }, [_vm._v("jumppoint")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }