





















































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import App from "@/App.vue";

// The MDC Components
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonOutlined from "@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue";
import ConfirmDialog from "@/components/prompts/ConfirmDialog.vue";


// utils
import * as helper from "@/utils/fetchDataHelpers.ts";

//css
import  * as theme from '@/assets/scss/_theme.scss';

@Component({
  components: {
    TheMDCButtonShapedRaised,
    TheMDCButtonOutlined,
    ConfirmDialog
    }
})export default class CardDisplay extends Vue implements VuePromptCaller {
  private ROOT = this.$root.$children[0] as App;
  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  
  @Prop() card!: Card;
  @Prop() showButton?: boolean;

  @Ref("remove-card-confirm-dialog") readonly removeCardConfirmDialog!: ConfirmDialog;

  get text() {
    return {
      expiratory: this.$t("credits.cardDisplay.expiratory"),
      stars: this.$t("credits.cardDisplay.stars"),
      addCredit: this.$t("credits.credits.addCredit"),

      success: this.$t("credits.credits.success"),
      fail: this.$t("credits.credits.fail"),
      cardSuccess: this.$t("credits.credits.cardSuccess"),
      cardFail: this.$t("credits.credits.cardFail"),
    };
  }

  get theme(){
    return theme;
  }

  private showRemoveCardDialog(){
    this.removeCardConfirmDialog.show({
      acceptBlt: this.$t("general.confirm") as string, // default to have, need explicit like turn it off
    closeBlt: this.$t("general.notOkay") as string,
    title: this.$t("credits.confirmRemoveCardDialog.title") as string,
    HTMLMsg: "",
    msg: [
      this.$t("credits.confirmRemoveCardDialog.confirmRemovingFollowingCard") as string,
      "",
      this.card.brand,
      this.$t("credits.cardDisplay.cardholderName") as string + this.$t('general.colon') + (this.card.name),
      this.$t("credits.cardDisplay.expiryDate") as string + this.$t('general.colon') + (this.card.expiryMonth + "/" + this.card.expiryYear),
      this.$t("credits.cardDisplay.stars") as string + " " + this.card.last4
    ].join("\n"),
    listener: this,
    acceptCallback: "removeCard",
    });
  }

  private toAddCredits(): void {
    // show add credit prompt
    this.ROOT.showAddCreditsPromptComponentPrompt({
      listener: this,
      card: this.card,
      execution: (event: string, payload: number) => {
        this.topUp(payload);
      }
    });
  }
  
  public async removeCard(): Promise<void> {
    this.$store.commit("showLoading");
    const removeCardResult = await this.$store.dispatch("credits/removeCard", {
      paymentMethodId: this.card.id
    });
    if(!removeCardResult){
      this.$store.commit("showConnectionError");
    }
    this.$store.commit("hideLoading");
  }

  private async topUp(payload: any): Promise<void> {
    // cover up add credit with loading page
    this.$store.commit("showLoading");
    const canPay = await this.$store.dispatch("credits/charge", {
      paymentMethodId: payload.paymentMethodId,
      amount: payload.amount
    });
    this.$store.commit("hideLoading");
    // prompt according to add credit success
    if (canPay == "paymentError"){
      this.showPaymentFailPrompt(this.$t('credits.credits.tryAnotherCreditCard') as string);
    }else if (canPay) {
      // if success, start connecting with backend untill payment is settled
      // cover up with loading page
      this.$store.commit("showLoading");
      await this.fetchPaymentStatus();
    } else if (canPay === false) {
      // if payment failed
      this.showPaymentFailPrompt();
    }
  }

  private async fetchPaymentStatus(): Promise<void> {
    // need some helper function for await five seconds
    // https://codingwithspike.wordpress.com/2018/03/10/making-settimeout-an-async-await-function/
    async function wait(ms: number) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    }
    async function doRequests(func: () => Promise<any>, ms: number) {
      await wait(ms);
      return await func();
    }
    const getChargeStatus = async () => {
      const status = await this.$store.dispatch("credits/getChargeStatus");
      return status;
    };

    /**
     * keep fetching payment status untill one of the following 3 cases:
     * - success
     * - failed
     * - overtime failed
     */
    for (let i = 0; i < 35; i++) {
      const paymentSuccess = await doRequests(
        getChargeStatus,
        Math.min(1000 * i, 5000) // at most wait 5 seconds
      );
      if (paymentSuccess === true) {
        // if success, show payment success prompt
        await helper.fetchUserLoginInfo(); // refech newest data after add credit
        this.showPaymentSuccessPrompt();
        this.$store.commit("hideLoading");
        return;
      } else if (paymentSuccess === false) {
        // this.showPaymentFailPrompt(); // if failed, show payment failed prompt
        break;
      } else if (paymentSuccess === "TRY_AGAIN") {
        // or else try again
        continue;
      }else {
        continue;
      }
    }
    // after 12 prompt
    this.showPaymentFailPrompt();// if failed, show payment failed prompt or timeout
    this.$store.commit("hideLoading");


  }

  private showPaymentSuccessPrompt() {
    // if payment success, reload page to get the newest data
    this.ROOT.showPrompt({
      title: this.$t("dialogs.success") as string,
      msg: this.text.success as string,
      acceptBlt: this.$t("dialogs.okay") as string,
      listener: this,
      execution: async () => {
        // if payment success, reload page to get the newest data
        // location.reload();
      }
    });
  }
  private showPaymentFailPrompt(message: string | null= null) {
    // if failed, do nothing
    this.ROOT.showPrompt({
      title: this.$t("dialogs.fail") as string,
      msg: message?message:this.text.fail as string,
      acceptBlt: this.$t("dialogs.close") as string
    });

  }
  
}
