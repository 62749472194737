var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-text-field-container", staticStyle: { width: "100%" } },
    [
      _c(
        "label",
        {
          ref: _vm.reference,
          staticClass: "mdc-text-field mdc-text-field--outlined",
          staticStyle: { width: "100%" }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value"
              }
            ],
            staticClass: "mdc-text-field__input",
            attrs: {
              type: "date",
              id: _vm.reference,
              "aria-labelledby": _vm.reference + "-id",
              required: _vm.isRequired,
              max: _vm.max || null,
              min: _vm.min || null
            },
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.value = $event.target.value
              }
            }
          }),
          _c("span", { staticClass: "mdc-notched-outline" }, [
            _c("span", { staticClass: "mdc-notched-outline__leading" }),
            _c("span", { staticClass: "mdc-notched-outline__notch" }, [
              _c(
                "span",
                {
                  staticClass: "mdc-floating-label",
                  attrs: { id: _vm.reference + "-id" }
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            ]),
            _c("span", { staticClass: "mdc-notched-outline__trailing" })
          ])
        ]
      ),
      _vm.helperText
        ? _c(
            "div",
            { staticClass: "mdc-text-field-helper-line helper-line-container" },
            [
              _c(
                "div",
                {
                  staticClass: "helper-text",
                  attrs: { id: "mdc-helper-text", "aria-hidden": "true" }
                },
                [_vm._v("\n    " + _vm._s(_vm.helperText) + "\n  ")]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }