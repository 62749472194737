var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow(),
          expression: "isShow()"
        }
      ],
      staticClass: "top-bar__container"
    },
    [
      _c("header", { staticClass: "mdc-top-app-bar" }, [
        _c("div", { staticClass: "mdc-top-app-bar__row" }, [
          _c(
            "section",
            {
              staticClass:
                "mdc-top-app-bar__section mdc-top-app-bar__section--align-start",
              staticStyle: { background: "transparent", cursor: "pointer" },
              on: { click: _vm.toHomePage }
            },
            [
              _c("img", {
                staticClass: "material-icons mdc-top-app-bar__navigation-icon",
                staticStyle: {
                  "border-radius": "9999px",
                  height: "30px",
                  "margin-left": "10px",
                  "vertical-align": "middle"
                },
                attrs: {
                  src: require("../../assets/icon_round.svg"),
                  alt: "No Img"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "mdc-top-app-bar__title",
                  staticStyle: {
                    "margin-top": "5px",
                    "vertical-align": "middle"
                  }
                },
                [_vm._v("\n          " + _vm._s(_vm.text.title) + "\n        ")]
              )
            ]
          ),
          _c(
            "section",
            {
              staticClass:
                "mdc-top-app-bar__section mdc-top-app-bar__section--align-end",
              staticStyle: { background: "transparent" },
              attrs: { role: "toolbar" }
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "material-icons mdc-top-app-bar__action-item mdc-icon-button",
                  staticStyle: {
                    color: "grey",
                    "margin-right": "5px",
                    "margin-top": "5px"
                  },
                  attrs: { "aria-label": "Open Setting" },
                  on: {
                    click: function($event) {
                      return _vm.showSetting()
                    }
                  }
                },
                [_vm._v("\n          settings\n        ")]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }