










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { MDCTopAppBar } from "@material/top-app-bar";

@Component
export default class TopBar extends Vue {
  get text() {
    return {
      title: "jumppoint"
    };
  }
  private isShow = () => this.$store.getters.showTopBar;

  show() {
    this.$store.commit("showTopBar");
  }
  hide() {
    this.$store.commit("hideTopBar");
  }
  get showSettingButton() {
    return this.$store.getters.showSettingButton;
  }
  showSetting() {
    this.$store.commit("showSettingBar");
  }

  mounted() {
    new MDCTopAppBar(document.querySelector(".mdc-top-app-bar") as any);
  }

  async toHomePage() {
    if (this.$router.currentRoute.name != "Shipment") {
      await this.$router.push({ name: "Shipment" });
      this.$store.commit("shipment/resetRate");
      this.$store.commit("shipment/resetPickup");
    }
  }
}
