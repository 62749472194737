var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: _vm.reference,
      staticClass: "mdc-button mdc-button--outlined",
      staticStyle: { "justify-content": "left", "min-width": "100px" },
      style: {
        width: _vm.width || "inherit",
        height: _vm.height || "inherit",
        backgroundColor: _vm.isDisabled
          ? _vm.theme.disabledColor
          : _vm.backgroundColor || "transparent"
      },
      attrs: { disabled: _vm.isDisabled },
      on: {
        click: function($event) {
          return _vm.emitToParent()
        }
      }
    },
    [
      _c("span", {
        staticClass: "mdc-button__ripple",
        staticStyle: { "-webkit-tap-highlight-color": "rgba(0,0,0,0)" }
      }),
      _vm.startIcon
        ? _c(
            "i",
            {
              staticClass: "material-icons mdc-button__icon",
              staticStyle: { "vertical-align": "middle" },
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v(_vm._s(_vm.startIcon))]
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "mdc-button__label",
          staticStyle: { "text-align": "center", "vertical-align": "middle" },
          style: { fontSize: _vm.fontSize }
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      ),
      _vm.trailIcon
        ? _c(
            "i",
            {
              staticClass: "material-icons mdc-button__icon",
              staticStyle: { "vertical-align": "middle" },
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v(_vm._s(_vm.trailIcon))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }