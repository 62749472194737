









import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import TheMDCIconButton from '@/components/mdcComponents/buttons/TheMDCIconButton.vue';
import { debounce } from 'lodash';

@Component({
  components: {
    TheMDCIconButton,
  },
})
export default class IframePrompt extends Vue {
  @Ref('iframe') iFrameElement!: HTMLIFrameElement;
  mounted() {
    window.addEventListener('message', this.handleEvent, false);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.handleEvent, false);
  }

  handleEvent(event: any) {
    if (event.data === 'quit-iframe') {
      this.quitIFrame();
    }
  }

  public show(data: IframePromptData) {
    const {
      acceptBlt, // default to have, need explicit like turn it off
      closeBlt,
      title,
      HTMLMsg,
      msg,
      listener,
      emitData,
      execution,
      url,
    } = data;

    this.title = title || null;
    this.listener = listener || null;
    this.execution = execution || null;
    this.url = url || null;
    this.showIframe = true;
  }

  private reset() {
    this.iFrameElement.classList.remove('iframe--show');
    setTimeout(() => {
      this.title = null;
      this.listener = null;
      this.execution = null;
      this.url = null;
      this.showIframe = false;
    }, 0.5 * 1000);
  }

  private quitIFrame() {
    if (this.listener) {
      this.listener.on('accept', null, this.execution);
    }
    this.reset();
  }

  private title: string | null = null;
  private listener: VuePromptCaller | null = null;
  private execution: CallBack<void> | null = null;
  private url: string | null = null;

  private showIframe = false;
}
