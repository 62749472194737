var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-dialog", attrs: { id: "export-report-prompt" } },
    [
      _c("div", { staticClass: "mdc-dialog__container" }, [
        _c(
          "div",
          {
            staticClass: "mdc-dialog__surface",
            attrs: {
              role: "alertdialog",
              "aria-modal": "true",
              "aria-labelledby": "export-report-title",
              "aria-describedby": "export-report-content"
            }
          },
          [
            _c(
              "h2",
              {
                staticClass: "mdc-dialog__title",
                attrs: { id: "export-report-title" }
              },
              [
                _vm.isShipments
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "sidebars.settingBar.exportReportDialog.description.shipments"
                          )
                        )
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "sidebars.settingBar.exportReportDialog.description.logs"
                          )
                        )
                      )
                    ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "mdc-dialog__content",
                attrs: { id: "export-report-content" }
              },
              [
                _c("section", [
                  _c("div", { staticClass: "form-group grid-container" }, [
                    _c(
                      "div",
                      { staticClass: "form__row" },
                      [
                        _c("TheMDCSelectOutlined", {
                          ref: "select-report",
                          staticStyle: { margin: "0 auto" },
                          attrs: {
                            reference: "selectReport",
                            label: _vm.text.selectReport,
                            isRequired: true,
                            menuList: this.reportList,
                            defaultValue: "shipments",
                            height: "50px",
                            event: "select-value"
                          },
                          on: { "select-value": _vm.changeSelectValue }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form__row" },
                      [
                        _c("TheMDCDatePicker", {
                          ref: "fromDate",
                          attrs: {
                            reference: "fromDate",
                            label: _vm.text.fromDate,
                            isRequired: true,
                            event: "from-date-change"
                          },
                          on: { "from-date-change": _vm.changeFromDate }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form__row" },
                      [
                        _c("TheMDCDatePicker", {
                          ref: "toDate",
                          attrs: {
                            reference: "toDate",
                            label: _vm.text.toDate,
                            isRequired: true,
                            event: "to-date-change"
                          },
                          on: { "to-date-change": _vm.changeToDate }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "mdc-dialog__actions" }, [
              _c(
                "button",
                {
                  staticClass: "mdc-button mdc-dialog__button",
                  attrs: { type: "button", "data-mdc-dialog-action": "close" }
                },
                [
                  _c("div", { staticClass: "mdc-button__ripple" }),
                  _c("span", { staticClass: "mdc-button__label" }, [
                    _vm._v(_vm._s(_vm.text.notOkay))
                  ])
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "mdc-button mdc-dialog__button",
                  attrs: {
                    type: "button",
                    "data-mdc-dialog-action": "accept",
                    disabled: !_vm.canProceed
                  },
                  on: { click: _vm.exportReport }
                },
                [
                  _c("div", { staticClass: "mdc-button__ripple" }),
                  _c("span", { staticClass: "mdc-button__label" }, [
                    _vm._v(_vm._s(_vm.text.okay))
                  ])
                ]
              )
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "mdc-dialog__scrim" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }