

































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class InAppNoticesPrompt extends Vue {
    dialog = false;
    currNotiIndex = 0;
    direction = '';

    // UI show and disable logic
    // protected showOneNotice(index: number): boolean {
    //   return this.currNotiIndex === index;
    // }

    tran() {
        if (this.direction === 'right') return 'list';
        if (this.direction === 'left') return 'listReverse';
    }

    onSwipeLeft() {
        this.direction = 'left';

        if (this.currNotiIndex < this.dataLastIndex!) {
            this.onClickNextButton(this.data![this.currNotiIndex].inAppNoticeUserId);
        }
    }
    onSwipeRight() {
        this.direction = 'right';

        if (!this.isPrevButtonDisabled()) {
            this.prevCurrNotiIndex();
        }
    }

    protected showDotIndicator(): boolean {
        return this.dataLastIndex !== 0;
    }

    protected showPrevButton(): boolean {
        return this.currNotiIndex > 0;
    }
    protected isPrevButtonDisabled(): boolean {
        return this.currNotiIndex === 0;
    }
    protected isNextButtonDisabled(): boolean {
        return this.currNotiIndex === this.dataLastIndex! + 1;
    }
    protected showCheckBox(bool: boolean) {
        return bool;
    }
    protected checked(id: string): boolean | null {
        const result = this.checkedList!.filter(temp => temp.inAppNoticeUserId === id)[0].doNotShowAgain;
        return result;
    }

    // UI action and methods
    protected prevCurrNotiIndex() {
        this.currNotiIndex--;
        this.direction = 'left';
    }
    protected nextCurrNotiIndex(id: string) {
        if (this.checkedOneSeen) {
            this.checkedOneSeen(id);
        }

        this.currNotiIndex++;
        this.direction = 'right';
    }
    protected onClickNextButton(id: string) {
        if (this.dataLastIndex !== null) {
            this.currNotiIndex < this.dataLastIndex ? this.nextCurrNotiIndex(id) : this.onClickSubmit(id);
        }
    }
    protected onClickCheckbox(id: string, bool: boolean) {
        if (this.checkedOneDoNotShowAgain) {
            this.checkedOneDoNotShowAgain(id, bool);
        }
    }
    protected onClickSubmit(lastId: string) {
        if (this.checkedOneSeen && this.onSubmit) {
            this.checkedOneSeen(lastId);
            this.onSubmit();
            this.hide();
        }
    }

    // this component props logic
    public show(props: InAppNoticePromptData) {
        const {
            locale,
            acceptBlt,
            closeBlt,
            dismissCheckBox,
            data,
            checkedList,
            onSubmit,
            checkedOneDoNotShowAgain,
            checkedOneSeen,
        } = props;

        this.dataLastIndex = data.length - 1;
        this.locale = locale;
        this.acceptBlt = acceptBlt;
        this.closeBlt = closeBlt;
        this.dismissCheckBox = dismissCheckBox;
        this.data = data;
        this.checkedList = checkedList;
        this.onSubmit = onSubmit;
        this.checkedOneDoNotShowAgain = checkedOneDoNotShowAgain;
        this.checkedOneSeen = checkedOneSeen;

        if (data) {
            this.dialog = true;
        }
    }
    private hide() {
        this.dialog = false;
        this.reset();
    }
    private reset() {
        this.locale = null;
        this.acceptBlt = null;
        this.closeBlt = null;
        this.dismissCheckBox = null;
        this.data = null;
        this.dataLastIndex = null;
        this.checkedList = null;
        this.onSubmit = null;
        this.checkedOneSeen = null;
        this.checkedOneDoNotShowAgain = null;
    }

    protected locale: string | null = null;
    protected acceptBlt: string | null = null;
    protected closeBlt: string | null = null;
    protected dismissCheckBox: string | null = null;
    protected data: InAppNoticeData[] | null = null;
    protected dataLastIndex: number | null = null;
    protected checkedList: CheckedByUserList[] | null = null;
    protected onSubmit: (() => void) | null = null;
    protected checkedOneSeen: ((id: string) => void) | null = null;
    protected checkedOneDoNotShowAgain: ((id: string, doNotShowAgain: boolean) => void) | null = null;
}
