import { AxiosInstance } from "axios";
import { Module } from "vuex";


import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { ShipmentHelpers } from "@/utils/shipmentHelpers";

export function expressOrderHistorialInputModule(axiosInstance: AxiosInstance) {
  const expressOrderHistorialInput: Module<any, any> = {
    namespaced: true,
    state: {
      userSavedSenderAddresses: [] as ExpressOrderAddress[], // Sender Addresses saved in DB
      userSavedReceiverAddresses: [] as ExpressOrderAddress[], // Receiver Addresses saved in DB
      userSavedItems: [] as ExpressOrderItem[], // Items saved in DB
      userSavedSameDayItems: [] as ExpressOrderItem[], // Items saved in DB
    },
    getters: {
      getUserSavedSenderAddresses(state: any) {
        return state.userSavedSenderAddresses;
      },
      getUserSavedReceiverAddresses(state: any) {
        return state.userSavedReceiverAddresses;
      },
      /**
       * get user saved address by id if exist, return null otherwise
       */
    //   getUserSavedAddressById(
    //     state: any
    // ): (addressId: string, identity: "Sender" | "Receiver") => ExpressOrderAddress | null {
    //     return (addressId: string, identity: "Sender" | "Receiver") => {
    //       if(identity === "Sender"){
    //         return state.userSavedSenderAddresses.find(
    //           (address: ExpressOrderAddress) => address.id === addressId
    //         );
    //       }else if(identity === "Receiver"){
    //         return state.userSavedReceiverAddresses.find(
    //           (address: ExpressOrderAddress) => address.id === addressId
    //         );
    //       }else{
    //         return ShipmentHelpers.emptyAddress();
    //       }
    //     };
    //   },
      getUserSavedItems(state: any): ExpressOrderItem[] {
        return state.userSavedItems || [];
      },
      /**
       *  get user saved item by id if exist, return null otherwise
       * Using index as Saved Item Id, Be awared always save item object not the index coz index will change when savedItem is fetch from backend
       */
      getUserSavedItemById(state: any): (itemId: number) => ExpressOrderItem | null {
        return (itemId: number) => {
          return (
            state.userSavedItems[itemId]
            || null
          );
        };
      },
      getUserSavedSameDayItems(state: any): ExpressOrderItem[] {
        return state.userSavedSameDayItems || [];
      },
      getUserSavedSameDayItemById(state: any): (itemId: number) => ExpressOrderItem | null {
        return (itemId: number) => {
          return (
            state.userSavedSameDayItems[itemId]
            || null
          );
        };
      }
    },
    mutations: {
      /**
       * Reset vuex userSavedSenderAddresses and saved the new data
       */
      insertUserSavedSenderAddresses(
        state: any,
        userSavedSenderAddresses: ExpressOrderAddress[]
      ) {
        // reset && insert
        state.userSavedSenderAddresses = [];
        if(userSavedSenderAddresses && userSavedSenderAddresses.length > 0){
          userSavedSenderAddresses.forEach((address: ExpressOrderAddress) => {
            state.userSavedSenderAddresses.push({
              id: address.id,
              contactName: address.contactName,
              phoneAreaCode: address.phoneAreaCode,
              contactPhone: address.contactPhone,
              areaRank: address.areaRank,
              fullAddress: address.fullAddress,
              isFreeInput: address.isFreeInput,
              streetEn: address.streetEn,
              streetChi: address.streetChi,
              buildingEn: address.buildingEn,
              buildingChi: address.buildingChi,
              floor: address.floor,
              room: address.room
            } as ExpressOrderAddress);
          });
        }
        
      },
      /**
       * Reset vuex userSavedReceiverAddresses and saved the new data
       */
      insertUserSavedReceiverAddresses(
        state: any,
        userSavedReceiverAddresses: ExpressOrderAddress[]
      ) {
        // reset && insert
        state.userSavedReceiverAddresses = [];
        if(userSavedReceiverAddresses){
          userSavedReceiverAddresses.forEach((address: ExpressOrderAddress) => {
            state.userSavedReceiverAddresses.push({
              id: address.id,
              contactName: address.contactName,
              phoneAreaCode: address.phoneAreaCode,
              contactPhone: address.contactPhone,
              areaRank: address.areaRank,
              fullAddress: address.fullAddress,
              isFreeInput: address.isFreeInput,
              streetEn: address.streetEn,
              streetChi: address.streetChi,
              buildingEn: address.buildingEn,
              buildingChi: address.buildingChi,
              floor: address.floor,
              room: address.room
            } as ExpressOrderAddress);
          });
        }
      },
      _resetUserSavedItems(state) {
        state.userSavedItems = [];
      },
      _addUserSavedItems(state, item: ExpressOrderItem) {
        state.userSavedItems.push(item);
      },
      _addUserSavedSameDayItems(state, item: ExpressOrderItem) {
        state.userSavedSameDayItems.push(item);
      },
      _resetUserSavedSameDayItems(state) {
        state.userSavedSameDayItems = [];
      },
    },
    actions: {

      async fetchCompanySavedInfo(store: any) {
        return await store.dispatch("fetchSavedOrigin") === true && await store.dispatch("fetchSavedDestination") === true && await store.dispatch("fetchSavedItems") === true;
      },
      // get sender saved address
      async fetchSavedOrigin(store: any) {

        const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "address/express-order/origin"
        })
        .then(response => {
          //console.log(response);
          if (response.data.success) {
            store.commit(
              "insertUserSavedSenderAddresses",
              response.data.data.addresses
            );
            return true;
          }
          return false;
        })
        .catch(error => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(error, console.trace());
        });
        return serverReturn;
      },

      async fetchSavedDestination(store: any){
        // get receiver saved address
        const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "address/express-order/destination"
        })
        .then(response => {
          //console.log(response);
          if (response.data.success) {
            store.commit(
              "insertUserSavedReceiverAddresses",
              response.data.data.addresses
            );
            return true;
          }
          return false;
        })
        .catch(error => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(error, console.trace());
        });
        return serverReturn;
      },

      async fetchSavedItems(store: any) {
        // get items
        const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url: "item/express-order"
          })
          .then(response => {
            //console.log(response);
            if (response.data.success) {
              store.dispatch("insertSavedItems", response.data.data.nonSameDay);
              store.dispatch("insertSavedSameDayItems", response.data.data.sameDay);
              return true;
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;        
      },
      insertSavedItems(store: any, userSavedItems: ExpressOrderItem[]) {
        // reset and insert
        store.commit("_resetUserSavedItems");
        if(userSavedItems){
          userSavedItems.forEach((item: ExpressOrderItem) => {
            store.commit("_addUserSavedItems", item);
            });
        }
      },
      insertSavedSameDayItems(store: any, userSavedItems: ExpressOrderItem[]) {
        // reset and insert
        store.commit("_resetUserSavedSameDayItems");
        if(userSavedItems){
          userSavedItems.forEach((item: ExpressOrderItem) => {
            store.commit("_addUserSavedSameDayItems", item);
            });
        }
        
      }
    }
  };

  return expressOrderHistorialInput;
}
