import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

import { historialInputModule } from "./options/historialInput/historialInput";
import { shipmentModule } from "./shipment";
import { exportReportModule } from "./exportReport";
import { trackingModule } from "./tracking";
import { creditsModule } from "./credits";
import { dateModule } from "./options/date";
import { addressModule } from "./options/address";
import { dialogModule } from "./dialog";
import { userModule } from "./user";
import { requestLabelModule } from "./requestLabel";
import { itemOptionModule } from "./options/itemOption/itemOption";
import { bulkImportModule } from "./bulkImport";
import { pickUpStoreModule } from "./options/pickUpStore";
import { inAppNoticesModule } from "./inAppNotices";

Vue.use(Vuex);

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_BASE_URI,
  timeout: 60000,
});

const store: any = new Vuex.Store({
  // strict: true,
  modules: {
    user: userModule(axiosInstance),
    historialInput: historialInputModule(axiosInstance),
    shipment: shipmentModule(axiosInstance),
    exportReport: exportReportModule(axiosInstance),
    tracking: trackingModule(axiosInstance),
    credits: creditsModule(axiosInstance),
    date: dateModule(axiosInstance),
    dialog: dialogModule(axiosInstance),
    address: addressModule(axiosInstance),
    itemOption: itemOptionModule(axiosInstance),
    requestLabel: requestLabelModule(axiosInstance),
    bulkImport: bulkImportModule(axiosInstance),
    pickUpStore: pickUpStoreModule(axiosInstance),
    inAppNotices: inAppNoticesModule(axiosInstance),
  },
  state: {
    // Login info
    isLogin: false,
    user: null as UserInfo | null,
    company: null as CompanyInfo | null,

    // state managment
    showTopBar: false,
    showNavBar: false,
    showSettingBar: false,
    hideSettingBar: false,
    showLoading: false,
    showLogoutDialog: false,
    showTitle: false,
    showConnectionError: false,
    showTrackingTitle: false,

    // per view storage
    login: {},
    dashboard: {},
  },
  getters: {
    isLogin: (state) => state.isLogin,
    getUser: (state) => state.user,
    getCompany: (state) => state.company,

    // state management
    showTopBar: (state) => state.showTopBar,
    showNavBar: (state) => state.showNavBar,
    showSettingBar: (state) => state.showSettingBar,
    hideSettingBar: (state) => state.hideSettingBar,
    showLoading: (state) => state.showLoading,
    showTitle: (state) => state.showTitle,
    showSettingButton: (state) => state.showNavBar,
    showConnectionError: (state) => state.showConnectionError,
    showTrackingTitle: (state) => state.showTrackingTitle,
  },
  mutations: {
    // login
    _login(state) {
      state.isLogin = true;
    },
    _logout(state) {
      state.isLogin = false;
      state.user = null;
      state.company = null;
      Storage.deleteAllFromSD();
    },
    insertUserInfo(state, user) {
      state.user = {
        id: user.id,
        name: user.name,
        email: user.email,
        phone: user.phone.toString(),
        permissions: user.permissions,
      };
    },
    insertCompanyInfo(state, company) {
      state.company = {
        id: company.id,
        name: company.name,
        nameAlias: company.nameAlias,
        easyshipSupport: company.easyshipSupport === 1 ? true : false,
        accountBalance: company.accountBalance,
      };
    },

    // state management
    showTopBar: (state) => (state.showTopBar = true),
    hideTopBar: (state) => (state.showTopBar = false),
    showNavBar: (state) => (state.showNavBar = true),
    hideNavBar: (state) => (state.showNavBar = false),
    showSettingBar: (state) => (state.showSettingBar = true),
    hideSettingBar: (state) => (state.hideSettingBar = true),
    resetShowSettingBar: (state) => (state.showSettingBar = false),
    resetHideSettingBar: (state) => (state.hideSettingBar = false),
    showLoading: (state) => (state.showLoading = true),
    hideLoading: (state) => (state.showLoading = false),
    showTitle: (state) => (state.showTitle = true),
    hideTitle: (state) => (state.showTitle = false),
    showConnectionError: (state) => {
      state.showConnectionError = true;
      console.trace();
    },
    hideConnectionError: (state) => (state.showConnectionError = false),
    showTrackingTitle: (state) => (state.showTrackingTitle = true),
    hideTrackingTitle: (state) => (state.showTrackingTitle = false),

    // //Tracking
    // setShipmentRecordType(state: any, type: 'Confirmed' | 'Completed') {
    //   state.shipmentRecordType = type;
    // },
  },
  actions: {
    /**
     * Login Info actions
     */
    async login(store, userAuth: UserAuth) {
      // server login
      const serverReturn = await axiosInstance
        .request({
          method: "POST",
          url: "auth/login",
          data: {
            email: userAuth.username,
            password: userAuth.password,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.success) {
            store.commit("_login");
            store.commit("insertUserInfo", response.data.data.user);
            store.commit("insertCompanyInfo", response.data.data.user.company);
            return true;
          }
          return false;
        })
        .catch((error) => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(
            error,
            console.trace()
          );
        });
      return serverReturn;
    },

    async logout(store) {
      const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "auth/logout",
        })
        .then((response) => {
          //console.log(response);
          if (response.data.success) {
            store.commit("_logout");
            return true;
          }
          return false;
        })
        .catch((error) => {
          console.log(error);
          return false;
          // return serverResponseHelpers.processServerErrors(error)
        });
      return serverReturn;
    },

    async fetchUserInfo(store) {
      const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "user/info",
        })
        .then((response) => {
          //console.log(response);
          if (response.data.success) {
            store.commit("insertUserInfo", response.data.data);
            return true;
          }
          return false;
        })
        .catch((error) => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(
            error,
            console.trace()
          );
        });
      return serverReturn;
    },

    async fetchCompanyInfo(store) {
      const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "company/info",
        })
        .then((response) => {
          //console.log(response);
          if (response.data.success) {
            store.commit("insertCompanyInfo", response.data.data);
            return true;
          }
          return false;
        })
        .catch((error) => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(
            error,
            console.trace()
          );
        });
      return serverReturn;
    },
  },
});

export default store;
