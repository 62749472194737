













































































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { MDCList } from "@material/list";
import { MDCRipple } from "@material/ripple";

import { MDCDialog } from "@material/dialog";

import TheMDCIconButton from "@/components/mdcComponents/buttons/TheMDCIconButton.vue";

@Component({
  components: {
    TheMDCIconButton
  }
})
export default class AddShipmentDataPrompt extends Vue {
  @Ref("add-shipment-data-prompt")
  readonly addShipmentDataPromptElement!: HTMLElement;
  private thisDialog!: MDCDialog;
  private thisList!: MDCList;

  get text() {
    return {
      addNewAddress: this.$t("dialogs.addShipmentDataPrompt.addNew"),
      modifyThisAddress: this.$t("dialogs.addShipmentDataPrompt.modifyThis"),

      kg: this.$t("dialogs.addShipmentDataPrompt.kg"),
      category: this.$t("dialogs.addShipmentDataPrompt.category"),
      itemValue: this.$t("dialogs.addShipmentDataPrompt.itemValue"),
      itemWeight: this.$t("dialogs.addShipmentDataPrompt.itemWeight")
    };
  }

  public mounted() {
    this.thisDialog = new MDCDialog(this.addShipmentDataPromptElement);
    this.thisList = new MDCList(
      document.querySelector(
        "#add-shipemnt-data-prompt-content .mdc-list"
      ) as HTMLElement
    );
    this.thisDialog.listen("MDCDialog:opened", () => {
      this.thisList.layout();
    });

    this.thisDialog.listen("MDCDialog:closing", (e: CustomEvent) => {
      if (this.listener && e.detail.action !== "close") {
        this.listener.on("accept", e.detail.action, this.execution);
      }
      // this.$emit(e.detail.action);
      this.reset();
    });
  }
  public updated() {
    this.registerMDC();
  }

  private registerMDC(): void {
    const listItemRipples = this.thisList.listElements.map(
      listItemEl => new MDCRipple(listItemEl)
    );
  }

  get addNewItem(): MenuItem {
    return {
      key: "addNewItem",
      value: false,
      expression: this.text.addNewAddress
    };
  }
  get modifyThisItem(): MenuItem {
    return {
      key: "modifyThisItem",
      value: null,
      expression: this.text.modifyThisAddress
    };
  }
  get hasThisItem(): boolean {
    if (this.action === null) {
      return false;
    }
    return this.action === "ModifyItem";
  }
  public show(data: AddShipmentFormData) {
    const { action, title, subtitle, menuList, listener, execution } = data;
    this.action = action;
    this.menuList = menuList;
    this.title = title || null;
    this.subtitle = subtitle;
    this.menuList = menuList;
    this.listener = listener || null;
    this.execution = execution || null;

    this.thisDialog.open();
  }


  // call when prompt need to be hided
  public hide(){
    if(typeof this.thisDialog !== 'undefined' && this.thisDialog.isOpen){
      this.thisDialog.close();
      this.reset();
    }
  }

  private reset() {
    (this.action = null), (this.menuList = null);
    this.title = null;
    this.subtitle = null;
    this.menuList = null;
    this.listener = null;
    this.execution = null;
  }

  private action: "CreateItem" | "ModifyItem" | null = null;
  private title: string | null = null;
  private subtitle: string | null = null;
  private menuList: MenuItem[] | null = null;
  private listener: VuePromptCaller | null = null;
  private execution: CallBack<void> | null = null;
}
