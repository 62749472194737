









import { Component, Vue, Prop } from "vue-property-decorator";
import TheMDCCircularProgress from "@/components/mdcComponents/progressIndicators/TheMDCCircularProgress.vue";

@Component({
  components: {
    TheMDCCircularProgress
  }
})
export default class Loading extends Vue {
  private isShow = () => this.$store.getters.showLoading;
  get text() {
    return {
      title: this.$t("transitions.loading.title")
    };
  }

  show() {
    this.$store.commit("showLoading");
  }
  hide() {
    this.$store.commit("hideLoading");
  }
}
