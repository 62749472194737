






































































import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import App from "../../../App.vue";

// mdc library
import { MDCDialog } from "@material/dialog";

// the mdc components
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";

@Component({
  components: {
    TheMDCTextFieldOutlined
  }
})
export default class ResetPasswordDialog extends Vue {
  @Ref("reset-password-dialog") resetPasswordDialogElement!: HTMLElement;
  @Ref("origin-pw") originPwComponent!: TheMDCTextFieldOutlined;
  @Ref("new-pw") newPwComponent!: TheMDCTextFieldOutlined;
  @Ref("re-enter-pw") reEnterPwComponent!: TheMDCTextFieldOutlined;
  private ROOT = this.$root.$children[0] as App;
  private dialog!: MDCDialog;

  get text() {
    return {
      title: this.$t("sidebars.settingBar.resetPasswordDialog.title"),
      okay: this.$t("dialogs.confirm"),
      notOkay: this.$t("dialogs.cancel"),
      originPw: this.$t("sidebars.settingBar.resetPasswordDialog.originPw"),
      newPw: this.$t("sidebars.settingBar.resetPasswordDialog.newPw"),
      reEnterPw: this.$t("sidebars.settingBar.resetPasswordDialog.reEnterPw")
    };
  }

  private originPwValue() {
    return this.originPwComponent.getValue() || "";
  }
  private newPwValue() {
    return this.newPwComponent.getValue() || "";
  }
  private reEnterPwValue() {
    return this.reEnterPwComponent.getValue() || "";
  }
  private hasPw() {
    return (
      this.originPwValue() != "" &&
      this.newPwValue() != "" &&
      this.reEnterPwValue() != ""
    );
  }
  private sameNewPw() {
    return this.newPwValue() === this.reEnterPwValue();
  }

  private minlength() {
    return (
      this.originPwValue().length >= 8 &&
      this.newPwValue().length >= 8 &&
      this.reEnterPwValue().length >= 8
    );
  }
  private canProceed() {
    return this.hasPw() && this.sameNewPw() && this.minlength();
  }

  private async proceed() {
    const canProceed = this.canProceed();
    if (canProceed) {
      // call backend and get result
      this.$store.commit("showLoading");
      const canReset = await this.$store.dispatch("user/changePassword", {
        originalPassword: this.originPwValue(),
        password: this.newPwValue()
      });
      this.$store.commit("hideLoading");
      if (canReset) {
        this.showSuccessPrompt();
        this.dialog.close("accept");
      } else {
        this.showOldPasswordWrongPrompt();
      }
    } else if (
      !canProceed &&
      !this.sameNewPw() &&
      this.hasPw() &&
      this.minlength()
    ) {
      this.showNotSamePwPrompt();
    }
  }
  private showNotSamePwPrompt() {
    this.ROOT.showPrompt({
      acceptBlt: this.$t("dialogs.okay") as string,
      msg: this.$t(
        "sidebars.settingBar.resetPasswordDialog.notSamePwPrompt.msg"
      ) as string
    });
  }
  private showOldPasswordWrongPrompt() {
    this.ROOT.showPrompt({
      acceptBlt: this.$t("dialogs.okay") as string,
      msg: this.$t(
        "sidebars.settingBar.resetPasswordDialog.oldPasswordWrongPrompt.msg"
      ) as string
    });
  }
  private showSuccessPrompt() {
    this.ROOT.showPrompt({
      acceptBlt: this.$t("dialogs.okay") as string,
      msg: this.$t("dialogs.success") as string
    });
  }

  public mounted() {
    // create the dialog
    this.dialog = new MDCDialog(this.resetPasswordDialogElement);

    // add button listener
    this.dialog.listen("MDCDialog:closing", (e: CustomEvent) => {
      // console.log(e.detail.action);
    });
  }

  public show() {
    this.dialog.open();
  }
}
