var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "add-shipment-data-prompt", staticClass: "mdc-dialog" },
    [
      _c("div", { staticClass: "mdc-dialog__container" }, [
        _c(
          "div",
          {
            staticClass: "mdc-dialog__surface",
            attrs: {
              role: "alertdialog",
              "aria-modal": "true",
              "aria-labelledby": "add-shipemnt-data-prompt-title",
              "aria-describedby": "add-shipemnt-data-prompt-content"
            }
          },
          [
            this.title
              ? _c(
                  "h2",
                  {
                    staticClass: "mdc-dialog__title",
                    attrs: { id: "add-shipemnt-data-prompt-title" }
                  },
                  [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "mdc-dialog__content",
                attrs: { id: "add-shipemnt-data-prompt-content" }
              },
              [
                _c(
                  "ul",
                  { staticClass: "mdc-list mdc-list--avatar-list" },
                  [
                    !_vm.hasThisItem
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "mdc-list-item mdc-list-item--activated",
                            attrs: {
                              tabindex: "0",
                              "data-mdc-dialog-action": "-1"
                            }
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  staticStyle: {
                                    "margin-right": "1rem",
                                    "vertical-align": "middle"
                                  }
                                },
                                [_vm._v("add")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "vertical-align": "middle" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.addNewItem.expression) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _c(
                          "li",
                          {
                            staticClass:
                              "mdc-list-item mdc-list-item--selected",
                            attrs: {
                              tabindex: "0",
                              "data-mdc-dialog-action": "-1"
                            }
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  staticStyle: {
                                    "margin-right": "1rem",
                                    "vertical-align": "middle"
                                  }
                                },
                                [_vm._v("build")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "vertical-align": "middle" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.modifyThisItem.expression) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                    _c("li", {
                      staticClass: "mdc-list-divider",
                      attrs: { role: "separator" }
                    }),
                    _c("li", [
                      (this.menuList
                      ? this.menuList.length > 0
                      : false)
                        ? _c("div", { staticClass: "inner-label" }, [
                            _c("h4", [_vm._v(_vm._s(this.subtitle))])
                          ])
                        : _vm._e()
                    ]),
                    _vm._l(_vm.menuList, function(item) {
                      return _c(
                        "li",
                        {
                          key: item.key,
                          staticClass: "mdc-list-item",
                          attrs: { "data-mdc-dialog-action": item.value }
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.additionData.itemId,
                                  expression: "!item.additionData.itemId"
                                }
                              ],
                              staticClass: "mdc-list-item__text"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "list-item__text-title" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.additionData.contactName) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "list-item__text-detail" },
                                [
                                  _vm._v(
                                    _vm._s(item.expression) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.additionData.itemId,
                                  expression: "item.additionData.itemId"
                                }
                              ],
                              staticClass: "mdc-list-item__text"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "list-item__text-title" },
                                [_vm._v(_vm._s(item.expression))]
                              ),
                              _c(
                                "span",
                                { staticClass: "list-item__text-detail" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.text.category) +
                                      ": " +
                                      _vm._s(item.additionData.category)
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "list-item__text-detail" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.text.itemValue) +
                                      ": " +
                                      _vm._s(
                                        item.additionData.declaredCurrency
                                      ) +
                                      "\n                " +
                                      _vm._s(
                                        item.additionData.declaredCustomsValue
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "list-item__text-detail" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.text.itemWeight) +
                                      ": " +
                                      _vm._s(item.additionData.actualWeight) +
                                      "\n                " +
                                      _vm._s(_vm.text.kg) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "mdc-dialog__scrim" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }