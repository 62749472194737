var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "balance-log__container container" }, [
    _c("h2", [_vm._v("\n    " + _vm._s(_vm.text.title) + "\n  ")]),
    _c(
      "ul",
      { staticClass: "balance-log__container__inner responsive-table" },
      [
        _c("li", { staticClass: "table-header" }, [
          _c("div", { staticClass: "col col-1" }, [
            _vm._v(_vm._s(_vm.text.date))
          ]),
          _c("div", { staticClass: "col col-2" }, [
            _vm._v(_vm._s(_vm.text.event))
          ]),
          _c("div", { staticClass: "col col-3" }, [
            _vm._v(_vm._s(_vm.text.amount))
          ])
        ]),
        _vm._l(_vm.logs, function(log) {
          return _c("li", { key: log.id, staticClass: "table-row" }, [
            _c(
              "div",
              {
                staticClass: "col col-1",
                attrs: { "data-label": _vm.text.date }
              },
              [_vm._v(_vm._s(_vm.formate_time(log.createdAt)))]
            ),
            _c(
              "div",
              {
                staticClass: "col col-2",
                attrs: { "data-label": _vm.text.event }
              },
              [_vm._v(_vm._s(_vm.formate_event(log.event)))]
            ),
            _c(
              "div",
              {
                staticClass: "col col-3",
                attrs: { "data-label": _vm.text.amount }
              },
              [_vm._v("$ " + _vm._s(log.difference))]
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }