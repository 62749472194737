var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-dialog", attrs: { id: "terms-dialog" } },
    [
      _c("div", { staticClass: "mdc-dialog__container" }, [
        _c(
          "div",
          {
            staticClass: "mdc-dialog__surface",
            attrs: {
              role: "alertdialog",
              "aria-modal": "true",
              "aria-labelledby": "text.termsTitle",
              "aria-describedby": "text.termsBody"
            }
          },
          [
            _c("h2", { staticClass: "mdc-dialog__title" }, [
              _vm._v(_vm._s(_vm.text.termsTitle))
            ]),
            _c("div", { staticClass: "mdc-dialog__content" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.TERMS_HTML) } })
            ]),
            _c("footer", { staticClass: "mdc-dialog__acitions" }, [
              _c(
                "button",
                {
                  staticClass: "mdc-button mdc-dialog__button",
                  attrs: { type: "button", "data-mdc-dialog-action": "close" }
                },
                [
                  _c("div", { staticClass: "mdc-button__ripple" }),
                  _c("span", { staticClass: "mdc-button__label" }, [
                    _vm._v(_vm._s(_vm.text.termsButton))
                  ])
                ]
              )
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "mdc-dialog__scrim" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }