var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon-button__container",
      style: { width: _vm.width || "inherit", height: _vm.height || "inherit" }
    },
    [
      _c(
        "button",
        {
          ref: _vm.reference,
          staticClass: "mdc-icon-button material-icons",
          class: { removeVerticalPadding: _vm.removeVerticalPadding },
          staticStyle: { width: "inherit", height: "inherit" },
          style: { color: _vm.color },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.emitToParent.apply(null, arguments)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.icon) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }