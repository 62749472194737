




























import { Component, Vue, Prop } from "vue-property-decorator";
import { MDCRipple } from "@material/ripple";

@Component
export default class NavBarItem extends Vue {
  @Prop() params!: any;
  private ripple!: MDCRipple;

  mounted() {
    // this.ripple = new MDCRipple(this.$refs[this.params.label] as Element);
    this.ripple = new MDCRipple(this.$refs[this.params.label] as HTMLElement);
    // this.ripple = new MDCRipple(this.$refs[this.params.label + '-ripple'] as Element);
    this.ripple.unbounded = true;
    // this.ripple.deactivate();
  }

  beforeDestroy() {
    this.ripple.destroy();
  }
}
