


























































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { MDCDialog } from "@material/dialog";

// using mdc dialog to do implementation
@Component
export default class ConfirmDialog extends Vue {
  @Prop() event?: string;

  @Ref("confirm-dialog") readonly thisPromptElement!: HTMLElement;
  private thisDialog!: MDCDialog;
  mounted() {
    this.thisDialog = new MDCDialog(this.thisPromptElement);

    this.thisDialog.listen("MDCDialog:closing", (e: CustomEvent) => {
      if(e.detail.action === "accept"){
        if(this.acceptCallback && this.listener){
          (this.listener[this.acceptCallback] as CallBack<any>)(this.callbackParams);
        }
      }else{
        if(this.closeCallback && this.listener){
          (this.listener[this.closeCallback] as CallBack<any>)(this.callbackParams);
        }
      }
      // if(this.event){
      //   this.$emit(this.event);
      // }
      // this.$emit(e.detail.action);
      this.reset();
    });
  }

  public show(data: ConfirmDialogData) {
    const {
      acceptBlt, // default to have, need explicit like turn it off
      closeBlt,
      title,
      HTMLMsg,
      msg,
      listener,
      acceptCallback,
      closeCallback,
      callbackParams
    } = data;

    this.acceptBlt = acceptBlt || null;
    this.closeBlt = closeBlt || null;
    this.title = title || null;
    this.HTMLMsg = HTMLMsg || null;
    this.msg = msg || null;
    this.listener = listener || null;
    this.acceptCallback = acceptCallback || null;
    this.closeCallback = closeCallback || null;
    this.callbackParams = callbackParams || null;
    this.thisDialog.open();
  }

  // call when prompt need to be hided
  public hide(){
    if(typeof this.thisDialog !== 'undefined' && this.thisDialog.isOpen){
      this.thisDialog.close();
      this.reset();
    }
  }

  private reset() {
    this.acceptBlt = null;
    this.closeBlt = null;
    this.title = null;
    this.HTMLMsg = null;
    this.msg = null;
    this.listener = null;
    this.acceptCallback = null;
    this.callbackParams = null;
  }

  private acceptBlt: string | null = null;
  private title: string | null = null;
  private closeBlt: string | null = null;
  private HTMLMsg: string | null = null;
  private msg: string | null = null;
  private listener: any = null;
  private closeCallback: string | null = null;
  private acceptCallback: string | null = null;
  private callbackParams: any = null;


  private execution: CallBack<void> | null = null;
}
