







































import ErrorPrompt from '@/components/dialogs/ErrorPrompt.vue';
import AddCreditsPrompt from '@/components/prompts/AddCreditsPrompt.vue';
import IframePrompt from '@/components/prompts/IframePrompt.vue';
import Prompt from '@/components/prompts/Prompt.vue';
import NavBar from '@/components/sidebars/NavBar/NavBar.vue';
import SettingBar from '@/components/sidebars/SettingBar/SettingBar.vue';
// Import sidebars
import TopBar from '@/components/sidebars/TopBar.vue';
// Import prompts
import Loading from '@/components/transitions/Loading.vue';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import AddShipmentDataPrompt from './components/prompts/AddShipmentDataPrompt.vue';
import InAppNoticesPrompt from '@/components/prompts/InAppNoticesPrompt.vue';

// import { mixin } from "vue/types/umd";

@Component({
  components: {
    TopBar,
    NavBar,
    SettingBar,
    Loading,
    ErrorPrompt,
    Prompt,
    AddShipmentDataPrompt,
    AddCreditsPrompt,
    IframePrompt,
    InAppNoticesPrompt,
  },
})
export default class App extends Vue implements VuePromptCaller {
  // See vue-property-decorator Ref
  @Ref('generic-prompt') readonly promptComponent!: Prompt;
  @Ref('add-shipment-data-prompt')
  readonly addShipmentDataPromptComponent!: AddShipmentDataPrompt;
  @Ref('add-credits-prompt')
  readonly addCreditsPromptComponent!: AddCreditsPrompt;
  get hasCard() {
    return this.$store.getters['credits/hasCard'];
  }
  @Ref('iframe-prompt') readonly iframePromptComponent!: IframePrompt;
  @Ref('inAppNotices-prompt') readonly inAppNoticePrompt!: InAppNoticesPrompt;

  // Visiter Pattern Implemenation
  // See module.d.ts
  public on(event: 'accept' | 'close', payload?: any, execution?: CallBack<void> | null) {
    if (event === 'accept') {
      if (execution) {
        execution(event, payload);
      }
    }
  }

  // watch vuex showConnectionError, if it changes to true, prompt connection error
  @Watch('showConnectionError')
  onConnectionErrorChanged(val: boolean, newVal: boolean) {
    if (val) {
      (this.$refs['generic-error-prompt'] as ErrorPrompt).show();
      this.$store.commit('hideConnectionError');
    }
  }
  //generic Error Prompt
  @Watch('showErrorDialog')
  onChanged(val: boolean, newVal: boolean) {
    if (val) {
      this.showPrompt(this.$store.getters['dialog/getErrorDialogData']);
      this.$store.commit('dialog/resetErrorDialog');
    }
  }

  // if user login, set showInAppNotices to true => prompt inAppNotices
  @Watch('isLogin')
  onIsLoginChange(val: boolean, oldVal: boolean) {
    if (val && !oldVal) {
      this.$store.commit('inAppNotices/showInAppNotices');
    }
  }

  // if showInAppNotices state = true, prompt inAppNotices
  @Watch('showInAppNotices')
  onShowInAppNotices(val: boolean, oldVal: boolean) {
    if (val) {
      this.fetchAndShowInAppNotice();
    }
  }

  mounted() {
    // remove /public/index.html overlay--app--loading loading page after vue loaded
    if (document.getElementById('overlay--app--loading') as HTMLElement) {
      (document.getElementById('overlay--app--loading') as HTMLElement).remove();
    }
  }

  get isLogin() {
    return this.$store.getters.isLogin;
  }

  get showInAppNotices() {
    return this.$store.getters['inAppNotices/getShowInAppNotices'];
  }

  get text() {
    return {
      connectionIssue: this.$t('app.connectionIssue'),
    };
  }
  get showConnectionError() {
    return this.$store.getters.showConnectionError;
  }
  get showErrorDialog() {
    return this.$store.getters['dialog/showErrorDialog'];
  }

  // Determine the size of the main content container
  get extendBaseTop() {
    return this.$store.getters.showNavBar && !this.$store.getters.showTopBar;
  }
  get extendBaseBot() {
    return this.$store.getters.showTopBar && !this.$store.getters.showNavBar;
  }

  get doubleExtendBase() {
    return !this.$store.getters.showTopBar && !this.$store.getters.showNavBar;
  }

  // freeze all click when loading page is shown
  get freezeClick() {
    return this.$store.getters.showLoading;
  }

  // Call to show generic prompt
  public showPrompt(args: PromptData) {
    this.promptComponent.show(args);
  }

  // Call to show Add Address, Add item prompt in shipmentForm.vue
  public showAddShipmentDataPrompt(args: AddShipmentFormData) {
    this.addShipmentDataPromptComponent.show(args);
  }

  // Call to show Top up credit prompt in ShipmentForm.vue, Credits.vue
  public async showAddCreditsPromptComponentPrompt(args: AddCreditsPromptData) {
    if (this.hasCard) {
      this.addCreditsPromptComponent.show(args);
    } else {
      // if not card is registered redirect to credit page
      this._showRegisterCardPrompt();
    }
  }

  // Call to hide credit Prompt
  public hideAddCreditsPromptComponentPrompt() {
    this.addCreditsPromptComponent.hide();
  }

  private _showRegisterCardPrompt() {
    this.showPrompt({
      title: this.$t('dialogs.message') as string,
      msg: this.$t('dialogs.registerCardPrompt') as string,
      acceptBlt: this.$t('dialogs.okay') as string,
      closeBlt: this.$t('dialogs.cancel') as string,
      listener: this,
      execution: () => {
        // TODO: reset all and move to credit page
        this.$store.commit('resetAll');
        this.$router.push({
          name: 'Credits',
          params: { locale: this.$i18n.locale },
        });
      },
    });
  }

  // Call to show Payment Registration Card Iframe in Credits.ts
  public showPaymentIframe(args: IframePromptData) {
    this.iframePromptComponent.show(args);
  }

  // Call to pass props to inAppNotice components and show it
  private showInAppNoticesPrompt(args: InAppNoticePromptData) {
    this.inAppNoticePrompt.show(args);
  }

  // Call to fetch inAppNotice from server and fire show notices function
  private fetchAndShowInAppNotice = async () => {
    await this.$store.dispatch('inAppNotices/fetchAllInAppNotices');

    const locale = this.$i18n.locale;

    if (this.$store.getters['inAppNotices/getDataList'].length > 0) {
      this.showInAppNoticesPrompt({
        locale: locale,
        acceptBlt: this.$t('general.okay') as string,
        closeBlt: this.$t('general.return') as string,
        dismissCheckBox: this.$t('dialogs.dontShowReminderAgain') as string,

        data: this.$store.getters['inAppNotices/getDataList'],
        checkedList: this.$store.getters['inAppNotices/getCheckedByUser'],
        checkedOneDoNotShowAgain: (id: string, doNotShowAgain: boolean) => {
          this.$store.commit('inAppNotices/checkedOneDoNotShowAgain', {
            id,
            doNotShowAgain,
          });
        },
        checkedOneSeen: (id: string) => {
          this.$store.commit('inAppNotices/checkedOneSeen', id);
        },
        onSubmit: async () => {
          await this.$store.dispatch('inAppNotices/changeUserAllNotices');
          this.$store.commit('inAppNotices/hideInAppNotices');
        },
      });
    }
  };
}
