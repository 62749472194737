



























































































































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import App from "@/App.vue";


// The MDC Components
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCTextArea from "@/components/mdcComponents/textFields/TheMDCTextArea.vue";
import { ShipmentHelpers } from "@/utils/shipmentHelpers.ts";
import Prompt from "@/components/prompts/Prompt.vue";
import { watch } from "fs";

@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCSelectOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCTextArea,
    Prompt
  }
})
export default class RequestLabelForm extends Vue implements VuePromptCaller {  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }

  @Ref("standardDeliveryQuantity") standardDeliveryQuantityComponent!: TheMDCTextFieldOutlined;
  @Ref("sameDayDeliveryQuantity") sameDayDeliveryQuantityComponent!: TheMDCTextFieldOutlined;
  @Ref("deliveryAddress") deliveryAddressComponent!: TheMDCTextFieldOutlined;
  @Ref("labelDeliveryRemarks") labelDeliveryRemarksComponent!: TheMDCTextFieldOutlined;
  @Ref("success-redirect-prompt") readonly requestLabelPromptComponent!: Prompt;
  



  private ROOT = this.$root.$children[0] as App;

  //using variable because select can't set value after dynamically append options.
  //static variable of area menu array need to be set before the area select component is rendered
  private sameDayDeliveryQuantity = "";
  @Watch("sameDayDeliveryQuantity")
  commitSameDayDeliveryQuantityOnChange(newValue: string, oldValue: string){
    this.$store.commit("requestLabel/setSameDayDeliveryQuantity", newValue);
  }
  private standardDeliveryQuantity = "";
  @Watch("standardDeliveryQuantity")
  commitStandardDeliveryQuantityOnChange(newValue: string, oldValue: string){
    this.$store.commit("requestLabel/setStandardDeliveryQuantity", newValue);
  }
  private labelDeliveryRemarks = "";
  @Watch("labelDeliveryRemarks")
  commitLabelDeliveryRemarksOnChange(newValue: string, oldValue: string){
    this.$store.commit("requestLabel/setLabelDeliveryRemarks", newValue);
  }

  beforeCreate() {
    this.$store.commit("hideSettingBar");
    this.$store.commit("hideNavBar");
    this.$store.commit("hideTitle");
  }
  created(){
    this.fillingForm();
  }

  mounted() {
    this.$nextTick(function () {
      this.deliveryAddressComponent.setValue(this.deliveryAddress?this.deliveryAddress.fullAddress:"");
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });  
  }
  // get getSameDayDeliveryQuantity(){
  //   return this.$store.getters["requestLabel/getSameDayDeliveryQuantity"];
  // }

  // get standardDeliveryQuantity(){
  //   return this.$store.getters["requestLabel/getStandardDeliveryQuantity"];
  // }

  // get labelDeliveryRemarks(){
  //   return this.$store.getters["requestLabel/getLabelDeliveryRemarks"];
  // }


  get deliveryAddress(){
    return this.$store.getters["requestLabel/getDeliveryAddress"];
  }
  private fillingForm(){
    this.sameDayDeliveryQuantity = (this.$store.getters["requestLabel/getSameDayDeliveryQuantity"]);
    // this.sameDayDeliveryQuantityComponent.setValue(String(this.sameDayDeliveryQuantity));

    this.standardDeliveryQuantity = (this.$store.getters["requestLabel/getStandardDeliveryQuantity"]);
  //  this.standardDeliveryQuantityComponent.setValue(String(this.standardDeliveryQuantity));

    this.labelDeliveryRemarks = this.$store.getters["requestLabel/getLabelDeliveryRemarks"];
    // this.standardDeliveryQuantityComponent.setValue(String(this.standardDeliveryQuantity));

  }


  private async backTo(){
    this.$store.commit("requestLabel/resetRequestLabel");
    await this.$router.push({
      name: "Shipment"
    })
  }
  get canProceed(){
      return Number(this.standardDeliveryQuantity) + Number(this.sameDayDeliveryQuantity) > 0 && this.deliveryAddress && this.deliveryAddress.fullAddress
  }

  private async submitRequestForm(){
    this.$store.commit("showLoading");
    this.$store.commit("requestLabel/setStandardDeliveryQuantity",this.standardDeliveryQuantity);
    this.$store.commit("requestLabel/setSameDayDeliveryQuantity",this.sameDayDeliveryQuantity);
    // this.$store.commit("requestLabel/setDeliveryAddress",this.deliveryAddress);
    this.$store.commit("requestLabel/setLabelDeliveryRemarks",this.labelDeliveryRemarks);
    const result = await this.$store.dispatch("requestLabel/requestLabel");
    this.$store.commit("hideLoading");
    if(result === true){
      //success
      this.requestLabelPromptComponent.show({
        closeBlt: this.$t("dialogs.submitLabelRequestPrompt.okay") as string, 
        title: this.$t("dialogs.submitLabelRequestPrompt.successfullySubmitted") as string, 
        msg: this.$t("dialogs.submitLabelRequestPrompt.requestIsSubmmited") as string
      })
    }else{
      this.$store.commit("showConnectionError");
    }
  }

  private async successSubmitRedirect(){
    await this.$router.push({name: "Shipment"});
  }

  private async buildingSearch() {
    this.$store.commit("showLoading");
    await this.$router.push({
      name: "ExpressOrderAddress",
      params: {
        addressMutation: "requestLabel/setDeliveryAddress",
        savedAddressGetter: "requestLabel/getDeliveryAddress",
        addressId: "",
        title: this.$t("requestLabelForm.labelDeliveryAddress") as string,
        completingRedirectName: this.$route.name as string
      },
    })
    .catch((e)=>{
      this.$store.commit("dialog/showErrorDialog", {title: this.$t("dialogs.routerErrorPrompt.title"),msg: this.$t("dialogs.routerErrorPrompt.message"), closeBlt: this.$t("dialog.close")});
    });
  }
  
  
  
}

