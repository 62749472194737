import store from "@/store";
import { AxiosError, AxiosResponse } from "axios";
import * as constants from "@/assets/constant"

// HTTP Status
export const BAD_REQUEST = 400;
export const UNAUTHORIZED_ERROR = 401;
export const SERVER_NOT_FOUND = 404;

// Errors
// msg
export const NETWORK_ERROR = "Network Error";
export const INSUFFICIENT_BALANCE = "EASYSHIP_INSUFFICIENT_BALANCE";
export const USER_COMPANY_INSUFFICIENT_BALANCE =
  "USER_COMPANY_INSUFFICIENT_BALANCE";
export const USER_INPUT_ERROR = "USER_INPUT_ERROR";
export const TRACKING_NUMBER_ALREADY_IN_USE = "TRACKING_NUMBER_ALREADY_IN_USE";
// code
export const TIMEOUT_ERROR_CODE = "ECONNABORTED";

function _hasGeneralError(error: AxiosError): boolean {
  // axios timeout Error
  if (error.code === TIMEOUT_ERROR_CODE) {
    return true;
  }

  // network error
  if (error.message === NETWORK_ERROR) {
    return true;
  }

  // else
  return false;
}

export function processServerErrors(error: AxiosError, line: any): boolean | string | null {

  // check generic error
  if (_hasGeneralError(error)) {
    store.commit("showConnectionError");
    return null;
  }
  
  // Customized error for payment error from API
  try{
    if(error.response!.data &&
      !error.response!.data.success && 
      error.response!.data.error.error_type == constants.backendErrorType.paymentError) {
        if(error.response!.data.error.message == constants.backendErrorType.paymentCustomerErrorMessage) {
          return "payCustomerError";
        }
      return "paymentError";
    }
  } catch (error) {
    console.log(error);
  }


  // backend error
  if(!error.response){
    store.commit("showConnectionError");
    return false;
  }else if (error.response!.status == BAD_REQUEST) {
    return false;
  } else if (error.response!.status == UNAUTHORIZED_ERROR) {
    return false;
  } else if (error.response!.status == SERVER_NOT_FOUND) {
    store.commit("showConnectionError");
    return null;
  } else {
    store.commit("showConnectionError");
    return null;
  }
}
