var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "768px" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "padding-container" },
                [
                  _c(
                    "v-window",
                    {
                      staticClass: "notices-container",
                      attrs: {
                        "active-class": "list-item",
                        touch: {
                          left: _vm.onSwipeLeft,
                          right: _vm.onSwipeRight
                        }
                      },
                      model: {
                        value: _vm.currNotiIndex,
                        callback: function($$v) {
                          _vm.currNotiIndex = $$v
                        },
                        expression: "currNotiIndex"
                      }
                    },
                    _vm._l(_vm.data, function(notice, index) {
                      return _c(
                        "v-window-item",
                        {
                          key: "notices-" + index,
                          attrs: { transition: _vm.tran() }
                        },
                        [
                          _c("div", { staticClass: "notice-title" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.locale === "cant"
                                    ? notice.titleChi
                                    : notice.titleEn
                                )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "notice-message" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.locale === "cant"
                                    ? notice.messageChi
                                    : notice.messageEn
                                )
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    1
                  ),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showDotIndicator(),
                        expression: "!showDotIndicator()"
                      }
                    ],
                    staticClass: "dummy-margin"
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDotIndicator(),
                          expression: "showDotIndicator()"
                        }
                      ],
                      staticClass: "dot-indicator-container"
                    },
                    _vm._l(_vm.data, function(e, i) {
                      return _c("div", {
                        key: "notices-index-" + i,
                        class:
                          _vm.currNotiIndex === i
                            ? "dot-indicator-selected"
                            : "dot-indicator"
                      })
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCheckBox(
                            _vm.data[_vm.currNotiIndex].allowDoNotShowAgain
                          ),
                          expression:
                            "showCheckBox(data[currNotiIndex].allowDoNotShowAgain)"
                        }
                      ],
                      staticClass: "dismiss-checkbox-container"
                    },
                    [
                      _c("input", {
                        staticClass: "dismiss-checkbox",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: _vm.checked(
                            _vm.data[_vm.currNotiIndex].inAppNoticeUserId
                          )
                        },
                        on: {
                          input: function($event) {
                            return _vm.onClickCheckbox(
                              _vm.data[_vm.currNotiIndex].inAppNoticeUserId,
                              $event.target.checked
                            )
                          }
                        }
                      }),
                      _c("div", { staticClass: "dismiss-message" }, [
                        _vm._v(_vm._s(_vm.dismissCheckBox))
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "button-group-container" }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPrevButton(),
                            expression: "showPrevButton()"
                          }
                        ],
                        staticClass: "prev-next-button previous-button",
                        attrs: { disabled: _vm.isPrevButtonDisabled() },
                        on: {
                          click: function($event) {
                            return _vm.prevCurrNotiIndex()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.closeBlt) +
                            "\n                "
                        )
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "prev-next-button next-button",
                        attrs: { disabled: _vm.isNextButtonDisabled() },
                        on: {
                          click: function($event) {
                            return _vm.onClickNextButton(
                              _vm.data[_vm.currNotiIndex].inAppNoticeUserId
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.acceptBlt) +
                            "\n                "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }