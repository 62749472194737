var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "credits__container" }, [
    _c("div", { staticClass: "credits__container__inner grid-container" }, [
      _c("div", { staticClass: "title__container inner-grid-list-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.text.title))]),
        _c("div", { staticClass: "current-credit__container" }, [
          _c(
            "div",
            {
              staticClass:
                "current-credit__inner inner-grid-leftright-container"
            },
            [
              _c("span", { staticClass: "credit-title left-item" }, [
                _vm._v(_vm._s(_vm.text.creditTitle))
              ]),
              _c("span", { staticClass: "credit-value right-item" }, [
                _vm._v(
                  _vm._s(_vm.text.dollarSign) + " " + _vm._s(_vm.accountBalance)
                )
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "card__container inner-grid-list-container" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.text.cardTitle))
        ]),
        _vm.isTesting
          ? _c("div", { staticClass: "form-label sub-title" }, [
              _vm._v(
                "*" +
                  _vm._s(_vm.$t("credits.credits.testingCreditAPI")) +
                  "\n      "
              )
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "add-card-blt__container" },
          [
            _c("TheMDCButtonOutlined", {
              ref: "add-card-blt",
              staticStyle: { "min-height": "60px" },
              attrs: {
                reference: "add-card-blt",
                label: _vm.text.addCardLabel,
                startIcon: "add",
                width: "100%",
                event: "registration",
                fontSize: "min(5vw, 1.5rem)"
              },
              on: { registration: _vm.toRegistration }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-info inner-grid-list-container" },
          _vm._l(_vm.cards, function(card) {
            return _c("CardDisplay", {
              key: card.id,
              attrs: { card: card, showButton: true }
            })
          }),
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "button__container inner-grid-list-container" },
        [
          _c(
            "a",
            {
              staticClass: "link",
              attrs: { href: "" },
              on: { click: _vm.toBalanceLog }
            },
            [_vm._v(_vm._s(_vm.text.checkBalanceLog))]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }