


































































































import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import { MDCDialog } from "@material/dialog";
import TheMDCDatePicker from "@/components/mdcComponents/pickers/TheMDCDatePicker.vue";
import ErrorPrompt from "../../dialogs/ErrorPrompt.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";

@Component({
  components: {
    TheMDCDatePicker,
    ErrorPrompt,
    TheMDCSelectOutlined
  }
})
export default class ExportReportDialog extends Vue {
  @Ref("select-report") readonly selectReportComponent!: TheMDCSelectOutlined;
  private dialog!: MDCDialog;
  private fromDate: string | null = null;
  private toDate: string | null = null;

  mounted() {
    this.dialog = new MDCDialog(
      document.querySelector("#export-report-prompt") as HTMLElement
    );

    this.fillDefaultDates();
  }
  private fillDefaultDates() {
    this.fromDate = (this.$refs["fromDate"] as TheMDCDatePicker).getValue();
    this.toDate = (this.$refs["toDate"] as TheMDCDatePicker).getValue();
  }
  get text() {
    return {
      title: this.$t("sidebars.settingBar.exportReportDialog.title"),
      selectReport: this.$t(
        "sidebars.settingBar.exportReportDialog.selectReport"
      ),
      okay: this.$t("dialogs.confirmPrompt.okay"),
      notOkay: this.$t("dialogs.confirmPrompt.notOkay"),
      hint: this.$t("sidebars.settingBar.exportReportDialog.hint"),
      fromDate: this.$t("sidebars.settingBar.exportReportDialog.fromDate"),
      toDate: this.$t("sidebars.settingBar.exportReportDialog.toDate"),
      shipmentReport: this.$t(
        "sidebars.settingBar.exportReportDialog.reportOption.shipmentReport"
      ),
      balanceReport: this.$t(
        "sidebars.settingBar.exportReportDialog.reportOption.balanceReport"
      )
    };
  }

  get reportList(): MenuItem[] {
    return [
      {
        key: "shipments",
        value: "shipments",
        expression: this.text.shipmentReport
      },
      {
        key: "balanceLog",
        value: "logs",
        expression: this.text.balanceReport
      }
    ];
  }
  // private isShipments() {
  //   if (this.selectReportComponent) {
  //     return this.selectReportComponent.getSelectedValue() === "shipments";
  //   }
  //   return null;
  // }
  private isShipments = true;
  private changeSelectValue(newValue: "shipments" | "logs") {
    this.isShipments = newValue === "shipments";
  }

  changeFromDate(dateValue: string) {
    if (dateValue) {
      this.fromDate = dateValue;
    }
  }

  changeToDate(dateValue: string) {
    if (dateValue) {
      this.toDate = dateValue;
    }
  }

  get canProceed() {
    if (this.fromDate) {
      if (this.toDate) {
        return true;
      }
    }
    return false;
  }

  private exportReport() {
    this.$store.commit("hideSettingBar");
    this.$store.dispatch("exportReport/generatereport", {
      fromDate: (this.$refs["fromDate"] as TheMDCDatePicker).getValue(),
      toDate: (this.$refs["toDate"] as TheMDCDatePicker).getValue(),
      reportType: this.selectReportComponent.getSelectedValue()
    });
  }
  show() {
    this.dialog.open();
  }
}
