import { AxiosInstance } from "axios";
import { Module } from "vuex";
import { expressOrderItemOptionModule } from "./expressOrderItemOption";
import { otherCourierItemOptionModule } from "./otherCourierItemOption";



import * as serverResponseHelpers from "@/utils/serverResponseHelpers";

export function itemOptionModule(axiosInstance: AxiosInstance) {
  const historialInput: Module<any, any> = {
    namespaced: true,
    modules: {
      expressOrder: expressOrderItemOptionModule(axiosInstance),
      otherCourier: otherCourierItemOptionModule(axiosInstance)
    },
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
  }
  return historialInput;
}
