







































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { MDCTextField } from "@material/textfield";
import App from "@/App.vue";


@Component
export default class TheMDCDatePicker extends Vue implements VuePromptCaller {
  // Visitor pattern: See module.d.ts
  public on(event: string, data?: any, execution?: CallBack<void> | null) {
    if (event === "accept") {
      if (execution) {
        execution(event, data);
      }
    }
  }
  private ROOT = this.$root.$children[0] as App;
  @Prop() reference!: string;
  @Prop() label!: string;
  @Prop() isRequired?: boolean;
  @Prop() event?: string;
  @Prop() min?: string | null;
  @Prop() max?: string | null;
  @Prop() helperText?: string | null;
  @Prop() errorTitle?: string | null;
  @Prop() errorMessage?: string | null;
  private datePicker!: MDCTextField;
  private value = new Date().toISOString().slice(0, 10);
  @Watch("value")
  onValueChange(newVal: any, oldVal: any) {
    this.emitToParent();
    // From desktop safari broswer, date input would not work and will fallback to text input
    // if the value is not in complete date format, emit null to parent and skip validation
    // else(value is in date format), check if value matches date range
    // if(new RegExp(/^\d{4}-\d{2}-\d{2}$/).test(newVal)){
    //   if(!isNaN(Number(Date.parse(newVal))) && this.validateDate(newVal)){
    //     this.emitToParent();
    //   }else{
    //     this.value = this.min || new Date().toISOString().slice(0, 10);
    //     this.errorPormpt();
    //   }
    // }else{
    //   this.emitNullToParent();
    // }
  }

  public mounted() {
    this.datePicker = new MDCTextField(
      this.$refs[this.reference as string] as HTMLElement
    );

    this.value = this.min || new Date().toISOString().slice(0, 10) as string;
  }

  getValue() {
    return this.datePicker.value;
  }

  setValue(v: string | null) {
    try{
      if(v == null || typeof v === 'undefined' || isNaN(Date.parse(v))){
        console.log("TheMDCDatePicker setEmptyValue");
      }else{
        // console.log("datePicker setValue", v);
       this.value = v;
      }
    }catch(e){
      console.log(e);
    }
  }
  /* 
    validate date function
    if there is min or max date
    if no max and min, always return true 
  */
  validateDate(dateString: string){
    if(!isNaN(Date.parse(dateString))){
      const inputDate = new Date(dateString);
      if((this.min && new Date(this.min) > inputDate)
        || (this.max && new Date(this.max) < inputDate)
      ){
        return false;
      }
    return true;
    }else{
      return false;
    }
  }

  errorPormpt(){
    this.ROOT.showPrompt({
      title: this.errorTitle || this.$t('general.datePickerError.title')as string,
      msg: this.errorMessage || this.$t('general.datePickerError.defaultMessage')as string,
      closeBlt: this.$t("dialogs.close") as string
    });
  }

  emitToParent() {
    if (this.event) {
      this.$emit(this.event, this.value);
    }
  }
  emitNullToParent(){
    if (this.event) {
      this.$emit(this.event, null);
    }
  }
}
