





















import { Component, Vue, Prop, Ref } from "vue-property-decorator";

@Component
export default class BalanceLog extends Vue {
  @Ref("balance-log__table") balanceLogElement!: HTMLElement;

  get text() {
    return {
      title:this.$t("credits.balanceLog.title"),
      date: this.$t("credits.balanceLog.date"),
      event: this.$t("credits.balanceLog.event"),
      amount: this.$t("credits.balanceLog.amount")
    };
  }
  public async mounted() {
    this.$store.commit("showTopBar");
    this.$store.commit("showNavBar");
    await this.$store.dispatch("credits/getCompanyLog");
    this.$nextTick(function () {;
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }
  get logs() {
    return this.$store.getters["credits/getCompanyLog"];
  }
  private formate_time(time: string) {
    const date = time.split("T")[0];
    const month = date.split("-")[1];
    const day = date.split("-")[2];
    const text_month: any = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec"
    };
    return day + " " + text_month[month];
  }

  private formate_event(event: string){
    return event.split("_").join(" ")
  }
}
