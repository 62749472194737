import { AxiosInstance } from "axios";
import { Module } from "vuex";


import * as serverResponseHelpers from "@/utils/serverResponseHelpers";

export function otherCourierHistorialInputModule(axiosInstance: AxiosInstance) {
  const otherCourierHistorialInput: Module<any, any> = {
    namespaced: true,
    state: {
      userSavedSenderAddresses: [] as Address[], // Sender Addresses saved in DB
      userSavedReceiverAddresses: [] as Address[], // Receiver Addresses saved in DB
      userSavedItems: [] as Item[], // Items saved in DB
    },
    getters: {
      getUserSavedSenderAddresses(state: any) {
        const shipmentAddresses = state.userSavedSenderAddresses.slice();
        return shipmentAddresses;
      },
      getUserSavedReceiverAddresses(state: any) {
        return state.userSavedReceiverAddresses.slice();
      },
      /**
       * get user saved address by id if exist, return null otherwise
       */
      getUserSavedAddressById(
        state: any
      ): (addressId: string) => Address | null {
        return (addressId: string) => {
          const shipmentSenderAddress: Address = state.userSavedSenderAddresses.find(
            (address: Address) => address.id === addressId
          );
          const shipmentReceiverAddress: Address = state.userSavedReceiverAddresses.find(
            (address: Address) => address.id === addressId
          );
          if (shipmentSenderAddress) {
            return shipmentSenderAddress;
          } else if (shipmentReceiverAddress) {
            return shipmentReceiverAddress;
          } else {
            return null;
          }
        };
      },
      getUserSavedItems(state: any): Item[] {
        return state.userSavedItems || [];
      },

      /**
       *  get user saved item by id if exist, return null otherwise
       * Using index as Saved Item Id, Be awared always save item object not the index coz index will change when savedItem is fetch from backend
       */
      getUserSavedItemById(state: any): (itemId: number) => Item | null {
        return (itemId: number) => {
          return (
            state.userSavedItems[itemId]
            || null
          );
        };
      }
    },
    mutations: {
      /**
       * Reset vuex userSavedSenderAddresses and saved the new data
       */
      insertUserSavedSenderAddresses(
        state: any,
        userSavedSenderAddresses: Address[]
      ) {
        // reset && insert
        state.userSavedSenderAddresses = [];
        if(userSavedSenderAddresses){
          userSavedSenderAddresses.forEach(address => {
            state.userSavedSenderAddresses.push({
              id: address.id,
              addressLine1: address.addressLine1,
              addressLine2: address.addressLine2,
              countryCodeAlpha2: address.countryCodeAlpha2,
              postalCode: address.postalCode,
              state: address.state,
              city: address.city,
              contactName: address.contactName,
              contactPhone: address.contactPhone,
              contactEmail: address.contactEmail,
              companyName: address.companyName,
              _name: (address as any).name
            });
          });
        }
       
      },
      /**
       * Reset vuex userSavedReceiverAddresses and saved the new data
       */
      insertUserSavedReceiverAddresses(
        state: any,
        userSavedReceiverAddresses: Address[]
      ) {
        // reset && insert
        state.userSavedReceiverAddresses = [];
        if(userSavedReceiverAddresses){
          userSavedReceiverAddresses.forEach(address => {
            state.userSavedReceiverAddresses.push({
              id: address.id,
  
              addressLine1: address.addressLine1,
              addressLine2: address.addressLine2,
  
              countryCodeAlpha2: address.countryCodeAlpha2,
              postalCode: address.postalCode,
              state: address.state,
              city: address.city,
              contactName: address.contactName,
              contactPhone: address.contactPhone,
              contactEmail: address.contactEmail,
              companyName: address.companyName,
  
              _name: (address as any).name
            });
          });
        }
        
      },
      _resetUserSavedItems(state) {
        state.userSavedItems = [];
      },
      _addUserSavedItems(state, item: Item) {
        state.userSavedItems.push(item);
      }
    },
    actions: {
      /**
       * Get Saved Sender Address, Receiver Address, Items
       */
       async fetchCompanySavedInfo(store: any) {
        return true;
      },
      async fetchSavedOrigin(store: any) {
        // get sender saved address
        const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "address/easyship-order/" + "origin"
        })
        .then(response => {
          //console.log(response);
          if (response.data.success) {
            store.commit(
              "insertUserSavedSenderAddresses",
              response.data.data
            );
            return true;
          }
          return false;
        })
        .catch(error => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(error, console.trace());
        });
        return serverReturn;

      },
      async fetchSavedDestination(store: any){
        // get receiver saved address
        const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url: "address/easyship-order/" + "destination"
          })
          .then(response => {
            //console.log(response);
            if (response.data.success) {
              store.commit(
                "insertUserSavedReceiverAddresses",
                response.data.data
              );
              return true;
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
          return serverReturn;
      },
      async fetchSavedItems(store: any) {
       // get items
       const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "item/easyship-order"
        })
        .then(response => {
          //console.log(response);
          if (response.data.success) {
            store.dispatch("insertSavedItems", response.data.data);
            return true;
          }
          return false;
        })
        .catch(error => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(error, console.trace());
        });
        return serverReturn;
      },
      insertSavedItems(store: any, userSavedItems: Item[]) {
        // reset and insert
        store.commit("_resetUserSavedItems");

        if(userSavedItems){
          userSavedItems.forEach((item: any) => {
            store.commit("_addUserSavedItems", {
  
              description: item.description,
              sku: item.sku,
              width: item.width,
              length: item.length,
              height: item.height,
              actualWeight: item.weight,
              category: item.category,
              declaredCurrency: item.currency,
              declaredCustomsValue: item.declaredCustomsValue,
              quantity: item.quantity
            });
          });
        }
        
      }
    }
  };

  return otherCourierHistorialInput;
}
