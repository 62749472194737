import i18n from "@/i18n";

//* This also works too
// export const Alpha2ToName: Constant = i18n.t(
//     "constants.alpha2ToName"
//   ) as Constant;

export const Alpha2ToName: Constant = {
  AF: i18n.t("constants.alpha2ToName.AF"),
  AX: i18n.t("constants.alpha2ToName.AX"),
  AL: i18n.t("constants.alpha2ToName.AL"),
  DZ: i18n.t("constants.alpha2ToName.DZ"),
  AS: i18n.t("constants.alpha2ToName.AS"),
  AD: i18n.t("constants.alpha2ToName.AD"),
  AO: i18n.t("constants.alpha2ToName.AO"),
  AI: i18n.t("constants.alpha2ToName.AI"),
  AQ: i18n.t("constants.alpha2ToName.AQ"),
  AG: i18n.t("constants.alpha2ToName.AG"),
  AR: i18n.t("constants.alpha2ToName.AR"),
  AM: i18n.t("constants.alpha2ToName.AM"),
  AW: i18n.t("constants.alpha2ToName.AW"),
  AU: i18n.t("constants.alpha2ToName.AU"),
  AT: i18n.t("constants.alpha2ToName.AT"),
  AZ: i18n.t("constants.alpha2ToName.AZ"),
  BS: i18n.t("constants.alpha2ToName.BS"),
  BH: i18n.t("constants.alpha2ToName.BH"),
  BD: i18n.t("constants.alpha2ToName.BD"),
  BB: i18n.t("constants.alpha2ToName.BB"),
  BY: i18n.t("constants.alpha2ToName.BY"),
  BE: i18n.t("constants.alpha2ToName.BE"),
  BZ: i18n.t("constants.alpha2ToName.BZ"),
  BJ: i18n.t("constants.alpha2ToName.BJ"),
  BM: i18n.t("constants.alpha2ToName.BM"),
  BT: i18n.t("constants.alpha2ToName.BT"),
  BO: i18n.t("constants.alpha2ToName.BO"),
  BA: i18n.t("constants.alpha2ToName.BA"),
  BW: i18n.t("constants.alpha2ToName.BW"),
  BV: i18n.t("constants.alpha2ToName.BV"),
  BR: i18n.t("constants.alpha2ToName.BR"),
  IO: i18n.t("constants.alpha2ToName.IO"),
  BN: i18n.t("constants.alpha2ToName.BN"),
  BG: i18n.t("constants.alpha2ToName.BG"),
  BF: i18n.t("constants.alpha2ToName.BF"),
  BI: i18n.t("constants.alpha2ToName.BI"),
  KH: i18n.t("constants.alpha2ToName.KH"),
  CM: i18n.t("constants.alpha2ToName.CM"),
  CA: i18n.t("constants.alpha2ToName.CA"),
  CV: i18n.t("constants.alpha2ToName.CV"),
  KY: i18n.t("constants.alpha2ToName.KY"),
  CF: i18n.t("constants.alpha2ToName.CF"),
  TD: i18n.t("constants.alpha2ToName.TD"),
  CL: i18n.t("constants.alpha2ToName.CL"),
  CN: i18n.t("constants.alpha2ToName.CN"),
  CX: i18n.t("constants.alpha2ToName.CX"),
  CC: i18n.t("constants.alpha2ToName.CC"),
  CO: i18n.t("constants.alpha2ToName.CO"),
  KM: i18n.t("constants.alpha2ToName.KM"),
  CG: i18n.t("constants.alpha2ToName.CG"),
  CD: i18n.t("constants.alpha2ToName.CD"),
  CK: i18n.t("constants.alpha2ToName.CK"),
  CR: i18n.t("constants.alpha2ToName.CR"),
  CI: i18n.t("constants.alpha2ToName.CI"),
  HR: i18n.t("constants.alpha2ToName.HR"),
  CU: i18n.t("constants.alpha2ToName.CU"),
  CY: i18n.t("constants.alpha2ToName.CY"),
  CZ: i18n.t("constants.alpha2ToName.CZ"),
  DK: i18n.t("constants.alpha2ToName.DK"),
  DJ: i18n.t("constants.alpha2ToName.DJ"),
  DM: i18n.t("constants.alpha2ToName.DM"),
  DO: i18n.t("constants.alpha2ToName.DO"),
  EC: i18n.t("constants.alpha2ToName.EC"),
  EG: i18n.t("constants.alpha2ToName.EG"),
  SV: i18n.t("constants.alpha2ToName.SV"),
  GQ: i18n.t("constants.alpha2ToName.GQ"),
  ER: i18n.t("constants.alpha2ToName.ER"),
  EE: i18n.t("constants.alpha2ToName.EE"),
  ET: i18n.t("constants.alpha2ToName.ET"),
  FK: i18n.t("constants.alpha2ToName.FK"),
  FO: i18n.t("constants.alpha2ToName.FO"),
  FJ: i18n.t("constants.alpha2ToName.FJ"),
  FI: i18n.t("constants.alpha2ToName.FI"),
  FR: i18n.t("constants.alpha2ToName.FR"),
  GF: i18n.t("constants.alpha2ToName.GF"),
  PF: i18n.t("constants.alpha2ToName.PF"),
  TF: i18n.t("constants.alpha2ToName.TF"),
  GA: i18n.t("constants.alpha2ToName.GA"),
  GM: i18n.t("constants.alpha2ToName.GM"),
  GE: i18n.t("constants.alpha2ToName.GE"),
  DE: i18n.t("constants.alpha2ToName.DE"),
  GH: i18n.t("constants.alpha2ToName.GH"),
  GI: i18n.t("constants.alpha2ToName.GI"),
  GR: i18n.t("constants.alpha2ToName.GR"),
  GL: i18n.t("constants.alpha2ToName.GL"),
  GD: i18n.t("constants.alpha2ToName.GD"),
  GP: i18n.t("constants.alpha2ToName.GP"),
  GU: i18n.t("constants.alpha2ToName.GU"),
  GT: i18n.t("constants.alpha2ToName.GT"),
  GG: i18n.t("constants.alpha2ToName.GG"),
  GN: i18n.t("constants.alpha2ToName.GN"),
  GW: i18n.t("constants.alpha2ToName.GW"),
  GY: i18n.t("constants.alpha2ToName.GY"),
  HT: i18n.t("constants.alpha2ToName.HT"),
  HM: i18n.t("constants.alpha2ToName.HM"),
  VA: i18n.t("constants.alpha2ToName.VA"),
  HN: i18n.t("constants.alpha2ToName.HN"),
  HK: i18n.t("constants.alpha2ToName.HK"),
  HU: i18n.t("constants.alpha2ToName.HU"),
  IS: i18n.t("constants.alpha2ToName.IS"),
  IN: i18n.t("constants.alpha2ToName.IN"),
  ID: i18n.t("constants.alpha2ToName.ID"),
  IR: i18n.t("constants.alpha2ToName.IR"),
  IQ: i18n.t("constants.alpha2ToName.IQ"),
  IE: i18n.t("constants.alpha2ToName.IE"),
  IM: i18n.t("constants.alpha2ToName.IM"),
  IL: i18n.t("constants.alpha2ToName.IL"),
  IT: i18n.t("constants.alpha2ToName.IT"),
  JM: i18n.t("constants.alpha2ToName.JM"),
  JP: i18n.t("constants.alpha2ToName.JP"),
  JE: i18n.t("constants.alpha2ToName.JE"),
  JO: i18n.t("constants.alpha2ToName.JO"),
  KZ: i18n.t("constants.alpha2ToName.KZ"),
  KE: i18n.t("constants.alpha2ToName.KE"),
  KI: i18n.t("constants.alpha2ToName.KI"),
  KR: i18n.t("constants.alpha2ToName.KR"),
  KW: i18n.t("constants.alpha2ToName.KW"),
  KG: i18n.t("constants.alpha2ToName.KG"),
  LA: i18n.t("constants.alpha2ToName.LA"),
  LV: i18n.t("constants.alpha2ToName.LV"),
  LB: i18n.t("constants.alpha2ToName.LB"),
  LS: i18n.t("constants.alpha2ToName.LS"),
  LR: i18n.t("constants.alpha2ToName.LR"),
  LY: i18n.t("constants.alpha2ToName.LY"),
  LI: i18n.t("constants.alpha2ToName.LI"),
  LT: i18n.t("constants.alpha2ToName.LT"),
  LU: i18n.t("constants.alpha2ToName.LU"),
  MO: i18n.t("constants.alpha2ToName.MO"),
  MK: i18n.t("constants.alpha2ToName.MK"),
  MG: i18n.t("constants.alpha2ToName.MG"),
  MW: i18n.t("constants.alpha2ToName.MW"),
  MY: i18n.t("constants.alpha2ToName.MY"),
  MV: i18n.t("constants.alpha2ToName.MV"),
  ML: i18n.t("constants.alpha2ToName.ML"),
  MT: i18n.t("constants.alpha2ToName.MT"),
  MH: i18n.t("constants.alpha2ToName.MH"),
  MQ: i18n.t("constants.alpha2ToName.MQ"),
  MR: i18n.t("constants.alpha2ToName.MR"),
  MU: i18n.t("constants.alpha2ToName.MU"),
  YT: i18n.t("constants.alpha2ToName.YT"),
  MX: i18n.t("constants.alpha2ToName.MX"),
  FM: i18n.t("constants.alpha2ToName.FM"),
  MD: i18n.t("constants.alpha2ToName.MD"),
  MC: i18n.t("constants.alpha2ToName.MC"),
  MN: i18n.t("constants.alpha2ToName.MN"),
  ME: i18n.t("constants.alpha2ToName.ME"),
  MS: i18n.t("constants.alpha2ToName.MS"),
  MA: i18n.t("constants.alpha2ToName.MA"),
  MZ: i18n.t("constants.alpha2ToName.MZ"),
  MM: i18n.t("constants.alpha2ToName.MM"),
  NA: i18n.t("constants.alpha2ToName.NA"),
  NR: i18n.t("constants.alpha2ToName.NR"),
  NP: i18n.t("constants.alpha2ToName.NP"),
  NL: i18n.t("constants.alpha2ToName.NL"),
  AN: i18n.t("constants.alpha2ToName.AN"),
  NC: i18n.t("constants.alpha2ToName.NC"),
  NZ: i18n.t("constants.alpha2ToName.NZ"),
  NI: i18n.t("constants.alpha2ToName.NI"),
  NE: i18n.t("constants.alpha2ToName.NE"),
  NG: i18n.t("constants.alpha2ToName.NG"),
  NU: i18n.t("constants.alpha2ToName.NU"),
  NF: i18n.t("constants.alpha2ToName.NF"),
  MP: i18n.t("constants.alpha2ToName.MP"),
  NO: i18n.t("constants.alpha2ToName.NO"),
  OM: i18n.t("constants.alpha2ToName.OM"),
  PK: i18n.t("constants.alpha2ToName.PK"),
  PW: i18n.t("constants.alpha2ToName.PW"),
  PS: i18n.t("constants.alpha2ToName.PS"),
  PA: i18n.t("constants.alpha2ToName.PA"),
  PG: i18n.t("constants.alpha2ToName.PG"),
  PY: i18n.t("constants.alpha2ToName.PY"),
  PE: i18n.t("constants.alpha2ToName.PE"),
  PH: i18n.t("constants.alpha2ToName.PH"),
  PN: i18n.t("constants.alpha2ToName.PN"),
  PL: i18n.t("constants.alpha2ToName.PL"),
  PT: i18n.t("constants.alpha2ToName.PT"),
  PR: i18n.t("constants.alpha2ToName.PR"),
  QA: i18n.t("constants.alpha2ToName.QA"),
  RE: i18n.t("constants.alpha2ToName.RE"),
  RO: i18n.t("constants.alpha2ToName.RO"),
  RU: i18n.t("constants.alpha2ToName.RU"),
  RW: i18n.t("constants.alpha2ToName.RW"),
  BL: i18n.t("constants.alpha2ToName.BL"),
  SH: i18n.t("constants.alpha2ToName.SH"),
  KN: i18n.t("constants.alpha2ToName.KN"),
  LC: i18n.t("constants.alpha2ToName.LC"),
  MF: i18n.t("constants.alpha2ToName.MF"),
  PM: i18n.t("constants.alpha2ToName.PM"),
  VC: i18n.t("constants.alpha2ToName.VC"),
  WS: i18n.t("constants.alpha2ToName.WS"),
  SM: i18n.t("constants.alpha2ToName.SM"),
  ST: i18n.t("constants.alpha2ToName.ST"),
  SA: i18n.t("constants.alpha2ToName.SA"),
  SN: i18n.t("constants.alpha2ToName.SN"),
  RS: i18n.t("constants.alpha2ToName.RS"),
  SC: i18n.t("constants.alpha2ToName.SC"),
  SL: i18n.t("constants.alpha2ToName.SL"),
  SG: i18n.t("constants.alpha2ToName.SG"),
  SK: i18n.t("constants.alpha2ToName.SK"),
  SI: i18n.t("constants.alpha2ToName.SI"),
  SB: i18n.t("constants.alpha2ToName.SB"),
  SO: i18n.t("constants.alpha2ToName.SO"),
  ZA: i18n.t("constants.alpha2ToName.ZA"),
  GS: i18n.t("constants.alpha2ToName.GS"),
  ES: i18n.t("constants.alpha2ToName.ES"),
  LK: i18n.t("constants.alpha2ToName.LK"),
  SD: i18n.t("constants.alpha2ToName.SD"),
  SR: i18n.t("constants.alpha2ToName.SR"),
  SJ: i18n.t("constants.alpha2ToName.SJ"),
  SZ: i18n.t("constants.alpha2ToName.SZ"),
  SE: i18n.t("constants.alpha2ToName.SE"),
  CH: i18n.t("constants.alpha2ToName.CH"),
  SY: i18n.t("constants.alpha2ToName.SY"),
  TW: i18n.t("constants.alpha2ToName.TW"),
  TJ: i18n.t("constants.alpha2ToName.TJ"),
  TZ: i18n.t("constants.alpha2ToName.TZ"),
  TH: i18n.t("constants.alpha2ToName.TH"),
  TL: i18n.t("constants.alpha2ToName.TL"),
  TG: i18n.t("constants.alpha2ToName.TG"),
  TK: i18n.t("constants.alpha2ToName.TK"),
  TO: i18n.t("constants.alpha2ToName.TO"),
  TT: i18n.t("constants.alpha2ToName.TT"),
  TN: i18n.t("constants.alpha2ToName.TN"),
  TR: i18n.t("constants.alpha2ToName.TR"),
  TM: i18n.t("constants.alpha2ToName.TM"),
  TC: i18n.t("constants.alpha2ToName.TC"),
  TV: i18n.t("constants.alpha2ToName.TV"),
  UG: i18n.t("constants.alpha2ToName.UG"),
  UA: i18n.t("constants.alpha2ToName.UA"),
  AE: i18n.t("constants.alpha2ToName.AE"),
  GB: i18n.t("constants.alpha2ToName.GB"),
  US: i18n.t("constants.alpha2ToName.US"),
  UM: i18n.t("constants.alpha2ToName.UM"),
  UY: i18n.t("constants.alpha2ToName.UY"),
  UZ: i18n.t("constants.alpha2ToName.UZ"),
  VU: i18n.t("constants.alpha2ToName.VU"),
  VE: i18n.t("constants.alpha2ToName.VE"),
  VN: i18n.t("constants.alpha2ToName.VN"),
  VG: i18n.t("constants.alpha2ToName.VG"),
  VI: i18n.t("constants.alpha2ToName.VI"),
  WF: i18n.t("constants.alpha2ToName.WF"),
  EH: i18n.t("constants.alpha2ToName.EH"),
  YE: i18n.t("constants.alpha2ToName.YE"),
  ZM: i18n.t("constants.alpha2ToName.ZM"),
  ZW: i18n.t("constants.alpha2ToName.ZW")
};

export const itemCategories = {
  mobiles: i18n.t("constants.itemCategories.mobiles"),
  tablets: i18n.t("constants.itemCategories.tablets"),
  computer_laptops: i18n.t("constants.itemCategories.computer_laptops"),
  cameras: i18n.t("constants.itemCategories.cameras"),
  accessory_no_battery: i18n.t("constants.itemCategories.accessory_no_battery"),
  accessory_battery: i18n.t("constants.itemCategories.accessory_battery"),
  health_beauty: i18n.t("constants.itemCategories.health_beauty"),
  fashion: i18n.t("constants.itemCategories.fashion"),
  watches: i18n.t("constants.itemCategories.watches"),
  home_appliances: i18n.t("constants.itemCategories.home_appliances"),
  home_decor: i18n.t("constants.itemCategories.home_decor"),
  toys: i18n.t("constants.itemCategories.toys"),
  sport: i18n.t("constants.itemCategories.sport"),
  luggage: i18n.t("constants.itemCategories.luggage"),
  audio_video: i18n.t("constants.itemCategories.audio_video"),
  documents: i18n.t("constants.itemCategories.documents"),
  jewelry: i18n.t("constants.itemCategories.jewelry"),
  dry_food_supplements: i18n.t("constants.itemCategories.dry_food_supplements"),
  books_collectionables: i18n.t(
    "constants.itemCategories.books_collectionables"
  ),
  pet_accessory: i18n.t("constants.itemCategories.pet_accessory"),
  gaming: i18n.t("constants.itemCategories.gaming")
};

export const localeRep = {
  en: "English",
  cant: "正體中文"
};

export const districts = [
  "中西區 Central and Western",
  "灣仔 Wan Chai",
  "東區 Eastern",
  "南區 Southern",
  "油尖旺 Yau Tsim Mong",
  "深水埗 Sham Shui Po",
  "九龍城 Kowloon City",
  "黃大仙 Wong Tai Sin",
  "觀塘 Kwun Tong",
  "葵青 Kwai Tsing",
  "荃灣 Tsuen Wan",
  "屯門 Tuen Mun",
  "元朗 Yuen Long",
  "北區 North",
  "大埔 Tai Po",
  "沙田 Sha Tin",
  "西貢 Sai Kung"
];

export const areas: { [k: string]: string[] } = {
  "中西區 Central and Western": [
    "堅尼地城 Kennedy Town",
    "石塘咀 Shek Tong Tsui",
    "西營盤 Sai Ying Pun",
    "上環 Sheung Wan",
    "中環 Central",
    "金鐘 Admiralty",
    "半山區 Mid-levels",
    "山頂 Peak"
  ],
  "灣仔 Wan Chai": [
    "灣仔 Wan Chai",
    "銅鑼灣 Causeway Bay",
    "跑馬地 Happy Valley",
    "大坑 Tai Hang",
    "掃桿埔 So Kon Po",
    "渣甸山 Jardine's Lookout"
  ],
  "東區 Eastern": [
    "天后 Tin Hau",
    "寶馬山 Braemar Hill",
    "北角 North Point",
    "鰂魚涌 Quarry Bay",
    "西灣河 Sai Wan Ho",
    "筲箕灣 Shau Kei Wan",
    "柴灣 Chai Wan",
    "小西灣 Siu Sai Wan"
  ],
  "南區 Southern": [
    "薄扶林 Pok Fu Lam",
    "香港仔 Aberdeen",
    "鴨脷洲 Ap Lei Chau",
    "黃竹坑 Wong Chuk Hang",
    "壽臣山 Shouson Hill",
    "淺水灣 Repulse Bay",
    "舂磡角 Chung Hom Kok",
    "赤柱 Stanley",
    "大潭 Tai Tam",
    "石澳 Shek O"
  ],
  "油尖旺 Yau Tsim Mong": [
    "尖沙咀 Tsim Sha Tsui",
    "油麻地 Yau Ma Tei",
    "西九龍填海區 West Kowloon Reclamation",
    "京士柏 King's Park",
    "旺角 Mong Kok",
    "大角咀 Tai Kok Tsui"
  ],
  "深水埗 Sham Shui Po": [
    "美孚 Mei Foo",
    "荔枝角 Lai Chi Kok",
    "長沙灣 Cheung Sha Wan",
    "深水埗 Sham Shui Po",
    "石硤尾 Shek Kip Mei",
    "又一村 Yau Yat Tsuen",
    "大窩坪 Tai Wo Ping",
    "昂船洲 Stonecutters Island"
  ],
  "九龍城 Kowloon City": [
    "紅磡 Hung Hom",
    "土瓜灣 To Kwa Wan",
    "馬頭角 Ma Tau Kok",
    "馬頭圍 Ma Tau Wai",
    "啟德 Kai Tak",
    "九龍城 Kowloon City",
    "何文田 Ho Man Tin",
    "九龍塘 Kowloon Tong",
    "筆架山 Beacon Hill"
  ],
  "黃大仙 Wong Tai Sin": [
    "新蒲崗 San Po Kong",
    "黃大仙 Wong Tai Sin",
    "東頭 Tung Tau",
    "橫頭磡 Wang Tau Hom",
    "樂富 Lok Fu",
    "鑽石山 Diamond Hill",
    "慈雲山 Tsz Wan Shan",
    "牛池灣 Ngau Chi Wan"
  ],
  "觀塘 Kwun Tong": [
    "坪石 Ping Shek",
    "九龍灣 Kowloon Bay",
    "牛頭角 Ngau Tau Kok",
    "佐敦谷 Jordan Valley",
    "觀塘 Kwun Tong",
    "秀茂坪 Sau Mau Ping",
    "藍田 Lam Tin",
    "油塘 Yau Tong",
    "鯉魚門 Lei Yue Mun"
  ],
  "葵青 Kwai Tsing": ["葵涌 Kwai Chung", "青衣 Tsing Yi"],
  "荃灣 Tsuen Wan": [
    "荃灣 Tsuen Wan",
    "梨木樹 Lei Muk Shue",
    "汀九 Ting Kau",
    "深井 Sham Tseng",
    "青龍頭 Tsing Lung Tau"
  ],
  "屯門 Tuen Mun": [
    "大欖涌 Tai Lam Chung",
    "掃管笏 So Kwun Wat",
    "屯門 Tuen Mun",
    "藍地 Lam Tei"
  ],
  "元朗 Yuen Long": [
    "洪水橋 Hung Shui Kiu",
    "廈村 Ha Tsuen",
    "流浮山 Lau Fau Shan",
    "天水圍 Tin Shui Wai",
    "元朗 Yuen Long",
    "新田 San Tin",
    "落馬洲 Lok Ma Chau",
    "錦田 Kam Tin",
    "石崗 Shek Kong",
    "八鄉 Pat Heung"
  ],
  "北區 North": [
    "粉嶺 Fanling",
    "聯和墟 Luen Wo Hui",
    "上水 Sheung Shui",
    "石湖墟 Shek Wu Hui",
    "沙頭角 Sha Tau Kok",
    "鹿頸 Luk Keng",
    "烏蛟騰 Wu Kau Tang"
  ],
  "大埔 Tai Po": [
    "大埔墟 Tai Po Market",
    "大埔 Tai Po",
    "大埔滘 Tai Po Kau",
    "大尾篤 Tai Mei Tuk",
    "船灣 Shuen Wan",
    "樟木頭 Cheung Muk Tau",
    "企嶺下 Kei Ling Ha"
  ],
  "沙田 Sha Tin": [
    "大圍 Tai Wai",
    "沙田 Sha Tin",
    "火炭 Fo Tan",
    "馬料水 Ma Liu Shui",
    "烏溪沙 Wu Kai Sha",
    "馬鞍山 Ma On Shan"
  ],
  "西貢 Sai Kung": [
    "清水灣 Clear Water Bay",
    "西貢 Sai Kung",
    "大網仔 Tai Mong Tsai",
    "將軍澳 Tseung Kwan O",
    "坑口 Hang Hau",
    "調景嶺 Tiu Keng Leng",
    "馬游塘 Ma Yau Tong"
  ]
};

export const backendErrorType = {
  paymentError: "JARVIX_PAY_API_ERROR",
  paymentCustomerErrorMessage: "The phone number or email was already used"
};

export const expressOrderStates = [
    "CREATED",
    "ARRIVED_AT_WAREHOUSE", 
    "CANCELLED",
    "COMPLETED",
    "CONFIRMED",
    "COURIER_ASSIGNED",
    "NOT_ABLE_TO_RECEIVE_PRODUCTS",
    "ON_THE_WAY_TO_CUSTOMER",
    "ON_THE_WAY_TO_WAREHOUSE",
    "PASSED_TO_COURIER",
    "REFUNDED",
    "REJECTED",
    "RETURNED_TO_MERCHANT",
    "RETURNED_TO_MERCHANT_AT_WAREHOUSE",
    "SENDING_TO_ANOTHER_WAREHOUSE",
    "UNDELIVERED",
    "SCHEDULED_FOR_REDELIVER",
    "PROBLEM_SHIPMENT",
    "ON_THE_WAY_TO_THIRD_PARTY",
    "ARRIVED_AT_THIRD_PARTY_WAREHOUSE",
    "ARRIVED_AT_THIRD_PARTY_PICK_UP_STORE",
    "REQUESTED_RETURN_FROM_THIRD_PARTY",
    "READY_TO_RETURN_FROM_THIRD_PARTY",
    "RETURNING_FROM_THIRD_PARTY_COMPANY_WAREHOUSE"  
];

export const expressOrderListThirdPartyStates = [
    "ON_THE_WAY_TO_THIRD_PARTY",
    "ARRIVED_AT_THIRD_PARTY_WAREHOUSE",
    "REQUESTED_RETURN_FROM_THIRD_PARTY",
    "READY_TO_RETURN_FROM_THIRD_PARTY",
    "RETURNING_FROM_THIRD_PARTY_COMPANY_WAREHOUSE"    
];
