import axios, { AxiosInstance } from "axios";
import { Module } from "vuex";

import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

export function creditsModule(axiosInstance: AxiosInstance) {
  const credits: Module<any, any> = {
    namespaced: true,
    state: {
      cards: null as Card[] | null,
      customerId: null as string | null,
      intentId: null as string | null,
      createdCard: null as boolean | null,
      chargeId: null as string | null,
      comapnyLog: null as any,
      // receiptUrl: null as string | null,
    },
    getters: {
      getCards: state => state.cards,
      getCustomerId: state => state.customerId,
      getIntentId: state => state.intentId,
      getCardById(state) {
        return (id: string) => {
          return state.cards.filter((card: Card) => {
            return card.id === id;
          })[0];
        };
      },
      hasCard: state => {
        return state.cards ? state.cards.length > 0 : false;
      },
      hasCreatedCard: state => state.createdCard,
      // getReceiptUrl: state => state.receiptUrl
      getChargeId: state => state.chargeId,
      getCompanyLog: state => state.comapnyLog
    },
    mutations: {
      initCards(state) {
        if (!state.cards) {
          state.cards = [];
        }
      },
      addCard(state, card: Card) {
        if (!state.cards) {
          state.cards = [];
        }

        if (
          state.cards.filter((theCard: Card) => {
            return theCard.id === card.id;
          }).length === 0
        ) {
          state.cards.push(card);
        }
      },
      removeCard(state, cardId: string){
        state.cards = state.cards.filter((card: Card) => {
          return card.id !== cardId;
        });
      },
      setCustomerId(state, id: string) {
        Storage.saveToSD("state.credits.customerId", id);
        state.customerId = id;
      },
      setIntentId(state, id: string) {
        Storage.saveToSD("state.credits.intentId", id);
        state.intentId = id;
      },
      // setReciptUrl(state, url: string){
      //     state.receiptUrl = url;
      // }
      createCard(state) {
        Storage.saveToSD("state.credits.createdCard", true);
        state.createdCard = true;
      },
      createCardFailed(state) {
        Storage.saveToSD("state.credits.createdCard", false);
        state.createCard = false;
      },
      addChargeId(state, chargeId: string) {
        Storage.saveToSD("state.credits.chargeId", chargeId);
        state.chargeId = chargeId;
      }
    },
    actions: {
      async getCards(store) {
          const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url: "payment/methods"
          })
          .then(response => {
            //console.log(response);
            if (response.data.success) {
              if (response.data.data.paymentMethods) {
                for (
                  let i = 0;
                  i < response.data.data.paymentMethods.length;
                  i++
                ) {
                  const thisCard: Card = {
                    id: response.data.data.paymentMethods[i].id,
                    name: response.data.data.paymentMethods[i].paymentMethod.name,
                    brand: response.data.data.paymentMethods[i].paymentMethod.brand,
                    expiryMonth: response.data.data.paymentMethods[i].paymentMethod.expiryMonth,
                    expiryYear: response.data.data.paymentMethods[i].paymentMethod.expiryYear,
                    last4: response.data.data.paymentMethods[i].paymentMethod.last4                
                  };
                  store.commit("addCard", thisCard);
                }
              } else {
                store.commit("initCards");
              }

              return true;
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
       
      },
      async addCard(store) {
        const serverReturn = await axiosInstance
          .request({
            method: "POST",
            url: "payment/save"
          })
          .then(response => {
            if (response.data.success) {
              store.commit("setCustomerId", response.data.data.jarvixCustomerId);
              return true;
            }
            return false;
          })
          .catch(error => {
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      },

      async removeCard(store, payload: any){
        const serverReturn = await axiosInstance
          .request({
            method: "DELETE",
            url: "payment/method",
            data:{
              paymentMethodId: payload.paymentMethodId
            }
          })
          .then(response => {
            if (response.data.success) {
              store.commit('removeCard', payload.paymentMethodId);
              return true;
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        console.log("removeCard", serverReturn);
        return serverReturn;
      },

      async charge(store, payload: any) {
        const serverReturn = await axiosInstance
          .request({
            method: "POST",
            url: "payment/charge",
            data: {
              amount: parseInt(payload.amount),
              paymentMethodId: payload.paymentMethodId
            }
          })
          .then(response => {
            //console.log(response);
            if (response.data.success) {
              // TODO: response.data.data.chargeId
              store.commit("addChargeId", response.data.data.chargeId);
              return true;
            }

            return false;
          })
          .catch((error) => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      },
      async getChargeStatus(store) {
        const serverServerReturn = await axiosInstance({
          method: "GET",
          url: "payment/status" + "/" + store.getters.getChargeId
        })
          .then(response => {
            //console.log(response);
            if (response.data.success) {
              if (['succeeded', 'uncaptured'].includes(response.data.data.result)) {
                // SUCCESS
                // FAILED
                // null
                return true;
              } else if (response.data.data.result === "failed") {
                return false;
              } else if (response.data.data.result === null) {
                return "TRY_AGAIN";
              }
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverServerReturn;
      },
      async getCompanyLog(store) {
        const serverServerReturn = await axiosInstance({
          method: "GET",
          url: "company/logs"
        }).then(response => {
          console.log(response)
          if (response.data.success) {
            store.state.comapnyLog = response.data.data;
            return true
          }
          return false
        }).catch(error => {
          console.log(error);
          return serverResponseHelpers.processServerErrors(error, console.trace());
        })
        return serverServerReturn;
      }
    }
  };
  return credits;
}
