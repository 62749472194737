import { AxiosInstance } from "axios";
import { Module } from "vuex";
import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { saveFileTODevice } from "@/utils/saveFileToDevice";

export function exportReportModule(axiosInstance: AxiosInstance) {
  return {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
      async generatereport(store, criteria: ReportCriteria) {
        if (
          criteria.reportType !== "logs" &&
          criteria.reportType !== "shipments"
        ) {
          store.commit("showConnectionError");
        }
        // code to download xlxs from backend
        const serverReturn = await axiosInstance
          .request({
            method: "get",
            url:
              "export/" +
              criteria.reportType +
              "/" +
              criteria.fromDate +
              "/" +
              criteria.toDate
          })
          .then(response => {
            //console.log(response);
            if (response.status == 200) {
              const s3Url = response.data.data.url;
              saveFileTODevice(s3Url);
              return true;
            }
            return false;
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
      }
    }
  } as Module<any, any>;
}
