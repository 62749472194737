export class SessionStorageHandler {
  public static saveToSD(key: string, value: any): void {
    // stringify object and save it to sessionStoarge
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  // read and parse and return Object from sessionStorage
  public static loadFromSD(key: string): any | null {
    try{
      if (key in sessionStorage) {
        return JSON.parse(sessionStorage.getItem(key) as string) ?? "";
      } else {
        return this.dataNotExistError(key);
      }
    }catch(e){
      return this.dataNotExistError(key);
    }
  }


    

  public static deleteFromSD(...args: string[]): void {
    // delete from sessionStorage by keys
    for (let i = 0; i < args.length; i++) {
      const key = args[i];
      if (key in sessionStorage) {
        sessionStorage.removeItem(key);
      }
    }
  }

  public static hasKey(key: string) {
    return key in sessionStorage;
  }

  public static deleteAllFromSD() {
    sessionStorage.clear();
  }
  private static dataNotExistError = function(key: string) {
    // throw new Error(key + " not exits in SD");
    console.log(key + " not exits in SD");
    return "";

  };
}
