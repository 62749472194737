


















import { Component, Prop, Vue } from "vue-property-decorator";
import { MDCRipple } from "@material/ripple";

@Component
export default class TheMDCIconButton extends Vue {
  @Prop() reference!: string;
  @Prop() icon!: string;
  @Prop() event?: string;
  @Prop() backgroundColor?: string;
  @Prop() color?: string;
  @Prop() height!: string | undefined;
  @Prop() width!: string | undefined;
  @Prop() removeVerticalPadding?: boolean;

  public mounted() {
    const iconButtonRipple = new MDCRipple(
      this.$refs[this.reference as string] as Element
    );
    iconButtonRipple.unbounded = true;
  }

  private emitToParent() {
    if (this.event) {
      this.$emit(this.event);
    }
  }
}
