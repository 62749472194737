


































import { Component, Prop, Vue } from "vue-property-decorator";
import { MDCRipple } from "@material/ripple";
import  * as theme from '@/assets/scss/_theme.scss';


@Component
export default class TheMDCButtonShapedRaised extends Vue {
  @Prop() label!: string;
  @Prop() reference!: string;
  @Prop() event!: string | undefined;
  @Prop() isDisabled!: boolean | undefined;
  @Prop() startIcon!: string | undefined;
  @Prop() trailIcon!: string | undefined;
  @Prop() width?: string;
  @Prop() height?: string;
  @Prop() minWidth?: string;
  @Prop() fontSize?: string;
  @Prop() backgroundColor?: string;
  @Prop() color?: string;

  public mounted() {
    new MDCRipple(this.$refs[this.reference as any] as Element);
  }

  get theme(){
    return theme;
  }

  private emitToParent() {
    if (this.event) {
      this.$emit(this.event);
    }
  }
}
