var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: _vm.reference, staticClass: "error-dialog__container mdc-dialog" },
    [
      _c("div", { staticClass: "mdc-dialog__container" }, [
        _c(
          "div",
          {
            staticClass: "mdc-dialog__surface",
            attrs: {
              role: "alertdialog",
              "aria-modal": "true",
              "aria-labelledby": _vm.reference + "-title",
              "aria-describedby": _vm.reference + "-content"
            }
          },
          [
            _c(
              "h2",
              {
                staticClass: "mdc-dialog__title",
                attrs: { id: _vm.reference + "-title" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.title || _vm.text.title) +
                    "\n        "
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "mdc-dialog__content",
                attrs: { id: _vm.reference + "-content" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.message || _vm.text.message) +
                    "\n      "
                )
              ]
            ),
            _c(
              "footer",
              {
                staticClass: "mdc-dialog__actions",
                staticStyle: { "justify-content": "center" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "mdc-button mdc-dialog__button",
                    attrs: {
                      type: "button",
                      "data-mdc-dialog-action": "closing"
                    },
                    on: { click: _vm.emitToParent }
                  },
                  [
                    _c("div", { staticClass: "mdc-button__ripple" }),
                    _c("span", { staticClass: "mdc-button__label" }, [
                      _vm._v(_vm._s(_vm.text.okay))
                    ])
                  ]
                )
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "mdc-dialog__scrim" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }