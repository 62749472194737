import { AxiosInstance } from "axios";
import { Module } from "vuex";
import DateFormatHelpers from '@/utils/dateFormatHelpers';
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";
import * as serverResponseHelpers from "@/utils/serverResponseHelpers";

export function dateModule(axiosInstance: AxiosInstance) {
  const date: Module<any, any> = {
    namespaced: true,
    state: {
        availablePickUpDates: [] as string[],
        availableDeliveryDates: [] as string[]
    },
    getters: {
      getAvailablePickUpDates(state){
        return state.availablePickUpDates;
      },
      getAvailableDeliveryDates(state){
        return state.availableDeliveryDates;
      }
    },
    mutations: {
     setAvailablePickUpDates(state: any, availablePickUpDates: string[]){
        Storage.saveToSD("state.shipment.availablePickUpDates",availablePickUpDates);
        state.availablePickUpDates = availablePickUpDates;
      },
      setAvailableDeliveryDates(state: any, availableDeliveryDates: string[]){
        Storage.saveToSD("state.shipment.availableDeliveryDates",availableDeliveryDates);
        state.availableDeliveryDates = availableDeliveryDates;
      }
    },
    actions: {
      async fetchExpressOrderAvailableDate(store) {
        const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url: "date/valid"
          })
          .then(response => {
            //console.log(response);
            if (response.data.success && response.data.data) {
              // const dateArray = [];
              // for(let i =0;i < response.data.data.dates.length;i++){
              //   dateArray.push(new Date(response.data.data.dates[i]));
              // }
              const sortedDeliveryDates = response.data.data.deliveryDates.sort((a: string ,b: string) => (new Date(a) as any) - (new Date(b) as any));
              const sortedPickUpDates = response.data.data.pickUpDates.sort((a: string ,b: string) => (new Date(a) as any) - (new Date(b) as any));
              const indexOfClosestTOMaxPickUpDate = sortedDeliveryDates.findIndex((deliveryDate: string) => new Date(deliveryDate) >= new Date(sortedPickUpDates[sortedPickUpDates.length - 1]));
              store.commit("setAvailableDeliveryDates",sortedDeliveryDates.slice(0,indexOfClosestTOMaxPickUpDate + 3));
              store.commit("setAvailablePickUpDates", sortedPickUpDates);

              return true;
            }else{
              return false;
            }
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      }
    }
  };

  return date;
}
