






































import { Component, Vue, Prop } from "vue-property-decorator";
import { MDCDialog } from "@material/dialog";

@Component
export default class ErrorPrompt extends Vue {
  @Prop() reference!: string;
  @Prop() title?: string;
  @Prop() message?: string;
  @Prop() event?: string; // emit to parent if accept button is clicked
  @Prop() width?: string;
  @Prop() height?: string;

  private isShow = () => true;
  get text() {
    return {
      title: this.$t("dialogs.errorPrompt.title"),
      message: this.$t("dialogs.errorPrompt.message"),
      okay: this.$t("dialogs.errorPrompt.okay")
    };
  }
  private errorPrompt!: MDCDialog;

  public mounted() {
    this.errorPrompt = new MDCDialog(
      this.$refs[this.reference as string] as Element
    );
    this.errorPrompt.listen('MDCDialog:closing', () => {
      this.$store.commit('dialog/resetErrorDialogData');
    });
  }

  public show() {
    (this.errorPrompt as any).open();
  }

  private emitToParent() {
    if (this.event) {
      this.$emit(this.event);
    }
  }
}
