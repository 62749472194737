import { AxiosInstance } from "axios";
import { Module } from "vuex";

import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";
import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { Alpha2ToName, itemCategories } from "@/assets/constant";
import store from ".";
import i18n from "@/i18n";


function verify(item: Item | any) {
  // verify before listing
  if (item.category === "accessory_with_battery") {
    item.category = "accessory_battery";
  }
}

export function shipmentModule(axiosInstance: AxiosInstance) {

  return {
    namespaced: true,
    state: {
      // shipment form data
      isSubprime: true as boolean,
      isDocument: false as boolean,
      senderAddress: null as Address | ExpressOrderAddress | null,
      receiverAddress: null as Address | ExpressOrderAddress | null,
      noOfItems: 1 as number,
      items: [] as Item[],
      itemIdCounter: 9999999,
      expressOrderItems: [] as ExpressOrderItem[],
    
      rates: [] as ShipmentRate[], // rates from quote rate api
      selectedRate: null as ShipmentRate | null, // rate selected by user
      selectedPickupOption: null as "pickup" | "markAsDirectlyHandover" | null,
      selectedPickupDate: null as string | null,
      selectedPickupMinTime: null as string | null,
      selectedPickupMaxTime: null as string | null,
      trackingNumber: null as string | null, 
      isInsured: false as boolean,
      taxesDutiesPaidBy: "Receiver" as "Sender" | "Receiver",
      supportCountries: Alpha2ToName, // all support country
      paymentMethod: "PAID_BY_MERCHANT" as PaymentMethod,
      cashOnDeliveryAmount:  null as number | null ,
      orderNotes: null as string | null,
      pickUpDate: "" as string,
      deliveryDate: "" as string,
      toPickUpStore: false as boolean,
      pickUpStoreId: "" as string,
      pickUpName: "" as string,
      pickUpPhone: "" as string

    },
    getters: {
      getShipmentCountries(state: any) {
        return state.supportCountries;
      },
      getShipmentSenderAddress(state: any) {
        return JSON.parse(JSON.stringify(state.senderAddress));
      },
      getShipmentReceiverAddress(state: any) {
        return JSON.parse(JSON.stringify(state.receiverAddress));
      },
      hasShipmentSenderAddress(state: any) {
        return state.senderAddress != null;
      },
      hasShipmentReceiverAddress(state: any) {
        return state.receiverAddress != null;
      },
      getItemCategories(state: any) {
        return JSON.parse(JSON.stringify(itemCategories));
      },
      hasShipmentItem(state: any): boolean {
        return state.items && state.items.length > 0;
      },
      getShipmentItems(state: any): Item[] {
        return JSON.parse(JSON.stringify(state.items)) || [];
      },

      getShipmentItemById(state: any): (itemId: number) => Item | null {
        return (itemId: number) => {
          return (
            JSON.parse(JSON.stringify(state.items.find((item: Item) => item.itemId === itemId))) as Item || null
          );
        };
      },
      getTrackingNumber(state: any): string {
        return state.trackingNumber;
      },

      getExpressOrderItems: state => JSON.parse(JSON.stringify(state.expressOrderItems)),
      getExpressOrderItemsNoOfItems(state: any): number | "" {
        return JSON.parse(JSON.stringify(state.expressOrderItems))?.length || 0;
      },

      getRates(state: any): Shipment[] {
        return JSON.parse(JSON.stringify(state.rates));
      },
      hasSelectedRate(state: any): boolean {
        return state.selectedRate != null;
      },
      hasSelectedPickupOption(state: any): boolean {
        return state.selectedPickupOption != null;
      },
      hasSelectedPickupDate(state: any): boolean {
        return state.selectedPickupDate != null;
      },
      hasSelectedPickupTime(state: any): boolean {
        return (
          state.selectedPickupMinTime != null &&
          state.selectedPickupMaxTime != null
        );
      },
      hasSelectedPickupTimeSlot(state: any): boolean {
        return (
          state.selectedPickupDate != null &&
          state.selectedPickupMinTime != null &&
          state.selectedPickupMaxTime != null
        );
      },
      getSelectedRate(state: any): ShipmentRate {
        return state.selectedRate as ShipmentRate;
      },
      getTimeSlots(state: any): TimeSlots[] | null {
        return state.selectedRate?.timeSlots || null;
      },
      getSelectedPickupOption(state: any) {
        return state.selectedPickupOption;
      },
      getShipmentIsInsured(state: any) {
        return state.isInsured;
      },
      getShipmentTaxesDutiesPaidBy(state: any) {
        return state.taxesDutiesPaidBy;
      },
      isSubprime(state: any){
        return state.isSubprime;
      },
      isDocument(state: any){
        return state.isDocument;
      },
      getShipmentIsSubprime(state: any) {
        return state.isSubprime;
      },
      getShipmentIsDocument(state: any) {
        return state.isDocument;
      },
      getSelectedPickupDate(state: any) {
        return state.selectedPickupDate;
      },
      getSelectedPickupMinTime(state: any) {
        return state.selectedPickupMinTime;
      },
      getSelectedPickupMaxTime(state: any) {
        return state.selectedPickupMaxTime;
      },
      getPaymentMethod(state: any){
        return state.paymentMethod;
      },
      getCashOnDeliveryAmount(state: any){
        return state.cashOnDeliveryAmount;
      },
      getOrderNotes(state: any){
        return state.orderNotes;
      },
      getPickUpDate(state: any){
        return state.pickUpDate;
      },
      getDeliveryDate(state: any){
        return state.deliveryDate;
      },
      getToPickUpStore(state: any){
        return state.toPickUpStore;
      },
      getPickUpStoreId(state: any){
        return state.pickUpStoreId;
      },
      getPickUpName(state: any){
        return state.pickUpName;
      },
      getPickUpPhone(state: any){
        return state.pickUpPhone;
      }
     
    },
    mutations: {
      saveShipmentSenderAddress(state: any, address: Address | ExpressOrderAddress | null) {
        Storage.saveToSD("state.shipment.senderAddress", address);
        state.senderAddress = null;
        if(address) state.senderAddress = Object.assign({},address);
      },
      saveShipmentReceiverAddress(state: any, address: Address | ExpressOrderAddress | null) {
        Storage.saveToSD("state.shipment.receiverAddress", address);
        state.receiverAddress = null;
        if(address)state.receiverAddress = Object.assign({},address);
      },
      setNoOfItems(state: any, noOfItems: number){
        Storage.saveToSD("state.shipment.noOfItems", noOfItems || 1);
        state.noOfItems = noOfItems || 1;

      },
      _saveShipmentItems(state: any, items: Item[]) {
        // please don't directly use this function, this function only aim for overwrite use
        Storage.saveToSD("state.shipment.items", items);
        state.items = items;
      },
      addShipmentItem(state: any, item: Item) {
        item.itemId = state.itemIdCounter;
        state.itemIdCounter -= 1;
        verify(item);
        state.items.push(item);
        Storage.saveToSD("state.shipment.items", state.items);
      },
      addShipmentItems(state: any, items: Item[]) {
        items.forEach((item: Item) => {
          item.itemId = state.itemIdCounter;
          state.itemIdCounter -= 1;
          verify(item);
          state.items.push(item);
          Storage.saveToSD("state.shipment.items", state.items);
        });
      },
      modifyShipmentItem(state: any, item: Item) {
        const target: Item = state.items.find((i: Item) => {
          return i.itemId === item.itemId;
        });

        target.description = item.description;
        target.sku = item.sku;
        target.actualWeight = item.actualWeight;
        target.length = item.length;
        target.height = item.height;
        target.category = item.category;
        target.declaredCurrency = item.declaredCurrency;
        target.declaredCustomsValue = item.declaredCustomsValue;
        target.quantity = item.quantity;
        Storage.saveToSD("state.shipment.items", state.items);
      },

      removeShipmentItem(state: any, itemId: number) {
        let targetIndex;
        for (let i = 0; i < state.items.length; i++) {
          const thisItem = state.items[i];
          if (thisItem.itemId == itemId) {
            targetIndex = i;
            break;
          }
        }
        state.items.splice(targetIndex, 1);
        Storage.saveToSD("state.shipment.items", state.items);
      },
      resetShipmentItems(state: any){
        Storage.deleteFromSD("state.shipment.items");
        state.items = state.items.splice(0, state.items.length);
      },
      setTrackingNumber(state: any, trackingNumber: string){
        Storage.saveToSD("state.shipment.trackingNumber", trackingNumber);
        state.trackingNumber = trackingNumber;
      },
      addRateOffers(state: any, rates: ShipmentRate[]) {
        Storage.saveToSD("state.shipment.rates", rates);
        state.rates = rates;
      },
      selectRate(state: any, rate: ShipmentRate) {
        Storage.saveToSD("state.shipment.selectedRate", rate);
        state.selectedRate = rate;
      },
      selectPickupOption(
        state: any,
        pickupOption: "pickup" | "markAsDirectlyHandover" | null
      ) {
        Storage.saveToSD("state.shipment.selectedPickupOption", pickupOption);
        state.selectedPickupOption = pickupOption;
      },
      selectPickupDate(state: any, pickupDate: string | null) {
        Storage.saveToSD("state.shipment.selectedPickupDate", pickupDate);
        state.selectedPickupDate = pickupDate;
      },
      selectPickupTime(state: any, pickupTime: TimeSlot | null) {
        Storage.saveToSD("state.shipment.selectedPickupTime", pickupTime);
        if (pickupTime) {
          state.selectedPickupMinTime = pickupTime.minTime;
          state.selectedPickupMaxTime = pickupTime.maxTime;
        } else {
          state.selectedPickupMinTime = null;
          state.selectedPickupMaxTime = null;
        }
      },
      setTaxesDutiesPaidBy(
        state: any,
        taxesDutiesPaidBy: "Sender" | "Receiver"
      ) {
        Storage.saveToSD("state.shipment.taxesDutiesPaidBy", taxesDutiesPaidBy);
        state.taxesDutiesPaidBy = taxesDutiesPaidBy;
      },
      setIsInsured(state: any, isInsured: boolean) {
        Storage.saveToSD("state.shipment.isInsured", isInsured);
        state.isInsured = isInsured;
      },
      setIsSubprime(state: any, isSubprime: boolean) {
        Storage.saveToSD("state.shipment.isSubprime", isSubprime);
        state.isSubprime = isSubprime;
      },
      setIsDocument(state: any, isDocument: boolean) {
        Storage.saveToSD("state.shipment.isDocument", isDocument);
        state.isDocument = isDocument;
      },
      resetShipment(state: any) {
        Storage.deleteFromSD(
          "state.shipment.senderAddress",
          "state.shipment.receiverAddress",
          "state.shipment.items",
          "state.shipment.isInsured",
          "state.shipment.taxesDutiesPaidBy",
          "state.shipment.isSubprime",
          "state.shipment.isDocument",
          "state.shipment.paymentMethod",
          "state.shipment.cashOnDeliveryAmount",
          "state.shipment.orderNotes",
          "state.shipment.noOfItems",
          "state.shipment.deliveryDate",
          "state.shipment.pickUpDate",
          "state.shipment.trackingNumber",
          "state.shipment.expressOrderItems",
          "state.shipment.toPickUpStore",
          "state.shipment.pickUpStoreId",
          "state.shipment.pickUpName",
          "state.shipment.pickUpPhone"
          );
        // shipment form data
        state.isSubprime = true as boolean;
        state.isDocument = false as boolean;
        state.senderAddress = null as Address | ExpressOrderAddress | null;
        state.receiverAddress = null as Address | ExpressOrderAddress | null;
        state.noOfItems = 1 as number;
        state.items = [] as Item[];
        state.itemIdCounter = 9999999;
        state.expressOrderItems = [] as ExpressOrderItem[];
        state.rates = [] as ShipmentRate[]; // rates from quote rate api
        state.selectedRate = null as ShipmentRate | null; // rate selected by user
        state.selectedPickupOption = null as "pickup" | "markAsDirectlyHandover" | null;
        state.selectedPickupDate = null as string | null;
        state.selectedPickupMinTime = null as string | null;
        state.selectedPickupMaxTime = null as string | null;
        state.isInsured = false as boolean;
        state.taxesDutiesPaidBy = "Receiver" as "Sender" | "Receiver";
        state.supportCountries = Alpha2ToName; // all support country
        state.paymentMethod = "PAID_BY_MERCHANT" as PaymentMethod;
        state.cashOnDeliveryAmount =  null as number | null ;
        state.orderNotes = null as string | null;
        state.pickUpDate = "" as string;
        state.deliveryDate = "" as string;
        state.trackingNumber = null as string | null; 
        state.pickUpStoreId = "" as string;
        state.toPickUpStore = false as boolean;
      },
      resetRate(state: any) {
        Storage.deleteFromSD(
          "state.shipment.rates",
          "state.shipment.selectedRate"
        );
        state.rates = [];
        state.selectedRate = null;
      },
      resetPickup(state: any) {
        Storage.deleteFromSD(
          "state.shipment.selectedPickupOption",
          "state.shipment.selectedPickupDate",
          "state.shipment.selectedPickupTime",
          "state.shipment.toPickUpStore"
        );
        state.selectedPickupOption = null;
        state.selectedPickupDate = null;
        state.selectedPickupMinTime = null;
        state.selectedPickupMaxTime = null;
        state.toPickUpStore = false;
      },

      setPaymentMethod(state: any, paymentMethod: PaymentMethod){
        Storage.saveToSD("state.shipment.paymentMethod", paymentMethod);
        state.paymentMethod = paymentMethod;
      },
      setCashOnDeliveryAmount(state: any, cashOnDeliveryAmount: number | null){
        Storage.saveToSD("state.shipment.cashOnDeliveryAmount", cashOnDeliveryAmount);
        state.cashOnDeliveryAmount = cashOnDeliveryAmount;
      },

      setOrderNotes(state: any, orderNotes: string | null){
        Storage.saveToSD("state.shipment.orderNotes", orderNotes || "");
        state.orderNotes = orderNotes;
      },
      setDeliveryDate(state: any, deliveryDate: string){
        Storage.saveToSD("state.shipment.deliveryDate", deliveryDate);
        state.deliveryDate = deliveryDate;
      },
      setPickUpDate(state: any, pickUpDate: string){
        Storage.saveToSD("state.shipment.pickUpDate", pickUpDate);
        state.pickUpDate = pickUpDate;
      },
      setExpressOrderItems(state: any, expressOrderItems: ExpressOrderItem[]){
        Storage.saveToSD("state.shipment.expressOrderItems", expressOrderItems);
        state.expressOrderItems = expressOrderItems;
      },
      setToPickUpStore(state: any, toPickUpStore: boolean){
        Storage.saveToSD("state.shipment.toPickUpStore", toPickUpStore);
        state.toPickUpStore = toPickUpStore;
      },
      setPickUpStoreId(state: any, pickUpStoreId: string){
        Storage.saveToSD("state.shipment.pickUpStoreId", pickUpStoreId);
        state.pickUpStoreId = pickUpStoreId;
      },
      setPickUpName(state: any, pickUpName: string){
        Storage.saveToSD("state.shipment.pickUpName", pickUpName);
        state.pickUpName = pickUpName;
      },
      setPickUpPhone(state: any, pickUpPhone: string){
        Storage.saveToSD("state.shipment.pickUpPhone", pickUpPhone);
        state.pickUpPhone = pickUpPhone;
      }
    },
    actions: {
      async quoteRate(store: any) {
        const serverReturn = await axiosInstance
          .request({
            method: "POST",
            url: "rate/rates",
            data: {
              originPostalCode: ((store.state
                .senderAddress as unknown) as Address).postalCode,
              destinationPostalCode: ((store.state
                .receiverAddress as unknown) as Address).postalCode,
              destinationCountryAlpha2: ((store.state
                .receiverAddress as unknown) as Address).countryCodeAlpha2,
              isInsured: store.state.isInsured,
              TaxesDutiesPaidBy: store.state.taxesDutiesPaidBy,
              items: store.state.items
            }
          })
          .then(async response => {
            //console.log(response);
            if (response.data.success && response.data.data.length > 0) {
              // if has rate
              for (let i = 0; i < response.data.data.length; i++) {
                const thisRate: any = response.data.data[i];
                if (thisRate.courierDoesPickup) {
                  thisRate["timeSlots"] = thisRate["timeslots"];
                }
              }
              // add rates
              store.commit("addRateOffers", response.data.data);
              return true;
            } else {
              store.state.rates = [];
              return false;
            }
          })
          .catch(error => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      },
      async createShipment(store: any) {
        const serverReturn = await axiosInstance
          .request({
            method: "POST",
            url: "easyship-order",
            data: {
              totalCharge: store.state.selectedRate?.totalCharge,
              originAddressId: store.state.senderAddress?.id,
              originAddress: {
                addressLine1: store.state.senderAddress?.addressLine1,
                addressLine2: store.state.senderAddress?.addressLine2,
                countryCodeAlpha2: store.state.senderAddress?.countryCodeAlpha2,
                postalCode: store.state.senderAddress?.postalCode,
                city: store.state.senderAddress?.city,
                state: store.state.senderAddress?.state,
                contactName: store.state.senderAddress?.contactName,
                contactPhone: store.state.senderAddress?.contactPhone,
                contactEmail: store.rootState.user?.email,
                companyName: store.rootState.company?.name,
                name: "default" // deprecating
              },
              destinationAddressId: store.state.receiverAddress?.id,
              destinationAddress: {
                addressLine1: store.state.receiverAddress?.addressLine1,
                addressLine2: store.state.receiverAddress?.addressLine2,
                countryCodeAlpha2: store.state.receiverAddress?.countryCodeAlpha2,
                postalCode: store.state.receiverAddress?.postalCode,
                city: store.state.receiverAddress?.city,
                state: store.state.receiverAddress?.state,
                contactName: store.state.receiverAddress?.contactName,
                contactPhone: store.state.receiverAddress?.contactPhone,
                contactEmail: store.rootState.user?.email,
                companyName: store.rootState.company?.name,
                name: "default" // deprecating
              },
              items: store.state.items,
              selectedCourierId:
                store.state.selectedRate?.courier.externalCourierId,
              isInsured: store.state.isInsured,
              taxesDutiesPaidBy: store.state.taxesDutiesPaidBy,
              hasPickup: store.state.selectedPickupOption == "pickup",
              pickupDate: store.state.selectedPickupDate,
              minTime: store.state.selectedPickupMinTime,
              maxTime: store.state.selectedPickupMaxTime
            }
          })
          .then(response => {
            //console.log(response);
            if (response.data.success) {
              return true;
            }
            return false;
          })
          .catch(error => {
            if (
              error.request.status === serverResponseHelpers.BAD_REQUEST &&
              error.response.data.error.user_message ===
                serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE
            ) {
              return serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE;
            } else if (
              error.request.status === serverResponseHelpers.BAD_REQUEST
            ) {
              return serverResponseHelpers.USER_INPUT_ERROR;
            }
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      },

      //TODO: wait for API
      async createExpressShipment(store: any) {
        const serverReturn = await axiosInstance
          .request({
            method: "POST",
            url: "express-order",
            data: {
              isDocument: store.state.isDocument,
              isSelfPickUp: !!store.state.pickUpStoreId,
              originAddressId: store.state.senderAddress?.id,
              originAddress: store.state.senderAddress,
              destinationAddressId: store.state.receiverAddress?.id,
              destinationAddress: store.state.receiverAddress,
              pickUpStoreId: store.state.pickUpStoreId,
              receiverName: store.state.pickUpName,
              receiverPhone: store.state.pickUpPhone,
              noOfItems: store.state.noOfItems,
              items: store.state.expressOrderItems,
              isInsured: false,
              paymentMethod: store.state.paymentMethod,
              cashOnDeliveryAmount: store.state.cashOnDeliveryAmount,
              deliveryDate: store.state.isDocument?store.state.deliveryDate:null,
              pickUpDate: store.state.isDocument?store.state.pickUpDate:null,
            }
          })
          // {
          //   "success": false,
          //   "data": {
          //     "shipmentOrderId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //     "newBalance": 0,
          //     "areaRank": "12-01",
          //     "contactNumber": "98745663"
          //   }
          // }
          .then(response => {
            //console.log(response);
            if (response.data.success) {
              return {success: true, newBalance: response.data.data.newBalance || "", areaRank: response.data.data.areaRank || "", contactNumber: response.data.data.contactNumber || ""};
            }
            return false;
          })
          .catch(error => {
            if (
              error.request.status === serverResponseHelpers.BAD_REQUEST &&
              error.response.data.error.user_message ===
                serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE
            ) {
              return serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE;
            }else if (error.request.status === serverResponseHelpers.BAD_REQUEST &&
              error.response.data.error.user_message ===
              serverResponseHelpers.TRACKING_NUMBER_ALREADY_IN_USE
            ){
              return serverResponseHelpers.TRACKING_NUMBER_ALREADY_IN_USE;
            }else if (
              error.request.status === serverResponseHelpers.BAD_REQUEST
            ) {
              return serverResponseHelpers.USER_INPUT_ERROR;
            }
            console.log(error);
            return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      },
      async validateTrackingNumeberWithType(store: any, payload: any){
        const serverReturn = await axiosInstance
        .request({
          method: "POST",
          url: "label/validate",
          data: {trackingNumber: payload.trackingNumber, sameDayDelivery: payload.sameDayDelivery}
        })
        .then(response => {
          //console.log(response);
          if (response.data.success && response.data.data.valid) {
            store.commit("setTrackingNumber",payload.trackingNumber);
            return true;
          }else if (response.data.success && response.data.data.errorCode){
            let errorMessage = i18n.t("shipment.shipmentForm.qrCodeScanner.error.scanOrInputTrackingNumber");
            switch(response.data.data.errorCode){
              case "ALREADY_IN_USE":
                errorMessage = i18n.t("shipment.shipmentForm.qrCodeScanner.error.alreadyInUse");
              break;
              case "WRONG_TYPE":
                errorMessage = i18n.t("shipment.shipmentForm.qrCodeScanner.error.wrongType");
              break;
              case "INVALID_NUMBER":
               errorMessage = i18n.t("shipment.shipmentForm.qrCodeScanner.error.invalidLabel");
               break;
            }
            // store.commit("dialog/showErrorDialog", {title: i18n.t("shipment.shipmentForm.qrCodeScanner.error.invalidLabel"), msg: errorMessage, closeBlt: i18n.t("dialogs.close") as string}
            //   , {root: true});
            return errorMessage;
            // if(ALREADY_IN_USE, WRONG_TYPE, INVALID_NUMBER ]
          }else{
            return false;

          }
        }).catch(error => {
          if (
            error.request.status === serverResponseHelpers.BAD_REQUEST &&
            error.response.data.error.user_message ===
              serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE
          ) {
            return serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE;
          } else if (error.request.status === serverResponseHelpers.BAD_REQUEST &&
            error.response.data.error.user_message ===
            serverResponseHelpers.TRACKING_NUMBER_ALREADY_IN_USE
          ){
            return serverResponseHelpers.TRACKING_NUMBER_ALREADY_IN_USE;
          } else if (
            error.request.status === serverResponseHelpers.BAD_REQUEST
          ) {
            return serverResponseHelpers.USER_INPUT_ERROR;
          }
          console.log(error);
          return serverResponseHelpers.processServerErrors(error, console.trace());
        });

        return serverReturn;
      },
      async validateTrackingNumeberWithoutType(store: any, payload: any){
        const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "label/type",
          params:{
            trackingNumber: payload.trackingNumber
          }
        })
        .then(response => {
          //console.log(response);
          if (response.data.success && response.data.data.valid) {
            store.commit("setTrackingNumber",payload.trackingNumber);
            store.commit("setIsDocument",response.data.data.sameDayDelivery);

            return true;
          }else if (response.data.success && response.data.data.errorCode){
            let errorMessage = i18n.t("shipment.shipmentForm.qrCodeScanner.error.scanOrInputTrackingNumber");
            switch(response.data.data.errorCode){
              case "ALREADY_IN_USE":
                errorMessage = i18n.t("shipment.shipmentForm.qrCodeScanner.error.alreadyInUse");
              break;
              case "INVALID_NUMBER":
               errorMessage = i18n.t("shipment.shipmentForm.qrCodeScanner.error.invalidLabel");
               break;
            }
            store.commit("dialog/showErrorDialog", {title: i18n.t("shipment.shipmentForm.qrCodeScanner.error.invalidLabel"), msg: errorMessage, closeBlt: i18n.t("dialogs.close") as string}
              , {root: true});
            return false;
            // if(ALREADY_IN_USE, WRONG_TYPE, INVALID_NUMBER ]
          }else{
            return false;

          }
        }).catch(error => {
          if(
            error.request.status === serverResponseHelpers.BAD_REQUEST &&
            error.response.data.error.user_message ===
              serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE
          ){
            return serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE;
          }else if (
            error.request.status === serverResponseHelpers.BAD_REQUEST
          ) {
            return serverResponseHelpers.USER_INPUT_ERROR;
          }
          console.log(error);
          return serverResponseHelpers.processServerErrors(error, console.trace());
        }
      );
        return serverReturn;
      }
    }
  } as Module<any, any>;
}
