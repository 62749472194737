import * as paymentMethod  from "@/constants/PAYMENT_METHOD.ts";

// export const districts: { [key: string]: string } = {
export const DELIVERY_TYPES: any = {"標準快遞": false, "即日配送": true};
export const PAYMENT_METHODS: any = {"寄方支付運費":paymentMethod.PAID_BY_MERCHANT ,"收方到付運費": paymentMethod.PAID_BY_RECEIVER, "收方到付貨款及運費": paymentMethod.CASH_ON_DELIVERY,"寄方付運費，收方到付貨款": paymentMethod.PAID_BY_MERCHANT_COD};
export const PICK_UP_METHODS: any = {"自提點收件": true, "上門送件": false};

// export const dimensionOption = ["大: >60cm", "中: 31-60cm", "小: <30cm"];
// export const weightOption = ["重: >10kg", "中: 5-10kg", "輕: <5kg"];
// export const temperatureOption = [ "室溫", "0-4度", "-18度"];
