





































































import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { MDCDialog } from "@material/dialog";

import CardDisplay from "@/views/credits/CardDisplay.vue";

import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";

@Component({
  components: {
    CardDisplay,
    TheMDCTextFieldOutlined,
    TheMDCSelectOutlined
  }
})
export default class AddCreditsPrompt extends Vue {
  @Ref("add-credit-prompt") readonly addCreditPromptElement!: HTMLElement;
  @Ref("top-up-select") readonly topUpSelect!: TheMDCSelectOutlined;

  get card(): Card | null {
    return this.cardInfo
      ?this.cardInfo
      :this.$store.getters["credits/getCards"][0];
  }
  private thisDialog!: MDCDialog;

  private listener: VuePromptCaller | null = null;
  private cardInfo: Card | null = null;
  private execution: CallBack<void> | null = null;

  mounted() {
    this.thisDialog = new MDCDialog(this.addCreditPromptElement);

    this.thisDialog.listen("MDCDialog:closing", (e: CustomEvent) => {
      if (this.listener && e.detail.action === "accept") {
        this.listener.on(
          e.detail.action,
          { paymentMethodId: this.card!.id, amount: this.getAmount() },
          this.execution
        );
      }
      this.$emit(e.detail.action); // not used, deprecate in the future
      this.reset();
    });
  }

  get text() {
    return {
      title: this.$t("credits.addCreditsPrompt.title"),
      amount: this.$t("credits.addCreditsPrompt.amount"),
      HKD: this.$t("credits.addCreditsPrompt.HKD"),
      poweredBy: this.$t("credits.addCreditsPrompt.poweredBy"),
      jarvixPay: this.$t("credits.addCreditsPrompt.jarvixPay"),
      okay: this.$t("credits.addCreditsPrompt.okay"),
      notOkay: this.$t("credits.addCreditsPrompt.notOkay")
    };
  }

  get priceList(): MenuItem[] {
    return [
      {
        key: 100,
        value: 100,
        expression: "HKD 100"
      },
      {
        key: 500,
        value: 500,
        expression: "HKD 500"
      },
      {
        key: 1000,
        value: 1000,
        expression: "HKD 1000"
      }
    ];
  }

  private getAmount() {
    return this.topUpSelect.getSelectedValue();
  }

  private canTopUp = false;
  private changeCanTopUp(newValue: number, oldValue: number) {
    this.canTopUp = newValue > 4;
  }

  public show(data: AddCreditsPromptData) {
    const { listener, execution, card} = data;
    this.listener = listener || null;
    this.cardInfo = (card?card:
      (this.$store.getters["credits/getCards"][0]));
    this.execution = execution || null;
    this.thisDialog.open();
  }

  // call when prompt need to be hided
  public hide(){
    if(typeof this.thisDialog !== 'undefined' && this.thisDialog.isOpen){
      this.thisDialog.close();
      this.reset();
    }
  }

  private reset() {
    this.listener = null;
    this.cardInfo = null;
    this.execution = null;
  }
}
