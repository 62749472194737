































import { Component, Vue, Prop } from "vue-property-decorator";
import { MDCTextField } from "@material/textfield";

@Component
export default class TheMDCTextArea extends Vue {
  @Prop() label!: string;
  @Prop() reference!: string;
  @Prop() isRequired!: boolean | undefined;
  @Prop() width!: string | undefined;
  @Prop() height!: string | undefined;
  @Prop() isDisabled!: boolean | undefined;
  @Prop() autofocus!: boolean | undefined;
  @Prop() maxlength?: string;
  @Prop() minlength?: string;
  @Prop() showCounter?: boolean;
  private textField!: MDCTextField;

  mounted() {
    this.textField = new MDCTextField(
      this.$refs[this.reference as any] as Element
    );
  }

  getMDC() {
    return this.textField;
  }

  getValue() {
    return this.textField.value;
  }

  setValue(v: string) {
    this.textField.value = v;
  }
}
