export default `<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Terms of Service</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Last updated on: 15th of May, 2020</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint Logistics Technologies Limited (&ldquo;jumppoint&ldquo;, &ldquo;we&ldquo; and &ldquo;us&ldquo;) is the operator of www.jumppoint.io (&ldquo;Website&ldquo;). The Website is designed to provide assistance to users (&ldquo;User(s)&ldquo;, &ldquo;you&ldquo;) in creating and managing shipments (&ldquo;Shipment&ldquo;). This Website and the offer of our Services (defined below) are offered on the sole condition of your acceptance without modification of any and all the terms, conditions, and notices set forth below (Collectively, the &ldquo;Terms of Service&ldquo;). By creating an account on the Website (&ldquo;jumppoint Account&ldquo;) or by using any Services provided by us, you acknowledge that you have read, understood, and agreed to be bound by the Terms of Service and represent that you have read and understood the same.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Terms of Service are accessible at any time on the following page of our Website www.jumppoint.io/legal/terms/overview. You must read, agree with and accept all the terms and conditions contained in the Terms of Service, and our privacy policy contained in www.jumppoint.com/legal/privacy (&ldquo;Privacy Policy&ldquo;) prior to becoming an jumppoint user. If you do not accept any provision in our Terms of Service and/or Privacy Policy, you are not authorized to use this Website or our Services and shall immediately cease using this Website.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Terms of Service represent a legally binding agreement between you (and your client, employer or another entity if you are acting on their behalf) and jumppoint and its direct affiliates or subsidiaries, including the various different entities that jumppoint uses to operate locally in different countries.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. Acceptance of Terms of Service</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.1. If you are entering into these Terms of Service on behalf of a company, business or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these Terms of Service, in which case the Terms of Service &ldquo;you&ldquo; or &ldquo;your&ldquo; shall refer to such entity and its affiliates. If you do not have such authority, or if you do not agree with these Terms of Service, you shall not accept the Terms of Service on behalf of such entity and you are not authorized to use this Website or the Services.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.2. By registering for the Services, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Services&rsquo; registration form. Our collection and processing of registration data and certain other information about you are governed by our Privacy Policy.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.3. You acknowledge and agree that we may change or otherwise modify the Terms of Service in the future, such changes and modifications shall become effective immediately upon posting to the Website. You understand and agree that your continued access or use of this Website after such change or modification constitutes your acceptance of the changed or modified Terms of Service. jumppoint endeavors to provide you with notice of any meaningful changes to the Terms of Service either through email or notification within the jumppoint platform, but jumppoint&rsquo;s failure to do so shall not excuse your obligation to comply with any such modified Terms of Service. You agree to periodically review the most current version of these Terms of Service as posted on the Website.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.4. You agree and acknowledge that you may be subject to additional terms applicable to certain Services provided by us that may be attached to such Services from time to time. All such terms are hereby incorporated by reference into these Terms of Service.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.5. In the event that you commit any breach or violation of any terms and conditions under our Terms of Service, we shall be entitled to immediately terminate our Services, suspend your jumppoint Account, and/or may take other legal action at our disposal.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1.6. The usage of any services not provided by us directly requires you to enter into a separate legally binding service agreement with a third-party service provider. You acknowledge and agree that we do not assume any liability whatsoever for acts, errors, or omissions (including, without limitation, any negligence or willful misconduct) of any third-party service providers with whom you or any of your affiliates may contract, regardless of the purpose.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2. Description of Services</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2.1. For the purpose of these Terms of Service, &ldquo;Services&ldquo; shall include (a) the account services of the Website, (b) all software, data, reports, text, images, sounds, video, and content made available through any of the foregoing (collectively referred to as the &ldquo;Content&ldquo;), and (c) Shipment services. Any new feature added to, or augmenting the Services, is also subject to these Terms of Service.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. Prohibited products</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1. The following are the types of products that, for everyone&rsquo;s safety, you are prohibited to ship through jumppoint:</span></p>
<p><br></p>
<ul style="margin-top:0;margin-bottom:0;">
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Any product related to illegal activities or which is prohibited by law in the place of origin, the destination, or any sites through which such product travel.</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Any product requiring specific licenses to store or distribute. Examples of products with distribution and licensing requirements may include, but are not limited to:</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Live plants and animals;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Alcoholic beverages;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ammunition &amp; firearms;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tobacco;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Firearms and any kind of weapon;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Prescription pharmaceuticals or other dangerous drugs;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Explosive or hazardous products;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Counterfeit items;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Unique or one-of-a-kind materials; and</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Precious metals in the forms of bullion or reserves.</span></p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.2. jumppoint shall not be liable for any loss, damage, destruction of such prohibited product.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.3. In order to maintain the integrity of jumppoint Services and reputation, jumppoint will report any activities pertaining to shipping prohibited items to relevant law enforcement authorities and shipping partners.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. General Conditions/ Access and Use of the Services</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.1. Subject to the terms and conditions of these Terms of Service, you may access and use the Services only for lawful purposes. All rights, titles and interests in and to the Services and its components will remain with and belong exclusively to jumppoint. You shall not (a) sublicense, resell, rent, lease, transfer, assign, time share or otherwise make the Services available to any third party, without written consent of jumppoint; (b) use the Services in any unlawful manner (including without limitation in violation of any data, privacy or export control laws) or in any manner that interferes with or disrupts the integrity or performance of the Services or its components or otherwise violates our Terms of Service, or (c) modify, adapt or hack the Services to, or otherwise attempt to gain unauthorized access to the Services or its related systems or networks. You shall comply with any codes of conduct, policies or other notices jumppoint provides you or publishes in connection with the Services, and you shall promptly notify jumppoint if you learn of a security breach related to the Services.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2. You may not tender for shipment any of the prohibited items listed under the Clause 3.1. You grant jumppoint, and any authorized third parties the right to inspect your Shipment at any time. We reserve the right to reject or suspend the carriage of any item.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.3. You will not use the Services to (or assist another person to):</span></p>
<p><br></p>
<ul style="margin-top:0;margin-bottom:0;">
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">upload any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to upload under any law or under contractual or fiduciary relationships; (iii) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another&rsquo;s privacy, hateful racially, ethnically or otherwise objectionable; or (vi) in the sole judgment of jumppoint, is objectionable or which restricts or inhibits any other person from using or enjoying the Services, or which may expose jumppoint or its users to any harm or liability of any type;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">interfere with or disrupt the Services or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Services; or</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">violate any applicable local, state, national or international law, or any regulations having the force of law;</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity; or</span></p>
    </li>
    <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:normal;">
        <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Services.</span></p>
    </li>
</ul>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.4. You are solely responsible for all data, information, feedback, suggestions, text, content and other materials that you upload, post, deliver, provide or otherwise transmit or store in connection with or relating to the Services (&ldquo;Your Content&ldquo;). jumppoint reserves the right to access your account in order to respond to your requests for technical support. By posting Your Content on or through the Services, you hereby do and shall grant jumppoint a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sub licensable and transferable license to use, modify, reproduce, distribute, display, publish and perform Your Content in connection with the Services and to improve jumppoint&rsquo;s products and Services, subject to the terms and conditions of these Terms of Service and our Privacy Policy. jumppoint has the right, but not the obligation, to monitor the Services, Content, or Your Content and to disclose Your Content if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of jumppoint, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability. You further agree that jumppoint may remove or disable any Content at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content), or for no reason at all.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.5. You understand that the operation of the Services, including Your Content, may be unencrypted and involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices and (c) transmission to jumppoint&rsquo;s third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate and maintain the Services. Accordingly, you acknowledge that you bear sole responsibility for adequate security, protection and backup of Your Content. jumppoint will have no liability to you for any unauthorized access or use of any of Your Content, or any corruption, deletion, destruction or loss of any of Your Content.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.6. You shall be responsible for obtaining and maintaining any equipment and ancillary Services needed to connect to, access or otherwise use the Services, including, without limitation, browsers, modems, hardware, servers, software, operating system, networking, web servers and internet Services (collectively, &ldquo;Equipment&ldquo;). You shall be responsible for ensuring that such Equipment is compatible with the Services and complies with all configurations and specifications set forth in jumppoint&rsquo;s published policies then in effect. You shall also be responsible for maintaining the security of the Equipment, your account, passwords (including, but not limited to, administrative and user passwords) and files, and for all uses of your account or the Equipment with or without your knowledge or consent.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.7. The failure of jumppoint to exercise or enforce any right or provision of these Terms of Service shall not be a waiver of that right. You acknowledge that these Terms of Service of Services form is a contract between you and jumppoint, even though it is electronic and is not physically signed by you and jumppoint, and it governs your use of the Services and takes the place of any prior agreements between you and jumppoint.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.8. Subject to the Terms of Service hereof, jumppoint may (but has no obligation to) provide technical support Services, through email in accordance with our standard practice. jumppoint might use the consignee&rsquo;s personal information to send them updates about their packages, gain consignee feedback, and in general any time jumppoint consider it needed to carry on its Services.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.9. In the event that you wish to change any information regarding the Shipment, such as address, items included or other matters, you shall notify jumppoint customer service at support@jumppoint.io. In the event that the Shipment has already been handled over to any shipping company, courier, or mail service (&ldquo;Courier&ldquo;), such changes may not become effective, or the Courier may ask a fee to validate the change.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.10. In the event that a Shipment is recalled and the Courier does not provide the returned delivery to the sender address, the Shipment will be returned to jumppoint. You may choose to collect the Shipment, at your own expenses, at a designated location provided by jumppoint, or pay a return shipping fee in the amount of HK$ 100 per Shipment, and jumppoint will ship it to your sender address. The Shipment will only be delivered to a local sender address. If you don&rsquo;t collect the Shipment within thirty (30) days from the date the Shipment is returned to jumppoint, jumppoint will ship it to your sender address and deduct the return shipping fee to your account.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.11 Eligibility. To be eligible to use jumppoint Services under this Agreement, you must be at least 18 years old, and have an jumppoint account registered to ship from Hong Kong SAR. If you have an jumppoint account registered in a different country of origin, you will be bound by the relevant Agreement related to that country as found in www.jumppoint.com/legal/terms/overview.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5. Account</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.1. In order to create an account with jumppoint, you agree that you will provide accurate information to the best of your knowledge and belief (&ldquo;Registration Information&ldquo;) and will promptly update such Registration Information as necessary. Access to, and use of, the jumppoint Account is restricted to authorized users only.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.2. You agree not to share your password(s), account information, or account access information to any third party. You are responsible for maintaining the confidentiality of password(s) and account information, and you are responsible for all activities that occur under your password(s) or account(s) or as a result of your access to the account(s). You agree to notify jumppoint immediately of any unauthorized use of your account. jumppoint shall not be liable for any unauthorized use of your account.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5.3. You may invite other users to share your account (&ldquo;Invited Member&ldquo;). However, you shall be responsible for the actions and activities by such Invited Member on your jumppoint Account.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6. Payment of Fees</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.1. Fees</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You can use jumppoint to generate shipping labels using your own shipping courier accounts (&ldquo;Your Courier Account&ldquo; or &ldquo;User Courier Account&ldquo;) or using jumppoint own shipping courier accounts (&ldquo;jumppoint Courier Account&ldquo;).</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In case you generate a shipping label with a jumppoint Courier Account you will be charged the shipping cost, which shall include all costs related to the Shipment, quoted by jumppoint, including, but not limited to, transportation cost, fuel surcharge, import tax, import duty, remote area surcharge, residential surcharges, courier tax handling fees, pick-up fees, and any other amount related to the activity of shipping through jumppoint (&ldquo;Shipping Fees&ldquo;). You acknowledge and agree that the Shipping Fees does not include any additional fees arising out of separate agreements with third-party service providers.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You may purchase an insurance coverage for your Shipments (&ldquo;Insurance Fees&ldquo;) through jumppoint. All rules, terms and policies related to shipping insurance offered through the Site shall be governed by the Insurance Terms and Conditions (https://www.jumppoint.io/legal/insurance-terms-and-conditions).</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint offers various services, some available only by paying a monthly subscription fee (&ldquo;Subscription Fees&ldquo;). In the event you subscribe for a paid monthly plan you agree to pay the corresponding Subscription Fees.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You will pay any fees related to your purchase from jumppoint of any Products or Services, including, but not limited to, Taxes and Duties, Shipping Fees Discrepancies (&ldquo;Additional Fees&ldquo;).</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Together, the Shipping Fees, Insurance Fees, Subscription Fees, and the Additional Fees are referred to as the &ldquo;Fees&ldquo;.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.2. Discrepancies</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quotations for Shipping Fees by jumppoint are based on the information provided by you, including, but not limited to, sender&rsquo;s address, receiver&rsquo;s address, weight, dimensions, and value of the items. In the event a shipping label is generated using a jumppoint Courier Account and there is a discrepancy between the Shipping Fees charged by jumppoint at the moment the User purchases a shipping label and the costs for the Shipment charged by Couriers to jumppoint due to incorrect information that you provided, including, but not limited to, incorrect weight, dimensions, value of the item(s), addresses, declaration of residential address, or any other information critical to estimate Shipping Fees, you will be responsible for such discrepancy amount (&ldquo;Shipping Fees Discrepancies&ldquo; or &ldquo;Adjustments&ldquo;). jumppoint specifically disclaims any liability for any Shipping Fees errors due to inaccurate or incomplete information. jumppoint is not responsible for any changes or alteration of the Shipping Fees. jumppoint agrees to make reasonable efforts to bill such discrepancy amount to your account in a timely manner but jumppoint makes no representations or warranties regarding the amount of time needed to complete processing.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.3. Account Balance</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You must keep at least one valid payment method (&ldquo;Authorized Payment Method&ldquo;) on file with us to pay for all incurred and recurring Fees and to transfer funds to your jumppoint Account (&ldquo;Account Balance&ldquo;). As Fees are incurred, any such amount will be deducted from your Account Balance and charged to your Authorized Payment Method.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Subscription Fees are paid in advance and will be billed monthly (each such date, a &ldquo;Billing Date&ldquo;). You will be charged on each Billing Date for all outstanding Subscription Fees that have not previously been paid. Fees will appear on an invoice, which will be sent to the User via the email provided. As well, an invoice will appear on the billing section of your jumppoint Account.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Shipping Fees, Insurance Fees and Additional Fees are billed at the moment a shipping label is generated, therefore the Services shall be considered as &ldquo;pre-paid&ldquo;. Shipping Fees are subject to change as set out in Section 6.3 herein. In order to access our Services.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Users must maintain a minimum Account Balance in the amount of HK$ 0 in the jumppoint Account at all times. In the event that the Account Balance becomes negative, meaning lower than 0, you authorize jumppoint to instantly charge the due Fees to any Authorized Payment Method, and jumppoint will continue to charge the Authorized Payment Method for applicable Fees until all outstanding Fees have been paid in full.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If we are not able to charge one of your Authorized Payment Methods we may try to charge any of the other Authorized Payment Methods saved on jumppoint. In case we are not able to collect the outstanding Fees we may suspend and revoke access to your Account and the Services. Your Account will be reactivated upon your payment of any outstanding Fees, plus the Fees applicable to your next billing cycle. You may not be able to access your Account during any period of suspension at which point you will be required to contact our support team by sending email to info@jumppoint.io. If the outstanding Fees remain unpaid for ten (10) days following the date of suspension, you agree to deposit funds into your jumppoint Account using another payment method and update the Authorized Payment Method.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.4. Currency fluctuations and exchange rates</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint reserves the right to adjust its pricing in response to currency fluctuations, including, but not limited to, currency conversion rate changes, conversion fee changes, and/or discount rate changes.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint will indicate which currency a transaction is being billed for, but jumppoint is not responsible for any fees or currency exchange rates charged to you by any third party payment provider.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.5. Shipping Fee Disputes</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In the event that you disagree with any Shipping Fees charged to your account, you shall submit such complaint to jumppoint within thirty (30) days of the fee being charged (&ldquo;Dispute Period&ldquo;). jumppoint will not review customer requests for Shipping Fees adjustments that are received after the Dispute Period.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.6. Taxes</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You acknowledge and agree that all fees, charges and any other rates or amounts charged by jumppoint to you hereunder are exclusive of any applicable value added, sales/use or goods and Services&rsquo; taxes (&ldquo;Transaction Taxes&ldquo;) which may be levied in connection with the supply by jumppoint of the Services to you. Where applicable, you shall be responsible to pay all Transaction Taxes arising in respect of the Shipping Fees or other amounts charged by jumppoint to you.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Users who incorrectly declare the commodity type and/or value of their shipment(s) may be charged a penalty of HK$ 1,000 or the difference between the declared value and the transactional value, whichever is higher.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.7. Balance Refund</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Users may request for a refund from jumppoint of any available and unused Account Balance, that will be refunded on the Authorized Payment Method used to deposit the funds on the jumppoint Account, subject to payment of payment fees, or transaction fees, or any other related fees. jumppoint will refund the available and unused Account Balance within ten (60) business days from the time the request by the User is received by jumppoint.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.8. Shipping Fee Refund</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Users may cancel any paid and unused shipping label and request for a refund accordingly prior to a Shipment being processed. jumppoint will refund the Shipping Fee to the User, either to the User Account Balance or to the User Authorized Payment Method, within ten (60) business days from the date the User has requested for such cancellation. In the event that the Shipment has already been shipped and sent over to the courier, such Shipment may not be cancelled and the User shall be billed for the full Shipment Fee and any other related fees thereof. In case a Shipment previously refunded to the User by jumppoint, is shipped by User, jumppoint will bill and charge User for the related Shipment Fees.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6.9. Others</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You are responsible for all applicable Taxes that arise from or as a result of your subscription to or purchase of jumppoint Services. To the extent that jumppoint charges these Taxes, they are calculated using the tax rates that apply based on the billing address you provide to us. If you are not charged Taxes by jumppoint, you are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For the avoidance of doubt, all sums payable by you to jumppoint under these Terms of Service shall be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes charged by jumppoint to you and remitted to the appropriate tax authorities on your behalf, any deductions or withholdings that are required by law shall be borne by you and paid separately to the relevant taxation authority. jumppoint shall be entitled to charge the full amount of Fees stipulated under these Terms of Service to your Authorized Payment Method ignoring any such deduction or withholding that may be required.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. Intellectual Property</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.1. jumppoint Property</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For purposes of this Agreement, &ldquo;jumppoint Property&ldquo; shall mean (a) jumppoint&rsquo;s methodology for the provision of Services; (b) the Developer Tools; and (c) jumppoint&rsquo;s ideas, websites, designs, processes, code, technology, software, copyrights, logos, domain names, patents, trade secrets, trademarks, products and materials. jumppoint hereby retains all worldwide right, title and interest in and to the jumppoint Property. Any rights not expressly granted herein to the jumppoint Property shall be retained by jumppoint. You acknowledge that all right, title and interest to the jumppoint Property is owned by jumppoint.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.2. Additional Restrictions</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other than as permitted herein, you shall not (and you shall not permit others to), directly or indirectly, modify, translate, decompile, disassemble, or reverse engineer any part of the jumppoint Property, or otherwise attempt to discern the functioning or operation of the Website or Services; or copy, rent, lease, distribute, or otherwise transfer any or the rights that you receive hereunder. For clarification purposes, all page headers, custom graphics, button icons, and scripts are service marks, trademarks, and/or trade dress of jumppoint and you shall not copy, imitate, or use them without our express prior written consent. You may use HTML logos provided by jumppoint through our merchant Services, auction tools features or affiliate programs without prior written consent solely for the purpose of directing web traffic to jumppoint.com. You shall not alter, modify or change such HTML logos in any way, use them in a manner that is disparaging or otherwise adverse to jumppoint or the Services, or display them in any manner that implies jumppoint&rsquo;s sponsorship or endorsement. You shall not (and you shall not permit others on your behalf to): (i) use any robot, spider, scraper or other automated means to access the jumppoint Website or Services for any purpose without jumppoint&rsquo;s express written permission, (ii) interfere or attempt to interfere with the proper working of our Website or any activities conducted on the Website, or (iii) bypass any measures jumppoint may use to prevent or restrict access to the jumppoint Website or the Services.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7.3. Client Property</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All materials provided by you under any shall be deemed &ldquo;Client Property&ldquo; for purposes of the Agreement. You hereby grant to jumppoint a non-exclusive license to the Client Property solely as needed to provide the Services. No other licenses, express or implied, under any intellectual property rights are granted by you to jumppoint under these Terms of Service.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. Confidential Information</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.1. Definition</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Each party (&ldquo;Recipient&ldquo;) acknowledges that it may receive Confidential Information as defined herein. For purposes of these Terms of Service and subject to the exclusions set forth below, Confidential Information means any information provided to it by the other party (&ldquo;Discloser&ldquo;) that is marked, labelled or otherwise designated as confidential or proprietary, or that Recipient knew, or should have known, was confidential due to the circumstances surrounding the disclosure.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.2. Exclusion</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Information that is subject to one of the exclusions below shall not be Confidential Information. The exclusions include the following: (a) Non-transactional Confidential Information (as defined below), (b) information publicly known at the time of disclosure, (c) information received by Recipient without restriction from a third party, (d) information published or otherwise made known to the public by Discloser, (e) information that was generated independently without reference to the Discloser&rsquo;s Confidential Information, or (f) information that is required to be disclosed under a court order or pursuant to any applicable governmental rule, regulation or statute, provided that Recipient provide Discloser with prior written notice of such disclosure, (as permitted by law) and the timing for response set forth in the request.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.3. Non-transactional Confidential Information</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other than transaction information absolutely required for jumppoint to provide, or for you to use the Services, jumppoint does not require nor desire any of your proprietary information (&ldquo;Non-transactional Confidential Information&ldquo;). You agree not to provide jumppoint with any Non-transactional Confidential Information including, but not limited to, prototypes of new products, without jumppoint&rsquo;s express prior written consent. In the event that you send such Non-transactional Confidential Information to jumppoint without jumppoint&rsquo;s prior written consent, then jumppoint shall not be obligated to treat such information as Confidential Information.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.4. Standard of Care</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Recipient shall not use the Confidential Information for any purpose other than as required by these Terms of Service. Recipient shall not disclose the Confidential Information to any third party, other than as required to perform the Services. Recipient shall use at least the same standard of care with the Discloser&rsquo;s Confidential Information as it does with its own Confidential Information, but in no event with less than reasonable care. Each party acknowledges that breach of this provision would result in irreparable harm to the other party, for which money damages would be an insufficient remedy, and therefore that the other party will be entitled to seek injunctive relief to enforce the provisions of this Section.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.5. Return or Destruction</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Other than transactional information that is retained in the ordinary course of jumppoint&rsquo;s business, each party shall either promptly return all Confidential Information, or confirm that such Confidential Information has been destroyed promptly after receipt of written request from the other party.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.6. Aggregate Use</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Subject to the Terms of Service herein, you hereby acknowledge and agree that jumppoint may compile aggregate results from all of, or a selection of your use of the Services, provided that jumppoint shall not disclose any information that would individually identify you (&ldquo;Aggregate Information&ldquo;). Such Aggregate Information shall be deemed to be jumppoint&rsquo;s Confidential Information. You also hereby agree that jumppoint may review and use your individual use of the Services in order to provide Services to you, to evaluate jumppoint&rsquo;s provision of the Services, and to improve jumppoint&rsquo;s Services offerings.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8.7. Privacy Policy</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint believes in protecting your privacy. Any information you provide to us or input on the Website as set out above will be used in accordance with our privacy policy found in this page ( www.jumppoint.com/legal/privacy) (&ldquo;Privacy Policy&ldquo;). We do not sell, rent, or otherwise disclose your Confidential Information to a third party, without your consent or as permitted by the Privacy Policy.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9. Indemnification</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You agree to indemnify and to hold harmless jumppoint, our officers, directors, employees and agents from and against any and all claims, liabilities, damages, losses or expenses, including reasonable attorneys&rsquo; fees and costs, due to or arising out of your use of the Services and/or your violation of the Terms of Service. Upon requesting for our Service, you agree to indemnify and hold harmless jumppoint from any and all claims for transportation, storage, handling and other charges relating to the goods in respect of the Shipment, including undercharges, rail demurrage, truck/intermodal detention and other charges of any nature, whether or not jumppoint accepts such order.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10. Disclaimers</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.1. As Is</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint endeavors to provide the Services in accordance with these Terms of Service. Users acknowledge that the Services and the Website are provided on an &ldquo;as is&ldquo; and &ldquo;as available&ldquo; basis. jumppoint and jumppoint&rsquo;s suppliers expressly disclaim all warranties of any kind, whether expressed or implied, including, but not limited to, the implied warranty of merchantability, fitness for a particular purpose and non-infringement for the services, the Website and any third party services. The use of the Services, Website, or third party services shall be at your own risk.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.2. No Continuous Access</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint does not guarantee continuous, uninterrupted or secure access to the Services. Operation of the Services may be interfered with by numerous factors outside of our control. jumppoint will make reasonable efforts to process requests for receiving or shipping merchandise in a timely manner but jumppoint makes no representations or warranties regarding the amount of time needed to complete processing because our Services are dependent upon many factors outside of our control, such as delays caused by third parties. jumppoint is not liable for any direct, indirect, incidental damage, loss of profit, goodwill, use, or data resulting from our inability to provide the Service. We reserve the sole right to discontinue the Website and our Services, including any of the Website&rsquo;s features, at any time with or without notice to you.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10.3. Others</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You understand that jumppoint does not inspect your goods nor does jumppoint take any responsibility for the business decisions that you make and implement through the Services. You acknowledge that jumppoint is not the Merchant of Record, nor the Importer of Record, and nor the owner of any product shipped by you through jumppoint.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11. Limitation of Liability</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.1. Third-Party Liability</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By accepting the Terms of Service, you acknowledge and agree that we do not assume any liability whatsoever for the acts, errors, or omissions (including, without limitation, any negligence or willful misconduct) of any third-party service providers with whom you or any of your affiliates may contract, regardless of the purpose.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.2. Waiver of Consequential Damages and Limitation of Liability</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In no event shall jumppoint be liable for any indirect, incidental, special or consequential damages, or damages for loss or damage of Shipments, loss of profits, revenue, data or use, incurred by you or any third party, whether in an action in contract or tort, arising from your access to, or use of, the Website, any content, or any third party websites and content. Other than as set forth below, in no event shall jumppoint&rsquo;s liability under this agreement exceed the monies paid or payable by you to jumppoint for the applicable services excluding carrier fees or other third party fees (&ldquo;damages cap&ldquo;). In the event of an unauthorized transaction by an jumppoint employee or agent, jumppoint is only liable for the damages cap. jumppoint must be notified within five (5) days after any unauthorized transaction or you acknowledge to waive all damages from jumppoint.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.3. Declaration and Accurate Information</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You are responsible to provide complete and accurate information in respect of the Shipment, including, but not limited to, the item category and item value of the Shipment. You shall be responsible for any error, damage, expense or consequence resulting from wrong or incomplete information provided to us. You shall further indemnify jumppoint for and hold jumppoint harmless from any loss or expense resulting as a consequence of the wrong information provided by you. jumppoint shall not be responsible for losses or damages in the event that any item contained in the Shipment is wrongfully declared. Moreover, jumppoint is in no way responsible for any damages, losses due to custom practice. jumppoint shall not be liable for the accuracy and truthfulness of the information provided by you.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.4. Import Taxes and Duties</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When you generate a Shipment using a jumppoint Courier Account shipping label, you have the option of choosing between a DDU Shipment or a DDP Shipment. In DDU Shipments, you choose not to pay in advance for any taxes and duties in respect of the Shipment arising in the destination country, of which the receiver will be billed accordingly. By using DDU Shipment, Users acknowledge that any local customs charges, import taxes, import duties, last-mile delivery fee, or in general any extra charge(s) regarding the Shipment (&ldquo;Taxes and Duties&ldquo;), will be of the receiver&rsquo;s sole responsibility. jumppoint shall not be liable for any discrepancies between the Shipping Fees by jumppoint and any local fee, or additional delivery fee charged to the receiver.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In the event that you generate a Shipment using a jumppoint Courier Account, and wish to avoid the receiver for being charged any additional fee, you may use DDP Shipment, in which you agree to pre-pay the Taxes and Duties in respect of the receiving country at the time you purchase the shipping labels.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint will provide you an estimate of the Taxes and Duties prior to each Shipment for your reference (&ldquo;Estimate&ldquo;). For DDU Shipments, such Estimate shall be for reference only and the Shipment may be subject to other additional charges depending on the local custom practices of the destination country. For DDP Shipments generated using a jumppoint Courier Account, jumppoint guarantees that such Estimate shall be binding and any Taxes and Duties payable by the User shall not exceed the Estimate provided by jumppoint. The Users shall not be liable for any discrepancy between the Shipping Fees by jumppoint and any local fee, or additional delivery fee provided that all information in respect of the Shipment submitted to jumppoint by the User is complete and accurate. jumppoint reserves the right to charge a User using DDP Shipments additional fees in respect of excessive Taxes and Duties resulting from the incomplete and/or inaccurate information provided by the User used to compute the Estimate.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In the event a shipment is generated using a User Courier Account, jumppoint is not responsible for any taxes and duties, neither for DDU Shipments nor for DDP Shipments.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.5. Customs</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint shall not be liable for any loss, delay, or damage of items shipped, while in the hands of a local custom. In addition, jumppoint shall not be held responsible in the event that the local custom confiscates, holds, or denies release of the Shipment to the receiver.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.6. Service provided by Courier</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint is a technology platform that connects shippers and couriers. In no circumstances will jumppoint be liable for any Services or information provided by any Courier, neither in the case of shipments generated using a jumppoint Courier Accounts nor a User Courier accounts. Under no circumstances jumppoint will be held responsible or liable for any damage, destruction, loss, late delivery of Shipment, failures in handling in shipments to couriers, failed or late shipment collection by Courier, or any issues with tracking updates, for any Courier, also jumppoint is not liable for the acts, errors, or omissions (including, without limitation, any negligence or willful misconduct) of any third-party service providers (including, without limitation, any delay, wrong, or missed pickup by the Courier). jumppoint is not liable for the delivery time information shown on the Website.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">For User Courier Accounts, jumppoint is not responsible for the accuracy or validity of any Fees, and the User is responsible to deliver the shipments to the Courier.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Further to the above, jumppoint connects warehouse service providers with potential clients. You acknowledge and agree that we do not assume any liability whatsoever for the acts, errors, or omissions (including, without limitation, any negligence or willful misconduct) of any third-party service providers with whom you or any of your affiliates may contract, regardless of the purpose.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.7. Other</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint is not liable for any fees, expenses, resulting as a consequence of a change of information from your side (for example a change of address, order cancellation, or order call-back), after a Shipment has been confirmed and paid for on jumppoint. jumppoint is not liable for any delay in the generation of shipping documents.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11.8. User Courier Account</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">User is responsible to provide accurate and truthful information for the User Courier Account that the user wishes to use with jumppoint. The User also acknowledge and certify to be the rightful owner of the User Courier Account. jumppoint is not liable nor responsible for failures in connecting a User Courier Account, failures in generating shipping labels or any other shipping documents, inaccuracies in tracking information, discrepancies between any shipping costs or fees shown by jumppoint and billed by Courier, generating any inaccurate Fees, or any other fees billed by Courier to the owner of the User Courier Accounts, or displayed on jumppoint.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12. Termination</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You have the right to terminate your account at any time by sending a cancellation request to support@jumppoint.io. Subject to earlier termination as provided below, jumppoint may terminate your account and these Terms of Service at any time by providing thirty (30) days prior notice to the administrative email address associated with your account. In addition to any other remedies we may have, jumppoint may also terminate these Terms of Service upon thirty (30) days&rsquo; notice (or ten (10) days in the case of non-payment), if you breach any of the Terms of Service or conditions of these Terms of Service. Also, jumppoint reserves the right to modify or discontinue, temporarily or permanently, the Services (or any part thereof). Except as provided above, upon any termination of your account, jumppoint may store all of Your Content on the Services (if any), or it may be permanently deleted by jumppoint, in its sole discretion. jumppoint will reimburse the balance within ninety (90) Days.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13. Disputes</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Please note, while disagreements sometimes arise, jumppoint wants to address any complaint with the Services in a proactive manner. Please log a support case for resolution, you can send an email to support@jumppoint.io. If you feel an escalation is merited, please email support@jumppoint.io regarding your unresolved case and your concern. For purposes of clarity, this does not waive the notice requirement set forth below.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">14. Governing Law and Venue</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">These Terms of Service shall be governed by the laws of the Hong Kong Special Administrative Region of the People&rsquo;s Republic of China. By using our Services, you agree to submit to the exclusive jurisdiction of the Hong Kong Court.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15. General Provisions</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15.1. Severability</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If any provision of these Terms of Service is held invalid or unenforceable by a court of competent jurisdiction, such provision shall be modified to the extent necessary to make such provision valid and enforceable and the remaining provisions of these Terms of Service shall remain in effect and enforceable in accordance with their Terms of Service.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15.2. No Waiver</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Failure or delay of jumppoint to exercise a right or power under these Terms of Service shall not operate as a waiver thereof, nor shall any single or partial exercise of a right or power preclude any other future exercise thereof.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15.3. Notices to You</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You agree that jumppoint may provide notice to you by posting it on our Website, emailing it to the email address listed in your account, showing a notification when you login to your account, or mailing it to the street address listed in your account. Such notice shall be considered to be received, read and understood by you within 24 hours of the time it is posted to our Website or emailed to the email address listed in your account. If the notice is sent by mail, we will consider it to have been read and understood by you within 24 hours of the time it is delivered. jumppoint reserves the right to terminate your account if you withdraw your consent to receive electronic communications.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15.4. Assignment</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You may not transfer or assign any rights or obligations you have under this Agreement without jumppoint&rsquo;s prior written consent and any attempted assignment without that consent will be void. jumppoint reserves the right to transfer or assign this Agreement or any right or obligation under this Agreement at any time. Subject to the foregoing provisions of this Section, these Terms of Service shall be binding on and inure to the benefit of the parties&rsquo; successors and assigns.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15.5. Attorneys&rsquo; Fees and Costs</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint shall have the right to collect from you its reasonable costs and necessary disbursements and attorneys&rsquo; fees incurred in enforcing these Terms of Service.</span></p>
<p><br></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">15.6. Force Majeure</span></p>
<p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">jumppoint shall not be liable for any delays or inability to perform the Services caused by forces beyond jumppoint&rsquo;s control including, without limitation, acts of God or acts of third party Service providers, including, but not limited to, carriers and postage systems. When goods are ordered out, in the case of acts of God, war, terrorism, public enemies, seizure under legal process, strikes, lockouts, riots and civil commotions, or any reason beyond jumppoint&rsquo;s control, or because of loss or destruction of goods for which jumppoint is not liable, or because of any other excuse provided by law, jumppoint shall not be liable for failure to carry out such instructions.</span></p>
<p><br></p>
<p><br></p>`;
