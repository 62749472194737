import store from "@/store";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

/**
 * get all the saved address and items saved from backend to vuex
 */
export async function fetchCompanySavedInfo(): Promise<boolean> {
  if (await store.dispatch("historialInput/expressOrder/fetchCompanySavedInfo") === true && await store.dispatch("historialInput/otherCourier/fetchCompanySavedInfo") === true) {
    return true;
  }
  return false;
}

/**
 * get user info and company info from backend and save to vuex
 */
export async function fetchUserLoginInfo(): Promise<boolean> {
  const fetchUser = await store.dispatch("fetchUserInfo");
  const fetchCompany = await store.dispatch("fetchCompanyInfo");
  if (fetchUser === true && fetchCompany === true) {
    store.commit("_login");
    return true;
  } else {
    return false;
  }
}

/**
 * get shipping form info from session storage
 */
export function fetchShippingInfo(): boolean {
  const IS_SUBPRIME = "state.shipment.isSubprime";
  const IS_DOCUMENT = "state.shipment.isDocument";
  const SENDER_ADDRESS = "state.shipment.senderAddress";
  const RECEIVER_ADDRESS = "state.shipment.receiverAddress";
  const NO_OF_ITEMS = "state.shipment.noOfItems";
  const ITEMS = "state.shipment.items";
  const IS_INSURED = "state.shipment.isInsured";
  const TAXES_DUTIES_PAID_BY = "state.shipment.taxesDutiesPaidBy";
  const PAYMENT_METHOD = "state.shipment.paymentMethod";
  const CASH_ON_DELIVERY_AMOUNT = "state.shipment.cashOnDeliveryAmount";
  const ORDER_NOTES = "state.shipment.orderNotes";
  const DELIVERY_DATE = "state.shipment.deliveryDate";
  const PICKUP_DATE = "state.shipment.pickUpDate";
  const TRACKING_NUMBER = "state.shipment.trackingNumber";
  const EXPRESS_ORDER_ITEM = "state.shipment.expressOrderItem";
  const PICK_UP_STORE_ID = "state.shipment.pickUpStoreId";
  const PICK_UP_NAME = "state.shipment.pickUpName";
  const PICK_UP_PHONE = "state.shipment.pickUpPhone";

  if (Storage.hasKey(IS_SUBPRIME)) {
    store.commit("shipment/setIsSubprime", Storage.loadFromSD(IS_SUBPRIME));
  }
  if (Storage.hasKey(IS_DOCUMENT)) {
    store.commit("shipment/setIsDocument", Storage.loadFromSD(IS_DOCUMENT));
  }

  if (Storage.hasKey(SENDER_ADDRESS)) {
    store.commit(
      "shipment/saveShipmentSenderAddress",
      Storage.loadFromSD(SENDER_ADDRESS)
    );
  }
  if (Storage.hasKey(RECEIVER_ADDRESS)) {
    store.commit(
      "shipment/saveShipmentReceiverAddress",
      Storage.loadFromSD(RECEIVER_ADDRESS)
    );
  }
  if (Storage.hasKey(NO_OF_ITEMS)) {
    store.commit("shipment/setNoOfItems", Storage.loadFromSD(NO_OF_ITEMS));
  }
  if (Storage.hasKey(ITEMS)) {
    store.commit("shipment/_saveShipmentItems", Storage.loadFromSD(ITEMS));
  }
  if (Storage.hasKey(IS_INSURED)) {
    store.commit("shipment/setIsInsured", Storage.loadFromSD(IS_INSURED));
  }
  if (Storage.hasKey(TAXES_DUTIES_PAID_BY)) {
    store.commit(
      "shipment/setTaxesDutiesPaidBy",
      Storage.loadFromSD(TAXES_DUTIES_PAID_BY)
    );
  }
  if (Storage.hasKey(PAYMENT_METHOD)) {
    store.commit("shipment/setPaymentMethod", Storage.loadFromSD(PAYMENT_METHOD));
  }
  if (Storage.hasKey(CASH_ON_DELIVERY_AMOUNT)) {
    store.commit("shipment/setCashOnDeliveryAmount", Storage.loadFromSD(CASH_ON_DELIVERY_AMOUNT));
  }
  if (Storage.hasKey(ORDER_NOTES)) {
    store.commit("shipment/setOrderNotes", Storage.loadFromSD(ORDER_NOTES));
  }
  if(Storage.hasKey(DELIVERY_DATE)) {
    store.commit("shipment/setDeliveryDate", Storage.loadFromSD(DELIVERY_DATE));
  }
  if(Storage.hasKey(PICKUP_DATE)) {
    store.commit("shipment/setPickUpDate", Storage.loadFromSD(PICKUP_DATE));
  }
  if(Storage.hasKey(TRACKING_NUMBER)) {
    store.commit("shipment/setTrackingNumber", Storage.loadFromSD(TRACKING_NUMBER));
  }
  if(Storage.hasKey(EXPRESS_ORDER_ITEM)){
    store.commit("shipment/setExpressOrderItem", Storage.loadFromSD(EXPRESS_ORDER_ITEM));
  }
  if(Storage.hasKey(EXPRESS_ORDER_ITEM)){
    store.commit("shipment/setExpressOrderItem", Storage.loadFromSD(EXPRESS_ORDER_ITEM));
  }
  if(Storage.hasKey(PICK_UP_STORE_ID)){
    store.commit("shipment/setPickUpStoreId", Storage.loadFromSD(PICK_UP_STORE_ID));
  }
  if(Storage.hasKey(PICK_UP_NAME)){
    store.commit("shipment/setPickUpName", Storage.loadFromSD(PICK_UP_NAME));
  }
  if(Storage.hasKey(PICK_UP_PHONE)){
    store.commit("shipment/setPickUpPhone", Storage.loadFromSD(PICK_UP_PHONE));
  }

  // true if has anything
  return (
    Storage.hasKey(SENDER_ADDRESS) ||
    Storage.hasKey(RECEIVER_ADDRESS) ||
    Storage.hasKey(ITEMS) ||
    Storage.hasKey(IS_INSURED) ||
    Storage.hasKey(TAXES_DUTIES_PAID_BY) ||
    Storage.hasKey(IS_SUBPRIME) ||
    Storage.hasKey(PAYMENT_METHOD) ||
    Storage.hasKey(CASH_ON_DELIVERY_AMOUNT) ||
    Storage.hasKey(ORDER_NOTES) ||
    Storage.hasKey(DELIVERY_DATE) ||   
    Storage.hasKey(PICKUP_DATE) || 
    Storage.hasKey(EXPRESS_ORDER_ITEM) ||
    Storage.hasKey(PICK_UP_STORE_ID) ||
    Storage.hasKey(PICK_UP_NAME) ||
    Storage.hasKey(PICK_UP_PHONE)
    );
}

/**
 * get tracking info from session storage
 */
export function fetchTracking(): boolean {
  const TYPE = "state.trackingRecordFilter.orderType";
  const CRITERIA = "state.trackingRecordFilter.criteria";
  const KEYWORD = "state.trackingRecordFilter.keyword";

  if (Storage.hasKey(TYPE)) {
    // store.commit("tracking/setTrackingRecordOrderType", Storage.loadFromSD(TYPE));
    store.commit("tracking/setTrackingRecordOrderType", "express");
  }

  if (Storage.hasKey(CRITERIA)) {
    //console.log("fetchtracking >> ",Storage.loadFromSD(CRITERIA) )
    store.commit("tracking/setTrackingRecordFilterCriteria", Storage.loadFromSD(CRITERIA));
  }

  if (Storage.hasKey(KEYWORD)) {
    store.commit("tracking/setTrackingRecordFilterKeyword", Storage.loadFromSD(KEYWORD));
    //console.log("fetchTracking",store.state.tracking.trackingRecordFilter);
  }


  return Storage.hasKey(TYPE) || Storage.hasKey(CRITERIA) || Storage.hasKey(KEYWORD);
}


/**
 * get rate info from session storage
 */
export function fetchShippingRates(): boolean {
  const RATES = "state.shipment.rates";
  const SELECTED_RATE = "state.shipment.selectedRate";

  if (Storage.hasKey(RATES)) {
    store.commit("shipment/addRateOffers", Storage.loadFromSD(RATES));
  }

  if (Storage.hasKey(SELECTED_RATE)) {
    store.commit("shipment/selectRate", Storage.loadFromSD(SELECTED_RATE));
  }

  return Storage.hasKey(RATES) || Storage.hasKey(SELECTED_RATE);
}

export function fetchShippingPickup(): boolean {
  const PICKUP_OPTION = "state.shipment.selectedPickupOption";
  const PICKUP_DATE = "state.shipment.selectedPickupDate";
  const PICKUP_TIME = "state.shipment.selectedPickupTime";

  if (Storage.hasKey(PICKUP_OPTION)) {
    store.commit(
      "shipment/selectPickupOption",
      Storage.loadFromSD(PICKUP_OPTION)
    );
  }

  if (Storage.hasKey(PICKUP_DATE)) {
    store.commit("shipment/selectPickupDate", Storage.loadFromSD(PICKUP_DATE));
  }

  if (Storage.hasKey(PICKUP_TIME)) {
    store.commit("shipment/selectPickupTime", Storage.loadFromSD(PICKUP_TIME));
  }

  return (
    Storage.hasKey(PICKUP_OPTION) ||
    Storage.hasKey(PICKUP_DATE) ||
    Storage.hasKey(PICKUP_TIME)
  );
}

export async function fetchExpressOrderAvailableDate(): Promise<boolean> {
  if(await store.dispatch("date/fetchExpressOrderAvailableDate") !== true){
    //TODO: handling error for can't found available date
    store.commit("showConnectionError");
    return false;
  }
  return true;
}

export async function fetchDistrictAreas(): Promise<boolean> {
  if(Storage.hasKey("state.address.districts") && Storage.hasKey("state.address.areas")){
    store.commit("address/setDistricts", Storage.loadFromSD("state.address.districts"));
    store.commit("address/setAreas", Storage.loadFromSD("state.address.areas"));
    return true;
  }else{
    return await store.dispatch("address/fetchDistrictAreas")
    //TODO: handling error for can't found available date
    // store.commit("showConnectionError");
  }
}

export async function fetchExpressOrderOptions(): Promise<boolean> {
  if(Storage.hasKey("state.expressOrderItemOption.sameDayCategories") 
    && Storage.hasKey("state.expressOrderItemOption.nonSameDayCategories") 
    && Storage.hasKey("state.expressOrderItemOption.sameDayDimensions")
    && Storage.hasKey("state.expressOrderItemOption.nonSameDayDimensions")
    && Storage.hasKey("state.expressOrderItemOption.weights")
    && Storage.hasKey("state.expressOrderItemOption.temperatures")){

    store.commit("itemOption/expressOrder/setSameDayCategories", Storage.loadFromSD("state.expressOrderItemOption.sameDayCategories"));
    store.commit("itemOption/expressOrder/setNonSameDayCategories", Storage.loadFromSD("state.expressOrderItemOption.nonSameDayCategories"));
    store.commit("itemOption/expressOrder/setSameDayDimensions", Storage.loadFromSD("state.expressOrderItemOption.sameDayDimensions"));
    store.commit("itemOption/expressOrder/setNonSameDayDimensions", Storage.loadFromSD("state.expressOrderItemOption.nonSameDayDimensions"));
    store.commit("itemOption/expressOrder/setWeights", Storage.loadFromSD("state.expressOrderItemOption.weights"));
    store.commit("itemOption/expressOrder/setTemperatures", Storage.loadFromSD("state.expressOrderItemOption.temperatures"));
    return true;
  }else{
    return await store.dispatch("itemOption/expressOrder/fetchExpressOrderOptions");
    //TODO: handling error for can't found available date
    // store.commit("showConnectionError");
  }
  return true;
}

// Current other courier option is saved sa constant, no need to fetch
export async function fetchOtherCourierOptions(): Promise<boolean> {

  return true;
}


export async function fetchPickUpStores(){
  return await store.dispatch("pickUpStore/fetchPickUpStores");
}



// Tracking

export async function fetchShipmentList(): Promise<boolean> {
  const trackingOrerType = store.getters["tracking/getTrackingOrderType"];
  if (trackingOrerType == "shipment") {
    if (await store.dispatch("tracking/retrieveShipmentList") === true) {
      return true;
    }
  } else if (trackingOrerType == "express") {
    if (await store.dispatch("tracking/retrieveExpressIntros")) {
      return true;
    }
  }
  return false;
}

export async function fetchShipmentRecordbyId(
  shipmentId: string
): Promise<boolean> {
  const trackingOrerType = store.getters["tracking/getTrackingOrderType"];

  if (trackingOrerType == "shipment") {
    const apiShipmentRecord = await store.dispatch(
      "tracking/retrieveShipmentRecordById",
      shipmentId
    );
    return true;
  } else if (trackingOrerType == "express") {
    const apiExpressRecord = await store.dispatch(
      "tracking/retrieveExpressRecordById",
      shipmentId
    );
    return true;
  }
  return false;
}
//Deprecated
export async function fetchShipmentListWithFilter(
  filterChange: "keyword" | "type",
  newValue: string
): Promise<any> {
  const trackingOrerType = store.getters["tracking/getTrackingOrderType"];
  switch (filterChange) {
    case "keyword":
      store.commit("tracking/setTrackingRecordFilterKeyword", newValue);
      break;
    case "type":
      store.commit("tracking/setTrackingRecordFilterType", newValue);
      break;
    default:
      //TODOs: error prompt invalid search type
      store.commit("showConnectionError");
      return false;
  }
  if (trackingOrerType == "shipment") {
    return await store.dispatch("tracking/retrieveShipmentList");
  } else if (trackingOrerType == "express") {
    return await store.dispatch("tracking/retrieveExpressIntros");
  } else {
    return false;
  }
}

export async function fetchLabelUrl(
  shipmentId: string,
  shipmentType: "otherCourier" | "express",
  labelType?: "expressOrder" | "expressOrderItem"
): Promise<string> {
  if(shipmentType == 'otherCourier'){
    return await store.dispatch(
      "tracking/fetchOtherCourierLabelUrl",
      shipmentId
    );
  }else if (labelType && labelType == 'expressOrderItem') {
    return await store.dispatch(
      "tracking/fetchExpressOrderItemLabelUrl",
      shipmentId
    );
  }else {
    return await store.dispatch(
      "tracking/fetchExpressOrderLabelUrl",
      shipmentId
    );
  }
  
}


//Tracking/express

// export async function fetchExpressRecordbyId(expressId: string): Promise<boolean> {
//     if (await store.dispatch("tracking/retrieveExpressRecordById", expressId)) {
//         return true;
//     }
//     return false;
// }

// export async function fetchExpressIntrosWithFilter
//     (
//         filterChange: "keyword" | "type",
//         newValue: string
//     ): Promise<any> {

//     switch (filterChange) {
//         case "keyword":
//             store.commit('tracking/setTrackingRecordFilterKeyword', newValue);
//             break;
//         case "type":
//             store.commit('tracking/setTrackingRecordFilterType', newValue);
//             break;
//         default:
//             //TODOs: error prompt invalid search type
//             store.commit('showConnectionError');
//             // console.log('invalidFilterType');
//             return false;
//     }
//     return await store.dispatch("tracking/retrieveTrackingList")
// }
// Credits
export async function fetchCardInfo(): Promise<any> {
  await store.dispatch("credits/getCards");
  const HAS_CREATED_CARD = "state.credits.createdCard";
  const CHARGE_ID = "state.credits.chargeId";
  if (Storage.hasKey(HAS_CREATED_CARD)) {
    const hasCreatedCard = Storage.loadFromSD(HAS_CREATED_CARD);
    if (hasCreatedCard) {
      store.commit("credits/createCard");
    } else {
      store.commit("credits/creteCardFail");
    }
  }
  if (Storage.hasKey(CHARGE_ID)) {
    store.commit("credits/addChargeId", Storage.loadFromSD(CHARGE_ID));
  }
  return Storage.hasKey(HAS_CREATED_CARD) || Storage.hasKey(CHARGE_ID);
}

export async function fetchBulkImportRecords(): Promise<boolean>  {
  return await store.dispatch("bulkImport/fetchBulkImportRecords");
}                                        

