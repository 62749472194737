var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "add-credit-prompt", staticClass: "mdc-dialog" }, [
    _c("div", { staticClass: "mdc-dialog__container" }, [
      _c(
        "div",
        {
          staticClass: "mdc-dialog__surface",
          attrs: {
            role: "alertdialog",
            "aria-modal": "true",
            "aria-labelledby": "add-credit-title",
            "aria-describedby": "add-credit-content"
          }
        },
        [
          _c(
            "h2",
            {
              staticClass: "mdc-dialog__title",
              attrs: { id: "add-credit-title" }
            },
            [_vm._v("\n        " + _vm._s(_vm.text.title) + "\n        ")]
          ),
          _c(
            "div",
            {
              staticClass: "mdc-dialog__content",
              attrs: { id: "add-credit-content" }
            },
            [
              _c(
                "div",
                { staticClass: "card__container" },
                [_c("CardDisplay", { attrs: { card: _vm.card } })],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "input__container",
                  staticStyle: { width: "100%" }
                },
                [
                  _c("TheMDCSelectOutlined", {
                    ref: "top-up-select",
                    attrs: {
                      label: _vm.text.amount,
                      reference: "top-up-select",
                      event: "watch-value",
                      menuList: _vm.priceList
                    },
                    on: { "watch-value": _vm.changeCanTopUp }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "powered-by__container" }, [
                _c("span", { staticClass: "tag" }, [
                  _c("span", [_vm._v(_vm._s(_vm.text.poweredBy))]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "0.75rem"
                      }
                    },
                    [_vm._v("\n              " + _vm._s(_vm.text.jarvixPay))]
                  )
                ])
              ])
            ]
          ),
          _c("div", { staticClass: "mdc-dialog__actions" }, [
            _c(
              "button",
              {
                staticClass: "mdc-button mdc-dialog__button",
                attrs: { type: "button", "data-mdc-dialog-action": "close" }
              },
              [
                _c("div", { staticClass: "mdc-button__ripple" }),
                _c("span", { staticClass: "mdc-button__label" }, [
                  _vm._v(_vm._s(_vm.text.notOkay))
                ])
              ]
            ),
            _c(
              "button",
              {
                staticClass: "mdc-button mdc-dialog__button",
                attrs: {
                  type: "button",
                  "data-mdc-dialog-action": "accept",
                  disabled: !_vm.canTopUp
                }
              },
              [
                _c("div", { staticClass: "mdc-button__ripple" }),
                _c("span", { staticClass: "mdc-button__label" }, [
                  _vm._v(_vm._s(_vm.text.okay))
                ])
              ]
            )
          ])
        ]
      )
    ]),
    _c("div", { staticClass: "mdc-dialog__scrim" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }