var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", {
    staticClass: "bottom-nav__action nav-bar-item",
    staticStyle: { display: "block" },
    attrs: {
      to: { name: _vm.params.to.name, params: { locale: this.$i18n.locale } },
      "active-class": "bottm-nav__action--active"
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var navigate = ref.navigate
          return [
            _c(
              "div",
              {
                ref: _vm.params.label,
                staticClass: "my-surface mdc-ripple-surface",
                attrs: { role: "link" },
                on: { click: navigate }
              },
              [
                _c("span", { staticClass: "in-ripple__container" }, [
                  _c(
                    "i",
                    {
                      staticClass:
                        "material-icons mdc-top-app-bar__action-item",
                      staticStyle: {
                        display: "block",
                        padding: "0",
                        height: "min-content"
                      }
                    },
                    [_vm._v(_vm._s(_vm.params.icon))]
                  ),
                  _c("span", { staticClass: "bottom-nav__label" }, [
                    _vm._v(_vm._s(_vm.params.label))
                  ])
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }