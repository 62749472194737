import { AxiosInstance } from "axios";
import { Module } from "vuex";
import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { saveFileTODevice } from "@/utils/saveFileToDevice";
import DateFormatHelpers from "@/utils/dateFormatHelpers";

export function bulkImportModule(axiosInstance: AxiosInstance) {
  return {
    namespaced: true,
    state: {
      bulkImportRecords: [] as BulkImportRecords[]
    },
    getters: {
      getBulkImportRecords(state: any){
        return state.bulkImportRecords;
      }
    },
    mutations: {
      setBulkImportRecords(state: any, bulkImportRecords: BulkImportRecords[]){
        state.bulkImportRecords = bulkImportRecords || [];
      }
    },
    actions: {
      async bulkImportOrders(store, orders: BulkImportRecords[]) {
        if (orders.length <= 0) {
          return false;
        }
        // code to download xlxs from backend
        const serverReturn = await axiosInstance
          .request({
            method: "POST",
            url: "express-order/bulk-import",
            data: {expressOrders: orders}
          })
          .then(response => {
            // //console.log(response);
            if (response.data.success === true) { 
              return response.data;
            }else{
              return response.data;        
            }
          })
          .catch(error => {
            console.log(error);
            return error!.response.data;
            // return serverResponseHelpers.processServerErrors(error, console.trace());
          });
        return serverReturn;
      },
      async fetchBulkImportRecords(store){
        const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "express-order/bulk-import/history"
        })
        .then(response => {
          if (response.data.success && response.data.data.bulkImportLogs) {
            store.commit("setBulkImportRecords",response.data.data.bulkImportLogs);
            return true;
          }else{
            return false;
          }
        }).catch(error => {
          store.commit("hideLoading", null, {root: true});
         
          return serverResponseHelpers.processServerErrors(error, console.trace());
        });
        return serverReturn;
      },
      async downloadBulkImportRecordFile(store, payload){
        const serverReturn = await axiosInstance
        .request({
          method: "GET",
          url: "express-order/bulk-import/file",
          params: payload
        })
        .then(response => {
          // //console.log(response);
          if (response.data.success && response.data.data.url) {
            const s3Url = response.data.data.url;
            saveFileTODevice(s3Url);
            return true;
          }else{
          return false;

          }
        }).catch(error => {

          return serverResponseHelpers.processServerErrors(error, console.trace());
        });

        return serverReturn;
      }
    }
  } as Module<any, any>;
}
