
































import Component from "vue-class-component";
import Vue from "vue";
import { MDCDialog } from "@material/dialog";
import PRIVACY_HTML from "@/assets/PRIVACY_HTML";

@Component
export default class Privacy extends Vue {
  private dialog!: MDCDialog;
  get PRIVACY_HTML() {
    return PRIVACY_HTML;
  }
  get text() {
    return {
      privacyTitle: this.$t("dialogs.privacy.privacyTitle"),
      privacyButton: this.$t("dialogs.privacy.privacyButton")
    };
  }

  public mounted() {
    this.dialog = new MDCDialog(
      document.getElementById("privacy-dialog") as any
    );
  }

  public show() {
    this.dialog.open();
  }
}
