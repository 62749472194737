import { Vue, Component } from "vue-property-decorator";
import i18n from "@/i18n";

@Component

export default class DateFormatHelpers extends Vue {
  static getDateObject(date: Date, interval: string| null = null, num = 0): Date{
      switch(interval){
        case "day":
          date.setDate(date.getDate() + num);
          break;
        case "month":
          date.setMonth(date.getMonth() + num);
          break;
        default:
          break;
      }
      return date;
  }
    
  static formattedJSDateStringFromDate(date: Date): string {
    // try {
    //   return date.toLocaleDateString();
    // } catch (error) {
    //   return date;
    // }
    try {
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      const year = date.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      return [year, month, day].join('-');
    } catch (error) {
        return date.toString();
    }
  }

  public static JSDateToDateTimeString(date: Date): string {
    try {
      
      return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    } catch (error) {
      return date.toString();
    }
  }

  public static formattedStringFromDateString(dateString: string, type: "date" | "time" | "dateTime" = "date") {
    if(!dateString) return dateString;
    const date = new Date(dateString);
    try {
        switch(type){
            case "date":
                return date.toLocaleDateString(i18n.locale === "cant"?"zh-hk":"en-hk");
            case "time":
                return date.toLocaleTimeString(i18n.locale === "cant"?"zh-hk":"en-hk");
            default:
                return date.toLocaleDateString(i18n.locale === "cant"?"zh-hk":"en-hk") + " " + date.toLocaleTimeString(i18n.locale === "cant"?"zh-hk":"en-hk");
        }
    } catch (error) {
      return dateString;
    }
  }


// a and b are javascript Date objects
  public static dateDifferenceInDays(a: Date, b: Date) {
    const msPerDay = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / msPerDay);
  }
       
}