







































import { Component, Vue, Prop } from "vue-property-decorator";
import { MDCDialog } from "@material/dialog";
import { MDCRipple } from "@material/ripple";

import ConfirmPrompt from "@/components/dialogs/ConfirmPrompt.vue";

// constant
import { localeRep } from "@/assets/constant";

@Component
export default class ChangeLanguageDialog extends Vue {
  private dialog!: MDCDialog;

  get text() {
    return {
      title: this.$t("sidebars.settingBar.changeLanguageDialog.title")
    };
  }
  mounted() {
    this.dialog = new MDCDialog(
      document.querySelector("#change-language-prompt") as HTMLElement
    );

    const listItemRipples = (Array.from(
      document.getElementsByClassName("mdc-list-item ")
    ) as HTMLElement[]).map((el) => new MDCRipple(el));
  }

  get languageMenu() {
    const settingItems = [];
    const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE!.split(
      ","
    );
    for (let i = 0; i < supportedLocales.length; i++) {
      const thisLocale = supportedLocales[i] as SupportedLocale;
      settingItems.push({
        name: localeRep[thisLocale],
        function: async () => {
          this.$store.commit("showLoading");
          localStorage.setItem("locale", thisLocale);
          await this.setLocale(thisLocale);
          this.$store.commit("hideLoading");
          // location.reload(); //clear reload everything
        },
        confirmPrompt: {
          message: this.$t("dialogs.confirmPrompt.languageMessage", {
            language: localeRep[thisLocale]
          })
        }
      });
    }
    // settingItems.splice(0, 1);
    return settingItems;
  }

  private async setLocale(locale: SupportedLocale) {
    if (this.$i18n.locale !== locale) {
      this.$root.$i18n.locale = locale;
      const to = this.$router.resolve({ params: { locale: locale } });
      await this.$router.push(to.location);
    }
  }

  private show() {
    this.dialog.open();
  }
}
