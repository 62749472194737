/**
 * script to be run before anything
 */

// make console log only available to development
const log = console.log;
console.log = function(...args: any[]) {
  if (process.env.VUE_APP_ENVIRONMENT === "production") {
    void 0;
  } else {
    log.apply(console, args as any);
  }
};
const win = typeof (window as any) !== "undefined" ? window : ({} as Window);
import { setupPlatforms } from "@/utils/platform";

setupPlatforms(win);

// official vue app
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
