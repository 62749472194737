









































import { Component, Vue, Prop } from "vue-property-decorator";
import { MDCDialog } from "@material/dialog";

@Component
export default class ConfirmPrompt extends Vue {
  @Prop() reference!: string;
  @Prop() title?: string;
  @Prop() message!: string;
  @Prop() event?: string; // emit to parent when accept blt is clicked
  private confirmPrompt!: MDCDialog;

  public mounted() {
    this.confirmPrompt = new MDCDialog(
      this.$refs[this.reference as string] as Element
    );
  }

  get text() {
    return {
      title: this.$t("dialogs.confirmPrompt.title"),
      okay: this.$t("dialogs.confirmPrompt.okay"),
      notOkay: this.$t("dialogs.confirmPrompt.notOkay")
    };
  }

  public show() {
    this.confirmPrompt.open();
  }

  private emitToParent() {
    if (this.event) {
      this.$emit(this.event);
    }
  }
}
