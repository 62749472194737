














































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MDCSelect } from "@material/select";

@Component
export default class TheMDCSelectOutlined extends Vue {
  @Prop() label!: string;
  @Prop() menuList!: Array<MenuItem>;
  @Prop() reference!: string;
  @Prop() isRequired!: boolean | undefined;
  @Prop() isDisabled!: boolean | undefined;
  @Prop() width!: string | undefined;
  @Prop() height!: string | undefined;
  @Prop() event!: string | undefined;
  @Prop() defaultValue?: string | undefined;
  @Prop() fontSize?: string;
  @Prop() removeEmptyOption?: boolean;
  // @Watch("this.selectMenu.value") 
  // watchEmitToParent(newValue: string, oldValue: string) {
  //   console.log('WatchEvent', this.event, newValue, oldValue);
  //   if (this.menuList.find((menuItem: MenuItem) => menuItem.value == newValue)?.disabled) {
  //     if(this.menuList.find((menuItem: MenuItem) => menuItem.value == oldValue)?.disabled){
  //       this.setSelectedValue("");
  //     }
  //     this.setSelectedValue(oldValue);
  //   }
  // }
  private selectMenu!: MDCSelect;
  // private inputValue = "";

  spliter = "%%" as string;

  mounted() {
    this.selectMenu = new MDCSelect(
      this.$refs[this.reference as any] as Element
    );
    if (this.defaultValue) {
      this.setSelectedValue(this.defaultValue);
    }

    // this.selectMenu.listen('MDCSelect:change', (event: any) => {
    // });

  }

  getMDC() {
    return this.selectMenu;
  }

  getSelectedValue(): any {
    return this.selectMenu.value;
  }

  /**
   * Need to update the select option list before the select is mounted. 
   */
  //When select option array is dynamic:
  //1. manulist need to be saved in a property , not a computed method
  //2. property menulist array need to be assign at created(), if not then select value can't be assigned in mounted()
  setSelectedValue(value: any) {
    try{
        this.selectMenu.value = value?? "";

    }catch(e){
      console.log("SelectOutlined setSelectedValue catch e",e);
    }
    // this.selectMenu.selectedIndex = 0;
  }

  private emitToParent(value: any) {
    this.$emit("input", value);
    if (this.event) {
      this.$emit(this.event,value);
    }
  }

}
