var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.reference,
      staticClass: "mdc-circular-progress mdc-circular-progress--small",
      staticStyle: { "text-align": "left" },
      attrs: {
        role: "progressbar",
        "aria-label": "Example Progress Bar",
        "aria-valuemin": "0",
        "aria-valuemax": "1"
      }
    },
    [
      _c(
        "div",
        { staticClass: "mdc-circular-progress__determinate-container" },
        [
          _c(
            "svg",
            {
              staticClass: "mdc-circular-progress__determinate-circle-graphic",
              attrs: {
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("circle", {
                staticClass: "mdc-circular-progress__determinate-circle",
                attrs: {
                  cx: "12",
                  cy: "12",
                  r: "8.75",
                  "stroke-dasharray": "54.978",
                  "stroke-dashoffset": "54.978"
                }
              })
            ]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "mdc-circular-progress__indeterminate-container" },
        [
          _c("div", { staticClass: "mdc-circular-progress__spinner-layer" }, [
            _c(
              "div",
              {
                staticClass:
                  "mdc-circular-progress__circle-clipper mdc-circular-progress__circle-left"
              },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "mdc-circular-progress__indeterminate-circle-graphic",
                    attrs: {
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("circle", {
                      attrs: {
                        cx: "12",
                        cy: "12",
                        r: "8.75",
                        "stroke-dasharray": "54.978",
                        "stroke-dashoffset": "27.489"
                      }
                    })
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "mdc-circular-progress__gap-patch" }, [
              _c(
                "svg",
                {
                  staticClass:
                    "mdc-circular-progress__indeterminate-circle-graphic",
                  attrs: {
                    viewBox: "0 0 24 24",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("circle", {
                    attrs: {
                      cx: "12",
                      cy: "12",
                      r: "8.75",
                      "stroke-dasharray": "54.978",
                      "stroke-dashoffset": "27.489"
                    }
                  })
                ]
              )
            ]),
            _c(
              "div",
              {
                staticClass:
                  "mdc-circular-progress__circle-clipper mdc-circular-progress__circle-right"
              },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "mdc-circular-progress__indeterminate-circle-graphic",
                    attrs: {
                      viewBox: "0 0 24 24",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("circle", {
                      attrs: {
                        cx: "12",
                        cy: "12",
                        r: "8.75",
                        "stroke-dasharray": "54.978",
                        "stroke-dashoffset": "27.489"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }