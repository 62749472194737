import { AxiosInstance } from "axios";
import { Module } from "vuex";

import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

export function userModule(axiosInstance: AxiosInstance) {
  const credits: Module<any, any> = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async changePassword(store: any, passwords: any) {
            const serverReturn = await axiosInstance.request({
              method: "POST",
              url: "auth/change-password/",
              data: {
                "originalPassword": passwords.originalPassword,
                "password": passwords.password
              }
            }).then(response => {
              //console.log(response);
              if (response.data.success) {
                return true
              }
              return false
            }).catch(error => {
              console.log(error)
              return serverResponseHelpers.processServerErrors(error, console.trace());
            })
            return serverReturn
        },
    }
  };
  return credits;
}
