



























































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import {MDCDataTable} from '@material/data-table';

// using mdc dialog to do implementation
@Component
export default class TheMDCDataTable extends Vue {
  @Prop() event?: string;
  @Prop() headers!: any[];
  @Prop() rows!: any[];
  @Prop() reference?: string;

  mounted() {
    const dataTable = new MDCDataTable(this.$refs[this.reference as any] as Element);
  }

}
