











































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MDCDrawer } from "@material/drawer";
import { MDCList } from "@material/list";

// component
import ConfirmPrompt from "@/components/dialogs/ConfirmPrompt.vue";

// particular
import ExportReportDialog from "@/components/sidebars/SettingBar/ExportReportDialog.vue";
import ChangeLanguageDialog from "@/components/sidebars/SettingBar/ChangeLanguageDialog.vue";
import ResetPasswordDialog from "@/components/sidebars/SettingBar/ResetPasswordDialog.vue";
import { MDCRipple } from "@material/ripple";
import { PhoneNumber } from "google-libphonenumber";
import * as ImportDataHelpers from "@/utils/importDataHelpers";


@Component({
  components: {
    ConfirmPrompt,
    ExportReportDialog,
    ChangeLanguageDialog,
    ResetPasswordDialog

  }
})
export default class Settings extends Vue {
  private drawer!: MDCDrawer;
  get text() {
    return {
      title: this.$t("sidebars.settingBar.title"),
      userInfo: this.$t("sidebars.settingBar.userInfo"),
      export: this.$t("sidebars.settingBar.export"),
      bulkImport: this.$t("sidebars.settingBar.bulkImport"),
      printer: this.$t("sidebars.settingBar.printer"),
      language: this.$t("sidebars.settingBar.language"),
      logout: this.$t("sidebars.settingBar.logout")

      // languageTitle: this.$t("sidebars.settingBar.languageTile")
    };
  }

  mounted() {
    this.drawer = MDCDrawer.attachTo(
      // only one drawer
      document.querySelector("#setting-bar-inner") as HTMLElement
    );
    const listItemRipples = (Array.from(
      document.getElementsByClassName("mdc-list-item ")
    ) as HTMLElement[]).map(el => new MDCRipple(el));
  }
  get normalSettingItems() {
    return {
      export: {
        name: this.text.export,
        icon: "send",
        prompt: {
          ref: "export-report__prompt",
          type: "exportReport"
        }
      },
      bulkImport: {
        name: this.text.bulkImport,
        icon: "file_upload"
      },
      language: {
        name: this.text.language,
        icon: "language",
        prompt: {
          ref: "language__prompt",
          type: "changeLanguage"
        }

      },
      resetPassword: {
        name: this.$t("sidebars.settingBar.resetPassword"),
        icon: "lock",
        prompt:{
          ref: "reset-password-prompt"
        }
      },
      requestLabel: {
        name: this.$t("sidebars.settingBar.requestLabel"),
        icon: "receipt_long",
        prompt:{
          ref: "request-label-prompt"
        }
      },
      logout: {
        name: this.text.logout,
        icon: "keyboard_return",
        function: async () => {
          this.$store.commit("showLoading");
          const logout = await this.$store.dispatch("logout");
          if (logout) {
            await this.$router.push({ path: "/" });
          }
          location.reload(); //clear vuex
        },
        prompt: {
          ref: "logout__prompt",
          type: "confirmPrompt",
          message: this.$t("dialogs.confirmPrompt.logoutMessage")
        }
      }
    };
  }

  get name() {
    return this.$store.getters.getUser?.name || null;
  }
  get email(): string | null {
    const email = this.$store.getters.getUser?.email;
    if (email) {
      return email.length >= 28 ? email.slice(0, 28) + "..." : email;
    }
    return null;
  }
  get accountBalance() {
    return this.$store.getters.getCompany
      ? this.$store.getters.getCompany.accountBalance
      : null;
  }

  get showSettingBar() {
    return this.$store.getters["showSettingBar"];
  }
  @Watch("showSettingBar")
  onShowChange(val: boolean, oldVal: boolean) {
    if (val) {
      this.drawer.open = true;
      this.$store.commit("resetShowSettingBar"); // this is a heck to reset the vuex attribute
    }
  }
  get hideSettingBar() {
    return this.$store.getters["hideSettingBar"];
  }
  @Watch("hideSettingBar")
  onHideChange(val: boolean, oldVal: boolean) {
    if (val) {
      this.drawer.open = false;
      this.$store.commit("resetHideSettingBar"); // this is a heck to reset the vuex attribute
    }
  }
  get isOpened(){
    return this.drawer?this.drawer.open:false;
  }
  opening(){
    this.$store.commit("resetShowSettingBar");
  }
  closing(){
    this.$store.commit("resetHideSettingBar");
  }

  // get isMobile(){
  //   return ImportDataHelpers.checkIsMobile();
  // }

  private openDialog(ref: string) {
    (this.$refs[ref] as ConfirmPrompt).show();
  }

  private async toRequestLabelForm(){
    await this.$router.push({
      name: "RequestLabelForm"
    }).catch((e)=>{
       this.$store.commit("hideLoading");
    });
  }

  // private async toBulkImportPage(){
  //   await this.$router.push({
  //     name: "BulkImport"
  //   });
  // }
   private async toBulkImportPage(){
    await this.$router.push({
      name: "BulkImport"
    }).catch((e)=>{
      console.log(e);
      this.$store.commit("hideLoading");
    });
  }
}
