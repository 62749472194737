import * as serverResponseHelpers from "@/utils/serverResponseHelpers";
import { AxiosInstance } from "axios";
import { Module } from "vuex";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

export function inAppNoticesModule(axiosInstance: AxiosInstance) {
  return {
    namespaced: true,
    state: {
      showInAppNotices: false,
      // only for read
      dataList: [] as Array<InAppNoticeData>,
      // return to backend
      checkedByUser: [] as Array<CheckedByUserList>,
    },
    getters: {
      getShowInAppNotices: (state) => state.showInAppNotices,
      getDataList: (state) => state.dataList,
      getCheckedByUser: (state) => state.checkedByUser,
    },
    mutations: {
      showInAppNotices(state) {
        if (!Storage.hasKey("state.inAppNotices.dataList")) {
          state.showInAppNotices = true;
        }
      },

      hideInAppNotices(state) {
        state.showInAppNotices = false;
      },

      initDataList(state, data: InAppNoticeData[]) {
        Storage.saveToSD("state.inAppNotices.dataList", data);
        state.dataList = data;
      },

      initCheckedByUser(state, data: CheckedByUserList[]) {
        state.checkedByUser = data;
      },

      checkedOneDoNotShowAgain(
        state,
        data: { id: string; doNotShowAgain: boolean }
      ) {
        const index = state.checkedByUser.findIndex(
          (e: CheckedByUserList) => e.inAppNoticeUserId === data.id
        );

        if (index != -1) {
          const seenBody: CheckedByUserList = {
            ...state.checkedByUser[index],
            doNotShowAgain: data.doNotShowAgain,
          };
          state.checkedByUser.splice(index, 1, seenBody);
        }
      },

      checkedOneSeen(state, id: string) {
        const index = state.checkedByUser.findIndex(
          (e: CheckedByUserList) => e.inAppNoticeUserId === id
        );
        if (index != -1) {
          const seenBody: CheckedByUserList = {
            ...state.checkedByUser[index],
            seen: true,
          };
          state.checkedByUser.splice(index, 1, seenBody);
        }
      },
    },
    actions: {
      async fetchAllInAppNotices(store) {
        const serverReturn = await axiosInstance
          .request({
            method: "GET",
            url: "in-app-notices/list",
          })
          .then(async (response) => {
            if (response.data.success) {
              store.commit("initDataList", response.data.data.datalist);
              const returnBodyCheckedList: any = [];
              response.data.data.datalist.map((e: any) => {
                returnBodyCheckedList.push({
                  inAppNoticeUserId: e.inAppNoticeUserId,
                  seen: false,
                  doNotShowAgain: e.allowDoNotShowAgain ? false : null,
                });
              });
              store.commit("initCheckedByUser", returnBodyCheckedList);

              return true;
            }

            return false;
          })
          .catch((error) => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(
              error,
              console.trace()
            );
          });
        return serverReturn;
      },

      async changeUserAllNotices({ getters }) {
        const temp = await getters["getCheckedByUser"];

        const serverReturn = await axiosInstance
          .request({
            method: "PATCH",
            url: "in-app-notices/user-list",
            data: { checkedByUserList: temp },
          })
          .then((response) => {
            if (response.data.success) {
              return true;
            }
            return false;
          })
          .catch((error) => {
            console.log(error);
            return serverResponseHelpers.processServerErrors(
              error,
              console.trace()
            );
          });
        return serverReturn;
      },
    },
  } as Module<any, any>;
}
