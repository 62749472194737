


















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { MDCCircularProgress } from "@material/circular-progress";

@Component
export default class TheMDCCircularProgress extends Vue {
  @Prop() reference!: string;
  private circularProgress!: MDCCircularProgress;

  mounted() {
    this.circularProgress = new MDCCircularProgress(
      this.$refs[this.reference as string] as Element
    );
    this.circularProgress.determinate = false;
  }
}
