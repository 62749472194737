var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow(),
          expression: "isShow()"
        }
      ],
      staticClass: "loading__container"
    },
    [
      _c(
        "div",
        { staticClass: "inner-loading" },
        [
          _c("TheMDCCircularProgress", { attrs: { reference: "loading" } }),
          _c("h6", [_vm._v(_vm._s(_vm.text.title))])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }