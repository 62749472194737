import i18n from "@/i18n";
import { districts, areas } from "@/assets/constant";
import DateFormatHelpers from "@/utils/dateFormatHelpers";
import App from "@/App.vue";
import router from "@/router";



import store from "@/store";
// import router from "@/router";
// import { Alpha2ToName, itemCategories } from "@/assets/constant";
// import { fetchShipmentRecordbyId } from "@/utils/fetchDataHelpers";
// import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";

export class ShipmentHelpers {

  public static displayAddress(address: Address): string {
    const stateString = address.state?address.state:"";
    const cityString = address.city?address.city:"";
    const addressLine1String = address.addressLine1?address.addressLine1:"";
    const addressLine2String = address.addressLine2?address.addressLine2:"";
    if(i18n.locale === "cant"){
      if(address.state !== address.city){
        return stateString + '\n' + cityString + '\n' + addressLine1String + '\n' + addressLine2String
      }else {
        return cityString + '\n' + addressLine1String + '\n' + addressLine2String
      }
    }else{
      if(address.state !== address.city){
        return addressLine1String + '\n' + addressLine2String + '\n' + cityString + '\n' + stateString + '\n';
      }else {
        return addressLine1String + '\n' + addressLine2String+ '\n' + cityString;
      }
    }
  }

  public static displayExpressOrderAddress(address: ExpressOrderAddress): string {
    const districtArea = ShipmentHelpers.getDistrictAreaByAreaRank(address.areaRank);
    const districtString = districtArea.district?(i18n.locale == "cant"?districtArea.district.districtChi:districtArea.district.districtEn):"";
    const areaString = districtArea.area?(i18n.locale == "cant"?districtArea.area.areaChi:districtArea.area.areaEn):"";
    const buildingString = (address.buildingChi || address.buildingEn || address.streetEn || address.streetChi?(i18n.locale == "cant"? address.buildingChi || "" + '\n' + address.streetChi || "":address.streetEn || "" + '\n' + address.buildingEn || ""):address.fullAddress || "");
    const roomString = address.room?i18n.t("tracking.trackingDetail.room") as string + i18n.t("general.colon") as string + address.room:"";
    const floorString = address.room?i18n.t("tracking.trackingDetail.floor") as string + i18n.t("general.colon") as string + address.floor:"";
    const addressLineString = address.addressLine1?address.addressLine1 + '\n' + address.addressLine2:"";

    if(i18n.locale == "cant"){
      if(addressLineString){
        return districtString + '\n' + areaString + '\n' + addressLineString;
      }else{
        return districtString + '\n' + areaString + '\n' + buildingString + '\n' + floorString +'\n' + roomString;
      }
    }else{
      if(addressLineString){
        return addressLineString + '\n' + areaString + '\n' + districtString + '\n';
      }else{
        return roomString + '\n' + floorString + '\n' + buildingString + '\n' + areaString + '\n' + districtString + '\n';
      }
    }
  }
  /* eslint-disable */
  public static getDistrictAreaByAreaRank(areaRank: string): {district: District | null, area: Area | null}{
    const districts = store.getters["address/getDistricts"];
    const area = this.getAreaByAreaRank(areaRank);
    let district = null;
    if(districts && area){
      district = districts.find((district: District)=> district.districtFull == area.districtFull);
    }
    return {district: district, area: area};
  }

  public static getAreaByAreaRank(areaRank: string): Area{
    const areas = store.getters["address/getAreas"];
    return areas.find((area: Area)=>area.areaRank == areaRank);  
  }

  public static validateAreaRank(areaRank: string): boolean {

    return store.getters["address/getAreas"].find((area: Area) => area.areaRank == areaRank);
  }

  public static validateDistrict(checkingDistrict: string): boolean{
    return districts.includes(checkingDistrict);
  }

  public static validateArea(checkingDistrict: string, checkingArea: string): boolean{
    if (!this.validateDistrict(checkingDistrict)) {
      return false;
    }
    return areas[checkingDistrict].includes(checkingArea);
  }

  // public static getBuildingAddress(action: string, identity: string, templateId: string): BuildingAddress | null{
  //   try{
  //     if(action == "CreateItem"){
  //       if(!templateId){
  //         return null;
  //       }else{
  //         return ShipmentHelpers.convertAddressToBuildingAddress((store.getters["getUserSavedAddressById"](templateId, identity) as ExpressOrderAddress)) || null;
  //       }
  
  //     }else{
  //       return ShipmentHelpers.convertAddressToBuildingAddress(store.getters["shipment/getShipment"+identity+"Address"] as ExpressOrderAddress) || null;
  //     }
  //   }catch(e){
  //     return null;
  //   }
  // }

  //TODO: convert address to building Address
  public static convertAddressToBuildingAddress(address: ExpressOrderAddress): BuildingAddress | null{
    try{
      return {

        fullAddressChi: address.fullAddress,
        fullAddressEn: address.fullAddress,
        areaRank: address.areaRank,
        areaChi: "",
        areaEn: "",
        areaFull: "",
        districtChi: "",
        districtEn: "",
        districtFull: "",
        lineRank: "",
        lineChi: "",
        lineEn: "",
        lineFull: "",
        buildingChi: address.buildingChi,
        buildingEn: address.buildingEn,
        streetChi: address.streetChi,
        streetEn: address.streetEn,
        isFreeInput: address.isFreeInput
      } as BuildingAddress
    }catch(e){
      return ShipmentHelpers.emptyBuildingAddress;
    }
  }

  public static emptyAddress = {
      id: null,
      contactName: "",
      phoneAreaCode: "852",
      contactPhone: "",
      areaRank: "",
      fullAddress: "",
      streetEn: "",
      streetChi: "",
      buildingEn: "",
      buildingChi: "",
      floor: "",
      room: ""
  } as ExpressOrderAddress

  public static emptyBuildingAddress = {
    fullAddressChi: "",
    fullAddressEn: "",
    areaRank: "",
    areaChi: "",
    areaEn: "",
    areaFull: "",
    districtChi: "",
    districtEn: "",
    districtFull: "",
    lineRank: "",
    lineChi: "",
    lineEn: "",
    lineFull: "",
    buildingChi: "",
    buildingEn: "",
    streetChi: "",
    streetEn: "",
    isFreeInput: true
  } as BuildingAddress;
  
  public static getAvailableDeliveryDates(): string[]{
    return store.getters['date/getAvailableDeliveryDates'];
  }
  

  public static getAvailablePickUpDates(inputDeliveryDate = store.getters['shipment/getDeliveryDate']): string[]{
    // const inputDeliveryDate = store.getters['shipment/getDeliveryDate'];
    
    const allAvailablePickUpDates = store.getters['date/getAvailablePickUpDates'];
    const allAvailableDeliveryDates = store.getters['date/getAvailableDeliveryDates'];
    if(!inputDeliveryDate || isNaN(Date.parse(inputDeliveryDate))){
      // return allAvailablePickUpDates;
      return [];
    }
    const inputDeliveryDatIndex = allAvailableDeliveryDates.indexOf(inputDeliveryDate);
    if(inputDeliveryDatIndex === -1){
      return [];
    }
    //if user select last two deliver date, pickup date option will be limited to one or two instead of last three pick
    return allAvailablePickUpDates.filter((date: string) => new Date(inputDeliveryDate) >= new Date(date)).slice(-1 * (allAvailableDeliveryDates.length - inputDeliveryDatIndex < 3?allAvailableDeliveryDates.length - inputDeliveryDatIndex:3));
  }
  //delivery date validation
  //-----------------------------------------------------------------------------------------------------------------------
  //TODO: Should not use helper to handle view behavior
  public static validatePickUpDeliveryDate(view: any, now: Date){
    // return  this.deliveryDate && Date.parse(this.deliveryDate as string) && new Date(this.deliveryDate) >= new Date(this.deliveryDateMin) && new Date(this.deliveryDate) <= new Date(this.deliveryDateMax);
    const availableDeliveryDates = store.getters['date/getAvailableDeliveryDates'];
    const inputPickUpDate = store.getters['shipment/getPickUpDate'];
    const inputDeliveryDate = store.getters['shipment/getDeliveryDate'];
    if (!this.after8PMValidateDeliveryDate(inputPickUpDate, inputDeliveryDate, now)){
      //return to shipment
      router.push({name: "Shipment"});
      window.scroll({
        top: 100,
        behavior: 'smooth'
      });
      store.commit("dialog/showErrorDialog",{
        title: i18n.t('shipment.shipmentForm.shipmentForm.deliveryDateError.title')as string,
        msg: i18n.t('shipment.shipmentForm.shipmentForm.deliveryDateError.after8PMCutOff')as string,
        closeBlt: i18n.t("dialogs.close") as string
      });
      return false;        
    }else{
      return true;
    }
  }
  

  // check if input is in delivery day from backend
  // public static isAvailableDeliveryDate(availableDeliveryDates: string[], deliveryDate: string, currentDate: Date): boolean{
  //   return availableDeliveryDates.includes(deliveryDate);
  // }

  // if user proceed shipment after 8pm, delivery date can't be tomorrow
  public static after8PMValidateDeliveryDate(pickUpDate: string, deliveryDate: string, currentDate: Date): boolean{
    return !(currentDate.getHours() >= Number(process.env.VUE_APP_SHIPMENT_CUT_OFF_TIME) && DateFormatHelpers.dateDifferenceInDays(currentDate, new Date(pickUpDate)) <= 1);
  }

  // delivery date is saturday, user has to submit before thursday 8pm
  // public static isSaturdayDeliveryDate(pickUpDate: string,deliveryDate: string, currentDate: Date): boolean{
  //   const inputdate = new Date(deliveryDate);
  //   return !(inputdate.getDay() == 6 
  //     && (DateFormatHelpers.dateDifferenceInDays(currentDate, inputdate) <= 1 
  //       || (DateFormatHelpers.dateDifferenceInDays(currentDate, inputdate) <= 2 && currentDate.getHours() >= Number(process.env.VUE_APP_SHIPMENT_CUT_OFF_TIME))));
  // }
  public static validateExpressOrderTrackingNumberFormat(trackingNumber: string): boolean{
    
    if(typeof trackingNumber == "string"){
      if(trackingNumber.match("[J][P][0-9]{12}")){
        return true;
      }
    }
    return false;
  }

  public static async validateTrackingNumeberWithType(trackingNumber: string, sameDayDelivery: boolean){
    let result =  await store.dispatch("shipment/validateTrackingNumeberWithType", {trackingNumber: trackingNumber, sameDayDelivery: sameDayDelivery});
    return result;
  }

  public static async validateTrackingNumeberWithoutType(trackingNumber: string){
    let result =  await store.dispatch("shipment/validateTrackingNumeberWithoutType", {trackingNumber: trackingNumber});
    return result;
  }

  public static validateExpressOrderTrackingNumberDuplicate(existingTrackingNumbers: string[], trackingNumber: string): boolean{
    if (existingTrackingNumbers.includes(trackingNumber)) {
      return false;
    }
    return true;
  }

  public static updateExpressOrderItemDescription(item: ExpressOrderItem){
    if(!item) return item;
    const updatingItem = Object.assign({},item);
    const isDocument = store.getters["shipment/isDocument"];

    const categoryOption: Option = isDocument?store.getters["itemOption/expressOrder/getSameDayCategoriesById"](updatingItem.categoryOptionId)
        :store.getters["itemOption/expressOrder/getNonSameDayCategoriesById"](updatingItem.categoryOptionId);
    updatingItem.categoryDescriptionChi = categoryOption?categoryOption.descriptionChi:"";
    updatingItem.categoryDescriptionEn = categoryOption?categoryOption.descriptionEn || "":"";
    

    if(isDocument){
      const weightOption: Option = store.getters["itemOption/expressOrder/getWeightById"](updatingItem.weightOptionId);
      updatingItem.weightDescriptionChi = weightOption?weightOption.descriptionChi:"";
      updatingItem.weightDescriptionEn = weightOption?weightOption.descriptionEn || "":"";
    }

    const dimensionOption: Option = store.getters["itemOption/expressOrder/getSameDayDimensionById"](updatingItem.dimensionOptionId);
      updatingItem.dimensionDescriptionChi = dimensionOption?dimensionOption.descriptionChi:"";
      updatingItem.dimensionDescriptionEn = dimensionOption?dimensionOption.descriptionEn || "":"";

    if(isDocument){
      const temperatureOption: Option = store.getters["itemOption/expressOrder/getTemperatureById"](updatingItem.temperatureOptionId);
      updatingItem.temperatureDescriptionChi = temperatureOption?temperatureOption.descriptionChi:"";
      updatingItem.temperatureDescriptionEn = temperatureOption?temperatureOption.descriptionEn || "":"";
    }

    return updatingItem;
  }

  public static removePickUpStoreSecond(time: string){
    return time.substring(0,time.length-3);
  }

  public static displayPickUpStore(pickUpStore: PickUpStore){
    if(!pickUpStore.id) return "";
    return [
      pickUpStore.alias,
      [i18n.t("pickUpStore.address") as string, i18n.t("general.colon") as string, pickUpStore.address].join(""),
      "",
      i18n.t('pickUpStore.openHours') as string,
      this.displayPickUpStoreOpenHours(pickUpStore),
      [i18n.t('pickUpStore.freeStorageDays'), i18n.t('general.colon'), pickUpStore.freeStorage??i18n.t('general.noInformation')].join("")
    ].join("\n");
  }
  public static displayPickUpStoreOpenHours(pickUpStore: PickUpStore){
    if(!pickUpStore.id ||
      (!pickUpStore.mondayFrom && !pickUpStore.tuesdayFrom && !pickUpStore.wednesdayFrom && !pickUpStore.thursdayFrom && !pickUpStore.fridayFrom && !pickUpStore.saturdayFrom && !pickUpStore.sundayFrom && !pickUpStore.publicHolidayFrom
    )) {
        return i18n.t("general.noInformation");
      }
    return [
      i18n.t('general.dayOfWeek.monday') as string + i18n.t('general.colon') as string + (!pickUpStore.mondayFrom || !pickUpStore.mondayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.mondayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.mondayTo)].join("")),
      i18n.t('general.dayOfWeek.tuesday') as string +  i18n.t('general.colon') as string + (!pickUpStore.tuesdayFrom || !pickUpStore.tuesdayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.tuesdayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.tuesdayTo)].join("")),
      i18n.t('general.dayOfWeek.wednesday') as string + i18n.t('general.colon') as string + (!pickUpStore.wednesdayFrom || !pickUpStore.wednesdayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.wednesdayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.wednesdayTo)].join("")),
      i18n.t('general.dayOfWeek.thursday') as string + i18n.t('general.colon') as string + (!pickUpStore.thursdayFrom || !pickUpStore.thursdayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.thursdayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.thursdayTo)].join("")),
      i18n.t('general.dayOfWeek.friday') as string + i18n.t('general.colon') as string + (!pickUpStore.fridayFrom || !pickUpStore.fridayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.fridayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.fridayTo)].join("")),
      i18n.t('general.dayOfWeek.saturday') as string + i18n.t('general.colon') as string + (!pickUpStore.saturdayFrom || !pickUpStore.saturdayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.saturdayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.saturdayTo)].join("")),
      i18n.t('general.dayOfWeek.sunday') as string + i18n.t('general.colon') as string + (!pickUpStore.sundayFrom || !pickUpStore.sundayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.sundayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.sundayTo)].join("")),
      i18n.t('general.dayOfWeek.holiday') as string + i18n.t('general.colon') as string + (!pickUpStore.publicHolidayFrom || !pickUpStore.publicHolidayTo?i18n.t('pickUpStore.closed') as string:[ this.removePickUpStoreSecond(pickUpStore.publicHolidayFrom), i18n.t('general.hypen'), this.removePickUpStoreSecond(pickUpStore.publicHolidayTo)].join(""))
    ].join("\n").trim();
  }

  public static calculateShipmentPrice(expressOrderItems: ExpressOrderItem[], isDocument: boolean): number {
    if (!isDocument) {
      return 27;
    }
    else if (expressOrderItems.length < 3) {
      return 68 
    }
    else {
      return 68 + (expressOrderItems.length - 3) * 20
    }
  }
}
