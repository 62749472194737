

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import App from '@/App.vue';

// The MDC Components
import TheMDCButtonShapedRaised from '@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue';
import TheMDCButtonOutlined from '@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue';

// View Component
import CardDisplay from '@/views/credits/CardDisplay.vue';

// utils
import * as fetchDataHelpers from '@/utils/fetchDataHelpers.ts';
import * as timeoutHelpers from '@/utils/timeoutHelpers.ts';

@Component({
  components: {
    TheMDCButtonShapedRaised,
    TheMDCButtonOutlined,
    CardDisplay,
  },
})
export default class Credits extends Vue implements VuePromptCaller {
  // Visiter Pattern
  public on(event: 'accept' | 'close', payload?: any, execution?: CallBack<void> | null) {
    if (event === 'accept') {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  private ROOT = this.$root.$children[0] as App;
  get text() {
    return {
      title: this.$t('credits.credits.title'),
      creditTitle: this.$t('credits.credits.creditTitle'),
      dollarSign: this.$t('credits.credits.dollarSign'),
      addCredit: this.$t('credits.credits.addCredit'),

      cardTitle: this.$t('credits.credits.cardTitle'),
      addCardLabel: this.$t('credits.credits.addCardLabel'),

      success: this.$t('credits.credits.success'),
      fail: this.$t('credits.credits.fail'),
      cardSuccess: this.$t('credits.credits.cardSuccess'),
      cardFail: this.$t('credits.credits.cardFail'),

      checkBalanceLog: this.$t('credits.credits.checkBalanceLog'),
    };
  }

  beforeCreate() {
    this.$store.commit('showTopBar');
    this.$store.commit('showNavBar');
  }
  mounted() {
    // this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been rendered
    this.$store.commit('hideLoading');
    // });
  }

  get accountBalance() {
    return this.$store.getters.getCompany?.accountBalance || 0;
  }

  get hasCard(): boolean | null {
    if (this.cards === null) {
      return null;
    } else {
      return this.cards.length > 0;
    }
  }

  get cards(): Card[] | null {
    return this.$store.getters['credits/getCards'];
  }

  get hasCreatedCard() {
    return this.$store.getters['credits/hasCreatedCard'];
  }

  get isTesting(){
    return process.env.VUE_APP_ENVIRONMENT !== 'production';
  }

  private toAddCredits(): void {
    // show add credit prompt
    this.ROOT.showAddCreditsPromptComponentPrompt({
      listener: this,
      execution: (event: string, payload: number) => {
        this.topUp(payload);
      },
    });
  }

  private toBalanceLog() {
    // go to balance log page

    this.$router.push({ name: 'BalanceLog' });
  }

  private async topUp(payload: any): Promise<void> {
    // cover up add credit with loading page
    this.$store.commit('showLoading');
    const canPay = await this.$store.dispatch('credits/charge', {
      paymentMethodId: payload.paymentMethodId,
      amount: payload.amount,
    });
    fetchDataHelpers.fetchUserLoginInfo(); // refech newest data after add credit
    this.$store.commit('hideLoading');

    // prompt according to add credit success
    //Customized top up error from jarvix pay
    if (canPay == 'paymentError') {
      this.showPaymentFailPrompt(this.$t('credits.credits.tryAnotherCreditCard') as string);
    } else if (canPay) {
      // if success, start connecting with backend untill payment is settled
      // cover up with loading page
      this.showPaymentSuccessPrompt();
      // this.$store.commit("showLoading");
      // await this.fetchPaymentStatus();
    } else if (canPay === false) {
      // if payment failed
      this.showPaymentFailPrompt();
    }
  }

  private async fetchPaymentStatus(): Promise<void> {
    // need some helper function for await five seconds
    // https://codingwithspike.wordpress.com/2018/03/10/making-settimeout-an-async-await-function/
    
    async function doRequests(func: () => Promise<any>, ms: number) {
      await timeoutHelpers.wait(ms);
      return await func();
    }
    const getChargeStatus = async () => {
      const status = await this.$store.dispatch('credits/getChargeStatus');
      return status;
    };

    /**
     * keep fetching payment status untill one of the following 3 cases:
     * - success
     * - failed
     * - overtime failed
     */
    for (let i = 0; i < 35; i++) {
      const paymentSuccess = await doRequests(
        getChargeStatus,
        Math.min(1000 * i, 5000), // at most wait 5 seconds
      );
      if (paymentSuccess === true) {
        // if success, show payment success prompt
        this.showPaymentSuccessPrompt();
        this.$store.commit('hideLoading');
        return;
      } else if (paymentSuccess === false) {
        // this.showPaymentFailPrompt(); // if failed, show payment failed prompt
        break;
      } else if (paymentSuccess === 'TRY_AGAIN') {
        // or else try again
        continue;
      } else {
        continue;
      }
    }
    // after 12 prompt
    this.showPaymentFailPrompt(); // if failed, show payment failed prompt or timeout
    this.$store.commit('hideLoading');
  }

  private showPaymentSuccessPrompt() {
    // if payment success, reload page to get the newest data
    this.ROOT.showPrompt({
      title: this.$t('dialogs.success') as string,
      msg: this.text.success as string,
      acceptBlt: this.$t('dialogs.okay') as string,
      listener: this,
      execution: () => {
        // if payment success, reload page to get the newest data
        this.refresh();
      },
    });
  }
  private showPaymentFailPrompt(message: string | null = null) {
    // if failed, do nothing
    this.ROOT.showPrompt({
      title: this.$t('dialogs.fail') as string,
      msg: message ? message : (this.text.fail as string),
      acceptBlt: this.$t('dialogs.close') as string,
    });
  }

  /**
   * register card flow
   */
  private async toRegistration() {
    // cover up add card with loading page
    this.$store.commit('showLoading');
    const registration = await this.$store.dispatch('credits/addCard');
    const promptBody = {
      title: this.$t('dialogs.fail') as string,
      msg: '',
      closeBlt: this.$t('dialogs.close') as string,
    };

    if (!registration) {
      this.$store.commit('showConnectionError');
    } else if (registration == 'paymentError') {
      promptBody.msg = this.$t('dialogs.jarvixPay.error.connection') as string;
      this.$store.commit('dialog/showErrorDialog', promptBody);
    } else if (registration == 'payCustomerError') {
      promptBody.msg = this.$t('dialogs.jarvixPay.error.customer') as string;
      this.$store.commit('dialog/showErrorDialog', promptBody);
    } else if (registration) {
      // if can add card
      this.showPaymentIFrame();
    }
    this.$store.commit('hideLoading');
  }
  private showPaymentIFrame() {
    const paymentRegistrationURL = // get Jarvix Pay payment-intent url
      process.env.VUE_APP_API_PAY_ADD_CARD_URL + this.$store.getters['credits/getCustomerId'];

    this.ROOT.showPaymentIframe({
      // use iframe to redirect into the url for registration
      listener: this,
      url: paymentRegistrationURL,
      execution: () => {
        // when return, registration should be done
        this.checkCardRegistration();
      },
    });
  }

  private async checkCardRegistration() {
    this.$store.commit('credits/createCard'); // update vuex state to created card
    this.$store.commit('showLoading');
    await timeoutHelpers.wait(2000);
    await fetchDataHelpers.fetchCardInfo();
    this.$store.commit('hideLoading');
  }

  private refresh() {
    window.location.reload();
  }
}
