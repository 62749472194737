var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.reference,
      staticClass: "mdc-select mdc-select--outlined",
      class: {
        "mdc-select--required": _vm.isRequired,
        "mdc-select--disabled": _vm.isDisabled
      },
      style: { width: _vm.width || "inherit", height: _vm.height || "inherit" }
    },
    [
      _c(
        "div",
        {
          staticClass: "mdc-select__anchor",
          staticStyle: { width: "100%", height: "inherit" },
          attrs: {
            "aria-disabled": _vm.isDisabled,
            "aria-labelledby": "outlined-select-label",
            "aria-required": _vm.isRequired
          }
        },
        [
          _c("span", {
            staticClass: "mdc-select__selected-text",
            staticStyle: {
              overflow: "hidden",
              "text-overflow": "ellipsis",
              "text-align": "left",
              display: "inline"
            },
            attrs: { id: "outlined-selected-text" }
          }),
          _c("i", { staticClass: "mdc-select__dropdown-icon" }),
          _c("span", { staticClass: "mdc-notched-outline" }, [
            _c("span", { staticClass: "mdc-notched-outline__leading" }),
            _c("span", { staticClass: "mdc-notched-outline__notch" }, [
              _c(
                "span",
                {
                  staticClass: "mdc-floating-label",
                  attrs: { id: "outlined-select-label" }
                },
                [_vm._v("\n          " + _vm._s(_vm.label) + "\n        ")]
              )
            ]),
            _c("span", { staticClass: "mdc-notched-outline__trailing" })
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mdc-select__menu mdc-menu mdc-menu-surface",
          staticStyle: { width: "100%" },
          attrs: { role: "listbox" }
        },
        [
          _c(
            "ul",
            { staticClass: "mdc-list" },
            [
              !_vm.isRequired && !_vm.removeEmptyOption
                ? _c("li", {
                    staticClass: "mdc-list-item",
                    style: { fontSize: _vm.fontSize },
                    attrs: {
                      "aria-selected": "true",
                      "data-value": "",
                      role: "option",
                      tabindex: "0"
                    },
                    on: {
                      click: function($event) {
                        return _vm.emitToParent()
                      }
                    }
                  })
                : _vm._e(),
              _vm._l(_vm.menuList, function(menuItem) {
                return _c(
                  "li",
                  {
                    key: menuItem.key,
                    staticClass: "mdc-list-item",
                    class: {
                      "mdc-list-item--disabled": menuItem.disabled,
                      "mdc-list-item-address-included": menuItem.expression.split(
                        _vm.spliter
                      )[1]
                    },
                    style: { fontSize: _vm.fontSize },
                    attrs: {
                      role: "option",
                      "data-value": menuItem.value,
                      disabled: menuItem.disabled,
                      "aria-disabled": menuItem.disabled
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.emitToParent(menuItem.value)
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "mdc-list-item__text" }, [
                      _vm._v(_vm._s(menuItem.expression.split("%%")[0]))
                    ]),
                    _c("br"),
                    menuItem.expression.split(_vm.spliter)[1]
                      ? _c(
                          "div",
                          { staticClass: "mdc-list-item__text_address" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  "- " + menuItem.expression.split("%%")[1]
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }