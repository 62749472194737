 import i18n from "@/i18n";
import store from "@/store";
import router from "@/router";
import { Alpha2ToName, itemCategories } from "@/assets/constant";
import * as fetchDataHelpers from "@/utils/fetchDataHelpers";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";
import { ShipmentHelpers }  from "@/utils/shipmentHelpers";
import DateFormatHelpers  from "@/utils/dateFormatHelpers";

import { PhoneNumberUtil, PhoneNumberType, PhoneNumber } from "google-libphonenumber";

import readXlsxFile from 'read-excel-file';
import { Console, group } from "console";
import * as BULK_IMPORT  from "@/constants/BULK_IMPORT.ts";
import * as PAYMENT_METHOD  from "@/constants/PAYMENT_METHOD";
import  * as compareHelpers from "@/utils/compareHelpers";
export const EMPTY_EXCEL_DATA = "EMPTY_EXCEL_DATA";
export const EXCEL_VERSION_INCOMPATIBLE = "EXCEL_VERSION_INCOMPATIBLE";
export const INVALID_FORMAT = "INVALID_FORMAT";
export const UNMATCH_COLUMN_NUMBER = "UNMATCH_COLUMN_NUMBER";
export const INVALID_DATA = "INVALID_DATA";
export const NUMBER_OF_ROWS_EXCEED_LIMIT = "NUMBER_OF_ROWS_EXCEED_LIMIT";


export const bulkImportExpressOrderColumnName = ["sameDayDelivery", "paymentMethod",	"cashOnDeliveryAmount",	"manyItemsTag","externalOrderNumber", "orderNotes",	"pickUpDate",	"deliveryDate",	"originContactName",	"originContactPhone",	"originArea",	"originStreetBuilding",	"originFloor",	"originRoom",	"destinationContactName",	"destinationContactPhone", "destinationDeliverMethod", "pickUpStoreCode", "destinationArea",	"destinationStreetBuilding",	"destinationFloor",	"destinationRoom",	"dimensionOption",	"actualWeight",	"weightOption",	"temperatureOption",	"categoryOption",	"category"];
export const categoryOptionOtherObject = {id: "other", descriptionEn: "Other", descriptionChi: "其他"};

export function selfPickUp(pickUpMethod: string): boolean{
  return !!BULK_IMPORT.PICK_UP_METHODS[pickUpMethod];
}

export function isDoorToDoor(pickUpMethod: string): boolean{
  return !BULK_IMPORT.PICK_UP_METHODS[pickUpMethod];
}

export function excelDateToJsDate(excelDate: any): Date{
  const secondsInDay = 24 * 60 * 60;
  const missingLeapYearDay = secondsInDay * 1000;
  const delta = excelDate - (25567 + 2);
  const parsed = delta * missingLeapYearDay;
  const date = new Date(parsed);
  if (Object.prototype.toString.call(date) === "[object Date]" ) {
    if (isNaN(date.getTime())) {
      return excelDate;
    }
  }  
  return date;
  // if(typeof excelDate === 'number'){
  //   return new Date((excelDate - (25567 + 1))*86400*1000);
  // }else{
  //   return new Date(excelDate);
  // }
}
/**
 * 
 * @param data : raw data from excel import
 * @returns : {"validate": boolean, "invalidData[i]": any[]}"
 */
export async function validateImportExpressOrderData(data: any[]) {
  //empty/invalid data  
  if(!Array.isArray(data) || data.length <= 3){
    return {validate: false, errorMessage: EMPTY_EXCEL_DATA, invalidData: []};
  }
  if(!(data[0][1].startsWith(process.env.VUE_APP_BULK_IMPORT_FORM_VERSION))){
    return {validate: false, errorMessage: EXCEL_VERSION_INCOMPATIBLE, invalidData: []};
  }
  await fetchDataHelpers.fetchExpressOrderOptions();
  await fetchDataHelpers.fetchExpressOrderAvailableDate();
  await fetchDataHelpers.fetchPickUpStores();
  // const invalidData = {} as {[key: number]: any};
  const invalidData: any = [];
  let validate = true;
  const orderData = data.slice(3);
  const formattedData: any[] = [];
  const invalidCustomMessages: string[] = [];
  if(process.env.VUE_APP_BULK_IMPORT_ROW_NUMBER_LIMIT && orderData.length > Number(process.env.VUE_APP_BULK_IMPORT_ROW_NUMBER_LIMIT))return {validate: false, errorMessage: NUMBER_OF_ROWS_EXCEED_LIMIT, invalidData: []};
  for(let i=0;i < orderData.length; i++){
    invalidData[i] = [];
    formattedData[i] = {};
    formattedData[i]["originAddress"] = {};
    formattedData[i]["originAddress"]["phoneAreaCode"] = "852";

    formattedData[i]["item"] = {};

    if(!Array.isArray(orderData[i]) || orderData[i].length <= 21){
      return {validate: false, errorMessage: INVALID_FORMAT, invalidData: []};
    }
    const row = orderData[i];
    formattedData[i]["rowNumber"]=i+1;
    let columnIndex = 0;
    //sameDayDelivery
    const orderType = row[columnIndex];
    if(!Object.keys(BULK_IMPORT.DELIVERY_TYPES).includes(orderType)) invalidData[i][columnIndex] = true;
    formattedData[i]["sameDayDelivery"] = BULK_IMPORT.DELIVERY_TYPES[orderType] === true ? "TRUE": "FALSE";
    // row[columnIndex];
    columnIndex++;

    //paymentMethod
    const paymentMethod = row[columnIndex];
    if(!Object.keys(BULK_IMPORT.PAYMENT_METHODS).includes(paymentMethod)) invalidData[i][columnIndex] = true;
    formattedData[i]["paymentMethod"] = BULK_IMPORT.PAYMENT_METHODS[paymentMethod];
    columnIndex++;

    //cashOnDeliveryAmount
    const cashOnDeliveryAmount = Number(row[columnIndex]);
    if([PAYMENT_METHOD.PAID_BY_MERCHANT_COD, PAYMENT_METHOD.CASH_ON_DELIVERY].includes(formattedData[i]["paymentMethod"])){
      if((isNaN(cashOnDeliveryAmount) || !Number.isInteger(cashOnDeliveryAmount) || cashOnDeliveryAmount <= 0)) invalidData[i][columnIndex] = true;
      formattedData[i]["cashOnDeliveryAmount"] = cashOnDeliveryAmount;
    }
    columnIndex++;

    const manyItemsTag = row[columnIndex];
    if(manyItemsTag){
      if(manyItemsTag.length > 255) invalidData[i][columnIndex] = true;
      formattedData[i]["manyItemsTag"] = manyItemsTag;
    }
    columnIndex++;



    const externalOrderNumber = row[columnIndex];
    if(externalOrderNumber){
      if(externalOrderNumber.length > 50) invalidData[i][columnIndex] = true;
      //formattedData[i]["externalOrderNumber"] = externalOrderNumber;
      formattedData[i]["item"]["itemExternalNumber"] = String(externalOrderNumber) || "";
    }
    columnIndex++;

    //orderNotes
    const orderNotes = row[columnIndex];
    if(orderNotes && orderNotes.length > 70) invalidData[i][columnIndex] = true;
    formattedData[i]["orderNotes"] = String(orderNotes??"");
    formattedData[i]["item"]["orderNotes"] = String(orderNotes??"");
    columnIndex++;


    //pickUpDate
    const pickUpDate = DateFormatHelpers.formattedJSDateStringFromDate(excelDateToJsDate(row[columnIndex]));
    columnIndex++;

    //deliveryDate
    const deliveryDate = DateFormatHelpers.formattedJSDateStringFromDate(excelDateToJsDate(row[columnIndex]));
    if(formattedData[i]["sameDayDelivery"] === "TRUE"){
      if( (!pickUpDate) || !ShipmentHelpers.getAvailablePickUpDates(deliveryDate).includes(pickUpDate)) invalidData[i][columnIndex-1] = true;
      if( (!deliveryDate) || !ShipmentHelpers.getAvailableDeliveryDates().includes(deliveryDate)) invalidData[i][columnIndex] = true;

      formattedData[i]["pickUpDate"] = String(pickUpDate) || "";
      formattedData[i]["item"]["pickUpDate"] = String(pickUpDate) || "";
      formattedData[i]["deliveryDate"] = String(deliveryDate) || "";
      formattedData[i]["item"]["deliveryDate"] = String(deliveryDate) || "";
    }
    columnIndex++; 
    
    // if(row[0] !== true || !Date.parse(row[4]) || !store.getters["date/getAvailablePickUpDates"].find(String(row[5]))) invalidData[i][4] = true;

    //originContactName
    const originContactName = row[columnIndex];
    if(!originContactName || originContactName.length > 20) invalidData[i][columnIndex] = true;
    formattedData[i]["originAddress"]["contactName"] = String(originContactName??"") || "";
    columnIndex++;

    //originContactPhone
    const originContactPhone = row[columnIndex];
    const phoneNumberCheckerInstance = PhoneNumberUtil.getInstance();
    if(!originContactPhone){
      invalidData[i][columnIndex] = true;
    }else{
      try{
        const numberParsed = phoneNumberCheckerInstance.parse(String(originContactPhone), "HK");
        if(!phoneNumberCheckerInstance.isValidNumberForRegion(numberParsed,"HK")){
          invalidData[i][columnIndex] = true;
        }
      }catch(error){
        invalidData[i][columnIndex] = true;
      }
    }
    formattedData[i]["originAddress"]["phoneAreacode"] = "852";
    formattedData[i]["originAddress"]["contactPhone"] = originContactPhone || "";
    columnIndex++;
    
    //originArea
    const originArea = String(row[columnIndex]??"");
    // const originAreaRank = store.getters["address.getAreas"].find((area: Area) => area.areaChi === originArea);
    // if(!originArea || !originAreaRank) invalidData[i][8] = true;
    if(originArea){
      if(originArea.length > 10) invalidData[i][columnIndex] = true;
      formattedData[i]["originAddress"]["area"] = originArea;
    }
    columnIndex++;

    //originStreetBuilding
    const originStreetBuilding = String(row[columnIndex]??"");
    if(!originStreetBuilding || originStreetBuilding.length > 250) invalidData[i][columnIndex] = true;
    formattedData[i]["originAddress"]["streetBuilding"] = originStreetBuilding;
    columnIndex++;

    //originFloor
    const originFloor = String(row[columnIndex]??"");
    if(originFloor){
      if(String(originFloor).length > 10) invalidData[i][columnIndex] = true;
      formattedData[i]["originAddress"]["floor"] = originFloor;
    }
    columnIndex++;

    

    //originRoom
    const originRoom = String(row[columnIndex]??"");
    if(originRoom){
      if( originRoom.length > 10) invalidData[i][columnIndex] = true;
      formattedData[i]["originAddress"]["room"] = originRoom;
    }
    columnIndex++;
    


    //destinationContactName
    const destinationContactName = String(row[columnIndex]??"");
    if(!destinationContactName || destinationContactName.length > 20) {
      invalidData[i][columnIndex] = true;
    }
    if(selfPickUp(row[columnIndex+2])){
      formattedData[i]["receiverName"] = destinationContactName;
    }else{
      formattedData[i]["destinationAddress"] = {};
      formattedData[i]["destinationAddress"]["phoneAreaCode"] = "852";
      formattedData[i]["destinationAddress"]["contactName"] = destinationContactName;
    }
    columnIndex++;

    //destinationContactPhone
    const destinationContactPhone = String(row[columnIndex]??"");
    if(!destinationContactPhone){
      invalidData[i][columnIndex] = true;
    }else{
      try{
        const numberParsed = phoneNumberCheckerInstance.parse(destinationContactPhone, "HK");
        if(!phoneNumberCheckerInstance.isValidNumberForRegion(numberParsed,"HK")){
          invalidData[i][columnIndex] = true;
        }
      }catch(error){
        invalidData[i][columnIndex] = true;
      }
    }
    if(selfPickUp(row[columnIndex+1])){
      formattedData[i]["receiverPhone"] = destinationContactPhone;
    }else{
      formattedData[i]["destinationAddress"]["contactPhone"] = destinationContactPhone;
    }
    columnIndex++;
    
    //destinationPickUpMethod
    const destinationPickUpMethod = String(row[columnIndex]??"");
    if(!destinationPickUpMethod 
      || !Object.keys(BULK_IMPORT.PICK_UP_METHODS).includes(destinationPickUpMethod)
      ||(formattedData[i]["sameDayDelivery"] == "TRUE" && selfPickUp(destinationPickUpMethod))
      ){
      invalidData[i][columnIndex] = true;
    }
    formattedData[i]["isSelfPickUp"] = selfPickUp(destinationPickUpMethod)?"TRUE":"FALSE";
    columnIndex++;

    //pickUpStoreCode
    const pickUpStoreCode = String(row[columnIndex]??"");
    const pickUpStore = store.getters["pickUpStore/getPickUpStoreByCode"](pickUpStoreCode.trim());
    if(selfPickUp(destinationPickUpMethod)){
      if((!pickUpStoreCode || !pickUpStore ||Object.keys(pickUpStore).length === 0)){
        invalidData[i][columnIndex] = true;
      }
      if(compareHelpers.trueOrZero(manyItemsTag) && pickUpStore?.isThirdParty){
        invalidData[i][columnIndex] = true;
        const customMessage = i18n.t("dialogs.thirdPartyNotSupportMultipleItems.message",{companyNameChi: pickUpStore.companyNameChi || i18n.t('general.thirdParty'), companyNameEn: pickUpStore.companyNameEn || i18n.t('general.thirdParty')}) as string;
        if(!invalidCustomMessages.includes(customMessage)){
          invalidCustomMessages.push(customMessage);
        }
      }
  
      formattedData[i]["pickUpStoreCode"] = pickUpStoreCode;
    }
    
    columnIndex++;

    //destinationArea
    const destinationArea = String(row[columnIndex]??"");
    // const destinationAreaRank = store.getters["address/getAreas"].find((area: Area) => area.areaChi === destinationArea);
    // if(!destinationArea || !destinationAreaRank) invalidData[i][columnIndex] = true;
    if(isDoorToDoor(destinationPickUpMethod)){
      if( destinationArea && destinationArea.length > 10) invalidData[i][columnIndex] = true;
      formattedData[i]["destinationAddress"]["area"] = destinationArea;
    }
    columnIndex++;

    //destinationStreetBuilding
    const destinationStreetBuilding = String(row[columnIndex]??"");
    if(isDoorToDoor(destinationPickUpMethod)) {
      if((!destinationStreetBuilding || destinationStreetBuilding.length > 250)) {
        invalidData[i][columnIndex] = true;
      }
      formattedData[i]["destinationAddress"]["streetBuilding"] = destinationStreetBuilding;
    }
    columnIndex++;

    //destinationFloor
    const destinationFloor = String(row[columnIndex]??"");
      if(isDoorToDoor(destinationPickUpMethod)) {
        if(destinationFloor && destinationFloor.length > 10){
          invalidData[i][columnIndex] = true;
        }
        formattedData[i]["destinationAddress"]["floor"] = (destinationFloor);
      }
    columnIndex++;
    
    //destinationRoom
    const destinationRoom = String(row[columnIndex]??"");
      if(isDoorToDoor(destinationPickUpMethod)) {
        if(destinationRoom && destinationRoom.length > 10){
          invalidData[i][columnIndex] = true;
        }
        formattedData[i]["destinationAddress"]["room"] = (destinationRoom);
      }
    columnIndex++;
    
    //dimensionOption
    const dimensionOption = row[columnIndex];
    const dimensionOptionObject = store.getters[(formattedData[i]["sameDayDelivery"] === "TRUE")?"itemOption/expressOrder/getSameDayDimensions":"itemOption/expressOrder/getNonSameDayDimensions"].find((dimension: Option)=> dimension.descriptionChi === dimensionOption);
    if(!dimensionOption || !dimensionOptionObject) {
      invalidData[i][columnIndex] = true;
    }
    formattedData[i]["item"]["dimensionOption"] = dimensionOption;
    columnIndex++;

    //actualWeight
    const actualWeight = row[columnIndex];
    if(formattedData[i]["sameDayDelivery"] !== "TRUE"){
      if((!actualWeight || isNaN(Number(actualWeight)) || Number(actualWeight) < 0)) {
        invalidData[i][columnIndex] = true;
      }
      formattedData[i]["item"]["actualWeight"] = Number(actualWeight) || 0;
    }
    columnIndex++;
    

    //weightOption
    const weightOption = row[columnIndex];
    if(formattedData[i]["sameDayDelivery"] === "TRUE"){
      const weightOptionObject = store.getters["itemOption/expressOrder/getWeights"].find((weight: Option) => weight.descriptionChi === weightOption);
      if(!weightOption || !weightOptionObject) {
        invalidData[i][columnIndex] = true;
      }
      formattedData[i]["item"]["weightOption"] = weightOption;
    }
    columnIndex++;

    //temperatureOption
    const temperatureOption = row[columnIndex];
    if(formattedData[i]["sameDayDelivery"] === "TRUE"){
      const temperatureOptionObject = store.getters["itemOption/expressOrder/getTemperatures"].find((tempature: Option) => tempature.descriptionChi === temperatureOption);
      if(!temperatureOption || !temperatureOptionObject) {
        invalidData[i][columnIndex] = true;
      }
      formattedData[i]["item"]["temperatureOption"] = temperatureOption;
    }
    columnIndex++;

    //categoryOption
    const categoryOption = row[columnIndex];
    columnIndex++;
    //category
    const otherCategory = String(row[columnIndex]??"");
    if(!categoryOption && !otherCategory){
      invalidData[i][columnIndex-1] = true;
      invalidData[i][columnIndex] = true;
    }else if((!categoryOption && otherCategory) || categoryOption.trim() === categoryOptionOtherObject.descriptionChi){
      if(!otherCategory|| otherCategory.length > 250) invalidData[i][columnIndex] = true;
      formattedData[i]["item"]["category"] = otherCategory;
    }else if(categoryOption){
      const categoryOptionObject = store.getters["itemOption/expressOrder/"+(formattedData[i]["sameDayDelivery"] === "TRUE"?"getSameDayCategories":"getNonSameDayCategories")].find((category: Option) => category.descriptionChi === categoryOption);
      if(!categoryOptionObject) invalidData[i][columnIndex-1] = true;
    }
    formattedData[i]["item"]["categoryOption"] = categoryOption;
    
    formattedData[i]["dummy"] = [formattedData[i]["sameDayDelivery"],
                                  formattedData[i]["paymentMethod"],
                                  formattedData[i]["isSelfPickUp"] ,
                                  pickUpDate,
                                  deliveryDate,
                                  originContactName,
                                  originContactPhone,
                                  originArea,
                                  originStreetBuilding,
                                  originFloor,
                                  originRoom,
                                  destinationContactName,
                                  destinationContactPhone,
                                  pickUpStoreCode,
                                  destinationArea,
                                  destinationStreetBuilding,
                                  destinationFloor,
                                  destinationRoom].join("$%&@");

    if(invalidData[i].includes(true)) validate = false;
  }
  // let formattedData = [];
  // if(validate === true){
  //   formattedData = await formatBulkImportExpressOrder(data);
  // }
  return {validate: validate, errorMessage: validate?"":INVALID_DATA, invalidData: invalidData, invalidCustomMessages: invalidCustomMessages, rawData: orderData || [], formattedData: formattedData};
}

/**
 * 
 * 
 */
 export async function readExcelFile(file: any, option?: any): Promise<any[]>{
   
  return await readXlsxFile(file, {
    ...option
  }).then((rows: any) => {
    return rows || [];
 })
}
export async function bulkImportExpressOrder(file: any, option?: any){
  const data = await readExcelFile(file, option);
  const validatedData = await validateImportExpressOrderData(data);
  return validatedData;
}

export function formatBulkImportRecords(records: any[]){
  const formattedRecords = [];
  for (let i = 0; i < records.length;i++){
    formattedRecords.push([
      records[i]!.createdAt, 
      i18n.te("bulkImport.status." + records[i]!.status)?i18n.t("bulkImport.status." + records[i]!.status):i18n.t("bulkImport.status.ERROR"),
      String(records[i].numberOfOrders),
      (records[i]!.labelKey?"file_download":"pending")
    ]);
  }
  return formattedRecords;
}

export function indexToExcelColumn(index: number) {
  let letters = '';
  while (index >= 0) {
      letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index % 26] + letters;
      index = Math.floor(index / 26) - 1;
  }
  return letters;
}

export function checkIsMobile(){
  return ( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}
