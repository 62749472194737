import { AxiosInstance } from "axios";
import { itemCategories } from "@/assets/constant";

export function otherCourierItemOptionModule(axiosInstance: AxiosInstance) {
  const getDefaultState = () => {
    return {
      categories: [] as  any[],
     
    }
  };
    return  {
    namespaced: true,
     state: getDefaultState(),

     getters: {
      getCategories: (state: any) => {
        return itemCategories;
      }
     },
     
     mutations: {
     },

     actions: {
    }
  };
}