
















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MDCTextField } from "@material/textfield";
// import {MDCTextFieldHelperText} from '@material/textfield/helper-text';
// const mdcHelperText = new MDCTextFieldHelperText(document.querySelector('.mdc-text-field-helper-text'));


@Component
export default class TheMDCTextFieldOutlined extends Vue {
  
  @Prop() label?: string;
  @Prop() reference!: string;
  @Prop() isRequired!: boolean | undefined;
  @Prop() width!: string | undefined;
  @Prop() height!: string | undefined;
  @Prop() isDisabled!: boolean | undefined;
  @Prop() type?: "text" | "password" | "number" | "decimal" | "tel" | "integer";
  @Prop() maxlength?: string;
  @Prop() minlength?: string;
  @Prop() maxNumber?: number;
  @Prop() minNumber?: number;
  @Prop() showCounter?: boolean;
  @Prop() placeholder?: string;
  @Prop() placeholderFontSize?: string;
  @Prop() event?: string;
  @Prop() forceFloating?: boolean;
  @Prop() helperText?: string;
  @Prop() value?: string | number;
  private inputValue = "" as any;
  @Watch("inputValue")
  watchEmitToParent(newValue: any, oldValue: any) {
    // console.log("watchEmitToParent value", newValue, typeof newValue);
    //if input type is phone number, prevent any
    if(this.type === "tel" && newValue){
      const onlyNumbers = newValue?.replace(/[^\d]/g, "");
      if (newValue != onlyNumbers || onlyNumbers.length > 8) {
        this.inputValue = onlyNumbers;
      }
    }else if(this.type === "integer"){
      if(!Number.isInteger(Number(newValue)) || !this.validateMixMaxNumber(newValue)){
        if(!Number.isInteger(Number(oldValue))){
          this.inputValue = "";
        }else{
          this.inputValue = oldValue;
        }
      }
    }else if(this.type === "number" || this.type === "decimal"){
      if(newValue && newValue.toString().includes(".")){
        if(newValue.length > newValue.indexOf(".") + 3){
          this.inputValue = oldValue;
        } 
      }else{
        if(!this.validateMixMaxNumber(newValue)){
          if(!this.validateMixMaxNumber(oldValue)){
            this.inputValue = this.minNumber?this.minNumber.toString():"";
          }else{
            this.inputValue = oldValue;
          }
        }
      }
    }
    //emit v-model event
    this.$emit('input', this.inputValue);
    if (this.event) {
      this.$emit(this.event, this.inputValue, oldValue);
    }
  }
  private textField!: MDCTextField;

  // private value = "";

  get hasplaceholder() {
    if (this.placeholder) {
      return true;
    } else {
      return false;
    }
  }
  mounted() {
    this.textField = new MDCTextField(
      this.$refs[this.reference as any] as Element
    );
    if(this.value){
      this.inputValue = this.value;
      this.setValue(this.inputValue);
    }
  }

  getMDC() {
    return this.textField;
  }

  getValue() {
    return this.textField.value;
  }

  setValue(v: string | number) {
    try{
      if(v == null || typeof v === 'undefined' ){
        this.textField.value = "";
      }else{
        this.textField.value = v.toString();
      }
    }catch(e){
      console.log(e);
    }
  }

  validateMixMaxNumber(input: string): boolean{
    if(this.type !== 'number' && this.type !== "integer" && this.type !== 'decimal' ){
      return true;
    }
    const inputNumber  = Number(input);
    if((isNaN(inputNumber)
      || (this.minNumber && inputNumber < this.minNumber)
      || (this.maxNumber && inputNumber > this.maxNumber)) 
      && input != ""
      )
    {
      return false;
    }
    return true;
  }
  focus() {
    this.textField.focus();
  }
  emitFocus(){
    this.$emit("focus");
  }
}
