import { render, staticRenderFns } from "./ErrorPrompt.vue?vue&type=template&id=3780b3a8&"
import script from "./ErrorPrompt.vue?vue&type=script&lang=ts&"
export * from "./ErrorPrompt.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kenneth/jumppoint/jumppoint-front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3780b3a8')) {
      api.createRecord('3780b3a8', component.options)
    } else {
      api.reload('3780b3a8', component.options)
    }
    module.hot.accept("./ErrorPrompt.vue?vue&type=template&id=3780b3a8&", function () {
      api.rerender('3780b3a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dialogs/ErrorPrompt.vue"
export default component.exports