var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-bar__container", class: { show: _vm.isShow } },
    [
      _c(
        "div",
        { staticClass: "nav-bar-items__container" },
        _vm._l(_vm.items, function(item) {
          return _c("NavBarItem", {
            key: item.to.name,
            ref: "navBarItems",
            refInFor: true,
            attrs: { params: item },
            on: {
              click: function($event) {
                return _vm.navRoute(item.to.name)
              }
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }