var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: _vm.reference,
      staticClass: "mdc-button mdc-button--raised",
      staticStyle: { "border-radius": "9999px" },
      style: {
        width: _vm.width || "inherit",
        height: _vm.height || "inherit",
        "min-width": _vm.minWidth || "100px"
      },
      attrs: { disabled: _vm.isDisabled },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.emitToParent()
        }
      }
    },
    [
      _vm.startIcon
        ? _c(
            "i",
            {
              staticClass: "material-icons mdc-button__icon",
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v(_vm._s(_vm.startIcon))]
          )
        : _vm._e(),
      _c("div", { staticClass: "mdc-button__ripple" }),
      _c(
        "span",
        {
          staticClass: "mdc-button__label",
          style: { fontSize: _vm.fontSize || "min(1.2rem, 4vw)" }
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm.trailIcon
        ? _c(
            "i",
            {
              staticClass: "material-icons mdc-button__icon",
              attrs: { "aria-hidden": "true" }
            },
            [_vm._v(_vm._s(_vm.trailIcon))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }