import {
  Capacitor,
  Plugins,
  FilesystemDirectory,
  FilesystemEncoding
} from "@capacitor/core";
const { Filesystem, Browser } = Plugins;

export async function saveFileTODevice(url: string) {
  await Browser.open({ url: url });
}

export const openBrowser = saveFileTODevice
